import { useParams } from "react-router-dom"
import ContainerTitulo from "../../components/Formularios/Container/ContainerTitulo"
import FormMetas from "./components/formulario"

function IncluirMetas() {
    const idmeta = useParams()

    return (
        <>
            <div className="col mt-2">
                <ContainerTitulo titulo="Incluir Meta" />
                <FormMetas idmeta={idmeta.id} acao="Novo" />
            </div>
        </>
    )
}

export default IncluirMetas