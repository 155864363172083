import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Card, Col, Form, Row } from "react-bootstrap";

import { containerClasses, Switch } from "@mui/material";
import { mdiCheckCircle, mdiKeyboardReturn, mdiPlus } from "@mdi/js";
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda";
import { Button } from "../../../../components/Formularios/Buttons/Button";
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading";
import ApiGet from "../../../../api/endPoints/useGet";
import { TextoFormatoCaptalize } from "../../../../components/Auxiliar/ConvTextoCaptalize";
import ApiPost from "../../../../api/endPoints/usePost";
import ApiPut from "../../../../api/endPoints/usePut";
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface";
import Tabela from "../../../../components/Formularios/Table";
import ModalDetalhe from "../modalDetalheCodigoSistema";
import { TextoFormatoUpperCaseAcento } from "../../../../components/Auxiliar/ConvTextUpperRemCaracEspAcento";
import { showConfirmationDialog } from "../../../../components/Formularios/Modal/ModalExcluir";
import ApiDelete from "../../../../api/endPoints/useDelete";
import { getSessionData } from "../../../../utils/storageUtils";
import axios from "axios";

interface FormPerfilProps {
  idcodigo?: string;
  acao?: string;
}

const FormCodSistema = ({ idcodigo, acao }: FormPerfilProps) => {
  const token = getSessionData("MultfilialWebToken") || "";
  const id_filial = getSessionData("DadosIdfilial")
  const navigate = useNavigate();

  const [id_codigo, setIdCodigo] = useState<string>("");
  const [tabela, setTabela] = useState<string>("");
  const [descricao, setDescricao] = useState<string>("");
  const [ativo, setAtivo] = useState<boolean>(true);
  const [sistema, setSistema] = useState<string | any[]>([]);
  const [sistemaSelecionado, setSistemaSelecionado] = useState<string>('');
  const [tipoItem, setTipoItem] = useState<string>('');
  const [categoria, setCategoria] = useState<string[]>([])
  const [categoriaSelecionada, setCategoriaSelecionada] = useState<string>('')
  const [mostraSelectCategoria, setMostraSelectCategoria] = useState<boolean>(true)
  const [mostraCategoriaCadastrada, setMostraCategoriaCadastrada] = useState<boolean>(true)

  //#region REALIZA REQUISIÇÃO PARA EXIBIR OS DADOS
  const [loading, setLoading] = useState<boolean>(false);
  const [dados, setDados] = useState<any[]>([]);
  const carregarDados = async (idcodigo: any, id_filial: any) => {
    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/codigosistema/carregar/:id_codigo/:id_filial",
      parametros: {
        id_codigo: idcodigo,
        id_filial: id_filial
      },
      headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setTipoItem(retorno[0].tipoItem)
        setSistema(retorno[0].sistema)
        setSistemaSelecionado(retorno[0].sistema)
        setIdCodigo(retorno[0].id);
        setTabela(retorno[0].tabela);
        setDescricao(retorno[0].descricao);
        if (retorno[0].situacao === 1) {
          setAtivo(true);
        } else {
          setAtivo(false);
        }
        setLoading(false);
        setDados(
          retorno[0].cod_sistema.length > 0 ? retorno[0].cod_sistema : []
        );

        if (retorno[0].categoria) {
          setMostraCategoriaCadastrada(false)
          setCategoriaSelecionada(retorno[0].categoria)
        } else {
          setMostraCategoriaCadastrada(true)
        }
      })
      .catch((erro) => {
        setLoading(false);
        toast.error(`Erro ao carregar dados. Motivo: ${erro.message}`);
      });
  };
  useEffect(() => {
    if (idcodigo !== "novo") {
      carregarDados(idcodigo, id_filial);
    }
  }, [idcodigo]);
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA ATUALIZAR OS DADOS DO USUÁRIO
  const atualizarDados = async (
    idcodigo: any,
    descricao: string,
    situacao: number,
    sistemaSelecionado: string,
    id_filial: any,
    tipoItem: string,
    categoriaSelecionada: string
  ) => {
    const dados = {
      descricao: TextoFormatoCaptalize(descricao.toLowerCase()),
      situacao,
      sistemaSelecionado,
      id_filial,
      tipoItem,
      categoriaSelecionada
    };
    ApiPut(`/codigosistema/atualizar/${idcodigo}/${id_filial}`, dados, token)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        toast.error(
          `Erro ao atualizar dados. Motivo: ${erro.response.data.message}`
        );
      });
  };
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS DO USUÁRIO
  const gravarDados = (
    tabela: string,
    descricao: string,
    situacao: number,
    sistemaSelecionado: string,
    itens: any[],
    id_filial: any,
    tipoItem: string,
    categoriaSelecionada: string
  ) => {
    const dados = {
      tabela: TextoFormatoUpperCaseAcento(tabela),
      descricao: TextoFormatoCaptalize(descricao.toLowerCase()),
      situacao,
      sistemaSelecionado,
      itens,
      id_filial,
      tipoItem,
      categoriaSelecionada
    };
    ApiPost("/codigosistema/gravar", dados, token)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        toast.error(`Erro ao gravar dados. Motivo: ${msg}`);
      });
  };
  //#endregion

  const gravar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (idcodigo === "novo") {
      gravarDados(tabela, descricao, ativo ? 1 : 0, sistemaSelecionado, dados, id_filial, tipoItem, categoriaSelecionada);
      toast.info("Inserindo novo registro...");
    } else {
      atualizarDados(idcodigo, descricao, ativo ? 1 : 0, sistemaSelecionado, id_filial, tipoItem, categoriaSelecionada);
      toast.info("Atualizando dados do registro...");
    }
  };

  const colunas: ITabela[] = [
    { titulo: "Código", acesso: "codigo" },
    { titulo: "Descrição", acesso: "descricao" },
    { titulo: "Situação", acesso: "situacao" },
  ];

  const visualizar = (dadosVisualizar: any) => {
    setDadosItens((state) => {
      return {
        ...state,
        codigo: dadosVisualizar.codigo,
        descricao: dadosVisualizar.descricao,
        defUsuario01: dadosVisualizar.defusr01,
        defUsuario02: dadosVisualizar.defusr02,
        defUsuario03: dadosVisualizar.defusr03,
        defUsuario04: dadosVisualizar.defusr04,
        defUsuario05: dadosVisualizar.defusr05,
        defUsuario06: dadosVisualizar.defusr06,
        ativo: dadosVisualizar.situacao === 1 ? true : false,
        acao: "Visualizar",
      };
    });
    setShowModalDetalhe(true);
  };
  const [dadosItens, setDadosItens] = useState({
    codigo: "",
    descricao: "",
    defUsuario01: "",
    defUsuario02: "",
    defUsuario03: "",
    defUsuario04: "",
    defUsuario05: "",
    defUsuario06: "",
    ativo: true,
    acao: "",
  });

  const editar = (dadosEditar: any) => {
    if (acao !== "Visualizar") {
      setDadosItens((state) => {
        return {
          ...state,
          codigo: dadosEditar.codigo,
          descricao: dadosEditar.descricao,
          defUsuario01: dadosEditar.defusr01,
          defUsuario02: dadosEditar.defusr02,
          defUsuario03: dadosEditar.defusr03,
          defUsuario04: dadosEditar.defusr04,
          defUsuario05: dadosEditar.defusr05,
          defUsuario06: dadosEditar.defusr06,
          ativo: dadosEditar.situacao === 1 ? true : false,
          acao: "EditarItem",
        };
      });
      setShowModalDetalhe(true);
    }
  };
  const excluirItem = async (dadosExcluir: any) => {
    const codigo = dadosExcluir.codigo;
    if (acao !== "Visualizar") {
      const { id_filial = getSessionData("DadosIdfilial") } = dadosExcluir;
      await showConfirmationDialog(
        "Excluir Código do Sistema",
        `Tem certeza que deseja excluir o de código do sistema \n ${dadosExcluir.codigo} - ${dadosExcluir.descricao}?`,
        "question"
      )
        .then((resultado) => {
          if (resultado.confirmed) {
            if (acao === "Novo") {
              const dadosNovos = dados.filter(
                (item: any) => item.codigo !== codigo
              );
              setDados(dadosNovos);
              toast.success("Código excluído com sucesso!");
            } else if (acao === "Editar") {
              const headers = {
                Authorization: token,
              };
              const dados = {
                url: "/codigosistema/excluir/detalhe/:tabela/:id_codigo/:id_filial",
                parametros: {
                  tabela: tabela,
                  id_codigo: codigo,
                  id_filial: id_filial
                },
                headers,
              };
              ApiDelete(dados)
                .then((retorno) => {
                  toast.success(retorno.message);
                  carregarDados(idcodigo, id_filial);
                })
                .catch((erro) => {
                  toast.error(
                    `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
                  );
                });
            }
          }
        })
        .catch((erro) => {
          toast.error(`Erro ao excluir dados. Motivo: ${erro.message}`);
        });
    }
  };

  //#region Controla o estado do modal para adicionar detalhes a tabela
  const [showModalDetalhe, setShowModalDetalhe] = useState<boolean>(false);
  //#endregion  

  const [ultimoCodigo, setUltimoCodigo] = useState(0);

  //Funcao que realiza o autoincremento do campo id_codigo
  function buscaUltimoCodigo() {
    if (id_codigo === '') {
      // Caso id_codigo seja vazio, autoincrementa localmente
      if (ultimoCodigo === null) {
        setUltimoCodigo(0);  // Inicia o contador, caso ainda não tenha sido setado
      }

      const proximoCodigo = String(ultimoCodigo + 1).padStart(3, "0"); // Incrementa o código

      // Limpar campos do modal e definir o próximo código
      setDadosItens({
        codigo: proximoCodigo,
        descricao: "",
        defUsuario01: "",
        defUsuario02: "",
        defUsuario03: "",
        defUsuario04: "",
        defUsuario05: "",
        defUsuario06: "",
        ativo: true,
        acao: "Novo",
      });

      setShowModalDetalhe(true);
    } else {
      // Caso id_codigo não seja vazio, busca o último código na API
      setLoading(true);
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/ultimo/codigo/${id_filial}/${id_codigo}`, {
        headers: { Authorization: token }
      }).then(function (resposta) {
        setLoading(false);
        const ultimoCodigoRecebido = isNaN(parseInt(resposta.data.data, 10)) ? 0 : parseInt(resposta.data.data, 10);
        setUltimoCodigo(ultimoCodigoRecebido);

        // Calcula o próximo código
        const proximoCodigo = String(ultimoCodigoRecebido + 1).padStart(3, "0");

        // Limpar campos do modal e definir o próximo código
        setDadosItens({
          codigo: proximoCodigo,
          descricao: "",
          defUsuario01: "",
          defUsuario02: "",
          defUsuario03: "",
          defUsuario04: "",
          defUsuario05: "",
          defUsuario06: "",
          ativo: true,
          acao: "Novo",
        });

        setShowModalDetalhe(true);
      }).catch(function (erro) {
        setLoading(false);
        const status = erro.response.status;
        if (status === 403 || status === 401) {
          toast.error("Acesso negado, faça seu login novamente");
        }
      });
    }
  };

  const handleOpenModal = () => {
    // Chama a função de busca antes de abrir o modal
    buscaUltimoCodigo();
  };

  const handleSave = () => {
    // Após salvar os dados com sucesso, incrementar o código
    setUltimoCodigo(ultimoCodigo + 1);
    setShowModalDetalhe(false); // Fecha o modal após salvar
  };

  const handleCloseModal = () => {
    // Simulação: Buscar dados de um GET ou preparar valores iniciais
    setShowModalDetalhe(false);
  };

  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS  - DETALHE
  const gravarDadosDetalhe = (
    tabela: string,
    codigo: string,
    descricao: string,
    situacao: number,
    defusr01: string,
    defusr02: string,
    defusr03: string,
    defusr04: string,
    defusr05: string,
    defusr06: string,
  ) => {
    const id_filial = getSessionData("DadosIdfilial")
    const dados = {
      tabela: tabela,
      codigo: TextoFormatoUpperCaseAcento(codigo),
      descricao: TextoFormatoCaptalize(descricao),
      situacao,
      defusr01,
      defusr02,
      defusr03,
      defusr04,
      defusr05,
      defusr06,
      id_filial
    };
    ApiPost("/codigosistema/gravar/detalhe", dados, token)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        carregarDados(idcodigo, id_filial);
        setShowModalDetalhe(false);
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        toast.error(`Erro ao gravar dados. Motivo: ${msg}`);
      });
  };
  //#endregion
  //#region REALIZA REQUISIÇÃO PARA ATUALIZAR OS DADOS  - DETALHE
  const atualizarDadosDetalhe = (
    tabela: string,
    codigo: string,
    descricao: string,
    situacao: number,
    defusr01: string,
    defusr02: string,
    defusr03: string,
    defusr04: string,
    defusr05: string,
    defusr06: string,
  ) => {
    const id_filial = getSessionData("DadosIdfilial")
    const dados = {
      tabela: tabela,
      codigo: TextoFormatoUpperCaseAcento(codigo),
      descricao: TextoFormatoCaptalize(descricao),
      situacao,
      defusr01,
      defusr02,
      defusr03,
      defusr04,
      defusr05,
      defusr06,
      id_filial
    };
    ApiPut(`/codigosistema/atualizar/detalhe/${codigo}/${tabela}`, dados, token)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        carregarDados(idcodigo, id_filial);
        setShowModalDetalhe(false);
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        toast.error(`Erro ao atualizar dados. Motivo: ${msg}`);
      });
  };
  //#endregion
  const handleSubmit = (dadosItens: any) => {
    const dadosTabela = {
      codigo: dadosItens.codigo,
      descricao: dadosItens.descricao,
      defusr01: dadosItens.defUsuario01,
      defusr02: dadosItens.defUsuario02,
      defusr03: dadosItens.defUsuario03,
      defusr04: dadosItens.defUsuario04,
      defusr05: dadosItens.defUsuario05,
      defusr06: dadosItens.defUsuario06,
      situacao: dadosItens.ativo ? 1 : 0,
      id_filial: dadosItens.id_filial
    };

    if (acao === "Novo" && dadosItens.acao === "Novo") {
      const item = dados.some((item) => item.codigo === dadosItens.codigo);
      if (!item) {
        setShowModalDetalhe(false);
        setDados([...dados, dadosTabela]);
      } else {
        toast.error("Código já cadastrado na tabela!");
      }
    } else if (acao === "Editar" && dadosItens.acao === "Novo") {
      const item = dados.some((item) => item.codigo === dadosItens.codigo);
      if (!item) {
        gravarDadosDetalhe(
          tabela,
          dadosItens.codigo,
          dadosItens.descricao,
          dadosItens.ativo ? 1 : 0,
          dadosItens.defUsuario01,
          dadosItens.defUsuario02,
          dadosItens.defUsuario03,
          dadosItens.defUsuario04,
          dadosItens.defUsuario05,
          dadosItens.defUsuario06,
        );
      } else {
        toast.error("Código já cadastrado na tabela!");
      }
    } else if (acao === "Editar" && dadosItens.acao === "EditarItem") {
      atualizarDadosDetalhe(
        tabela,
        dadosItens.codigo,
        dadosItens.descricao,
        dadosItens.ativo ? 1 : 0,
        dadosItens.defUsuario01,
        dadosItens.defUsuario02,
        dadosItens.defUsuario03,
        dadosItens.defUsuario04,
        dadosItens.defUsuario05,
        dadosItens.defUsuario06,
      );
    }
  };

  function buscarSistemas(tipoItem: string) {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/listar/sistemas/codsis`, {
      headers: {
        Authorization: token
      },
      params: {
        id_filial,
        tipoItem
      }
    }).then(function (resposta) {
      setSistema(resposta.data.dataSistemas)
    }).catch(function (erro) {
      // Trata erros de autorização e exibe uma mensagem para o usuário
      const status = erro.response.status
      if (status == 403 || status == 401) {
        toast.error("Acesso negado, faça seu login novamente")
      }
      setSistema([])
    })
  }

  function buscarCategorias(sistema: string) {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/listar/categorias/codsis/${id_filial}/${sistema}`, {
      headers: {
        Authorization: token
      }
    }).then(function (resposta) {
      setCategoria(resposta.data.data)
    }).catch(function (erro) {
      const status = erro.response.status;
      if (status === 403 || status === 401) {
        toast.error("Acesso negado, faça seu login novamente");
        navigate("/login")
      } else {
        toast.error(erro.response.data.message)
      }
    })
  }

  return (
    <>
      <Card>
        <Form onSubmit={gravar}>
          <Card.Body>
            <Row>
              <Col sm={2}>
                <InputSemBorda
                  label="Cód. Tabela"
                  name="codtabela"
                  type="text"
                  placeholder="Cód. Tabela"
                  readonly
                  value={id_codigo || "novo"}
                  onChange={setIdCodigo}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                />
              </Col>
              <Col sm={3}>
                <InputSemBorda
                  label="Item"
                  name="item"
                  type="text"
                  placeholder="Item"
                  readonly={
                    acao === "Visualizar" || acao === "Editar" ? true : false
                  }
                  value={tabela}
                  onChange={setTabela}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-uppercase"
                />
              </Col>
              <Col sm={5}>
                <InputSemBorda
                  label="Descrição"
                  name="descricao"
                  type="text"
                  placeholder="Descrição"
                  required
                  readonly={acao === "Visualizar" ? true : false}
                  value={descricao}
                  onChange={setDescricao}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                />
              </Col>
              <Col sm={2}>
                <br />
                <label htmlFor="" className="form-label me-2">
                  Ativo
                </label>
                <Switch
                  checked={ativo}
                  onChange={() => setAtivo(!ativo)}
                  disabled={acao === "Visualizar" ? true : false}
                />
              </Col>
              <Col sm={3}>
                <div className="col-16 form-floating">
                  <select
                    autoFocus
                    required={acao == 'Novo'}
                    className="form-select bordasInferiorInput text-black text-capitalize"
                    onChange={(event) => {
                      setTipoItem(event.target.value)

                      if (event.target.value === 'categoria' || event.target.value === 'listFormulario') {
                        buscarSistemas(event.target.value)
                      }
                    }}
                    value={tipoItem}
                    disabled={acao == 'Editar' || acao == 'Visualizar'}
                  >
                    <option value={""}>Selecione...</option>
                    <option value={"generico"}>Genérico</option>
                    <option value={"sistema"}>Sistema</option>
                    <option value={"categoria"}>Categoria</option>
                    <option value={"listFormulario"}>List Formulário</option>
                  </select>
                  <label className="form-label">Tipo do Item</label>
                </div>
              </Col>
              <Col sm={3} hidden={tipoItem !== 'categoria' && tipoItem !== 'listFormulario'}>
                <div className="col-16 form-floating">
                  <select
                    autoFocus
                    disabled={acao == 'Editar' || acao == 'Visualizar'}
                    required={tipoItem === 'categoria' || tipoItem === 'listFormulario'}
                    className="form-select bordasInferiorInput text-black text-capitalize"
                    onChange={(event) => {
                      setSistemaSelecionado(event.target.value)

                      if (event.target.value && tipoItem === 'listFormulario') {
                        setMostraSelectCategoria(false)
                        buscarCategorias(event.target.value)
                      } else {
                        setMostraSelectCategoria(true)
                      }
                    }}
                    value={sistemaSelecionado || (typeof sistema === 'string' ? sistema : '')}
                  >
                    {Array.isArray(sistema) && <option value="">Selecione...</option>}

                    {Array.isArray(sistema) ? (
                      sistema.length > 0 ? (
                        sistema.map((item: any) => (
                          <option value={item.descricao}>
                            {item.descricao}
                          </option>
                        ))
                      ) : (
                        <option disabled value="">
                          Nenhum sistema disponível
                        </option>
                      )
                    ) : (
                      sistema && (
                        <option value={sistema}>
                          {sistema}
                        </option>
                      )
                    )}
                  </select>
                  <label className="form-label">Sistema Desejado</label>
                </div>
              </Col>
              <Col sm={3} hidden={mostraSelectCategoria}>
                <div className="col-16 form-floating">
                  <select
                    autoFocus
                    required={tipoItem == 'listFormulario'}
                    className="form-select bordasInferiorInput text-black text-capitalize"
                    onChange={(event) => setCategoriaSelecionada(event.target.value)}
                    value={categoriaSelecionada}
                    disabled={acao == 'Editar' || acao == 'Visualizar'}
                  >
                    <option value={""}>Selecione...</option>
                    {categoria.map((item: any) => (
                      <option key={item.descricao} value={item.descricao}>
                        {item.descricao}
                      </option>
                    ))}

                  </select>
                  <label className="form-label">Categoria</label>
                </div>
              </Col>
              <Col sm={3} hidden={mostraCategoriaCadastrada}>
                <div className="col-16 form-floating">
                  <input
                    autoFocus
                    className="form-select bordasInferiorInput text-black text-capitalize"
                    value={categoriaSelecionada}
                    disabled
                  >
                  </input>
                  <label className="form-label">Categoria</label>
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col sm={2}>
                <Button
                  invisivel={false}
                  type="button"
                  disabled={acao == 'Visualizar'}
                  className="btn btn-light text-bg-dark bg-white border-0"
                  descricaoBotao="Incluir Código"
                  icone={mdiPlus}
                  onclick={handleOpenModal}
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Tabela
                coluna={colunas}
                itemsPerPage={5}
                dados={dados}
                onVisuClick={visualizar}
                onEditClick={editar}
                onDeleteClick={excluirItem}
                permissao_editar={false}
                permissao_visualizar={false}
                permissao_excluir={false}
              />
            </Row>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex my-2 gap-1 justify-content-between">
              <Button
                invisivel={false}
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  navigate(-1);
                }}
              />
              <Button
                invisivel={acao == 'Visualizar'}
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
              />
            </div>
          </Card.Footer>
        </Form>
      </Card>
      <ModalLoading show={loading} label="Carregado dados do perfil ..." />

      {/* MODAL PARA INCLUIR/EDITAR ITEM NA TABELA */}

      <ModalDetalhe
        showModal={showModalDetalhe}
        setShowModal={setShowModalDetalhe}
        onClose={handleCloseModal}
        itemTipo={tipoItem}
        onSubmit={handleSubmit}
        onSave={handleSave}
        acao={dadosItens.acao}
        dadosCodigo={dadosItens.codigo}
        dadosDescricao={dadosItens.descricao}
        dadosDefUsuario01={dadosItens.defUsuario01}
        dadosDefUsuario02={dadosItens.defUsuario02}
        dadosDefUsuario03={dadosItens.defUsuario03}
        dadosDefUsuario04={dadosItens.defUsuario04}
        dadosDefUsuario05={dadosItens.defUsuario05}
        dadosDefUsuario06={dadosItens.defUsuario06}
        dadosAtivo={dadosItens.ativo}
      />
    </>
  );
};
export default FormCodSistema;
