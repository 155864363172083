import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from "../../../components/Formularios/Buttons/Button"
import { mdiPlusBox } from '@mdi/js';
import { Col, Row } from 'react-bootstrap';
import InputSemBorda from '../Inputs/InputsSemBorda';
import axios from 'axios';
import { getSessionData } from '../../../utils/storageUtils';
import { toast } from 'react-toastify';

interface tiposModalIncluirCampos {
    onClick: (idCampo: string, tipoCampo: string, tabelaSelecionada: string, labelCampo: string, obrigatorio: boolean, anexoObg: boolean) => void,
    dados?: { id_campo: string | number }[]
    sistema?: string
    categoria?: string
}

function ModalIncluirCampos({
    onClick,
    dados,
    sistema,
    categoria
}: tiposModalIncluirCampos) {
    const [show, setShow] = useState(false);
    const [idCampo, setIdCampo] = useState('')
    const [labelCampo, setLabelCampo] = useState('')
    const [tipoCampo, setTipoCampo] = useState('')
    const [tabela, setTabela] = useState([])
    const [tabelaSelecionada, setTabelaSelecionada] = useState('')
    const [obrigatorio, setObrigatorio] = useState(false)
    const [anexoObg, setAnexoObg] = useState(false)
    const token = getSessionData('MultfilialWebToken') || "";

    const handleClose = function () {
        setShow(false)
        setIdCampo('')
        setTipoCampo('')
        setTabelaSelecionada('')
        setAnexoObg(false)
        setObrigatorio(false)
    };

    const [ultimoCodigo, setUltimoCodigo] = useState(0);

    // useEffect para calcular o maior `id_campo` ao montar o componente
    useEffect(() => {
        let maiorId = 0;

        // Verifica se `dados` está definido e é um array
        if (dados && dados.length > 0) {
            for (let i = 0; i < dados.length; i++) {
                const idCampoNumber = Number(dados[i].id_campo);
                if (idCampoNumber > maiorId) {
                    maiorId = idCampoNumber;
                }
            }
        }

        setUltimoCodigo(maiorId);
    }, [dados]);

    const handleShow = () => {
        const proximoCodigo = String(ultimoCodigo + 1).padStart(3, "0");

        setIdCampo(proximoCodigo);
        setShow(true);
    };

    // Função assíncrona para preencher os campos dos select boxes com dados da API
    async function preencherSelectBox() {
        // Faz uma requisição GET para obter dados de vários select boxes da API
        const id_filial = sessionStorage.getItem("DadosIdfilial")!
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/preencher/selectbox/formularios/${id_filial}/${sistema}/${categoria}`, {
            headers: {
                Authorization: token // Adiciona o token de autorização no cabeçalho da requisição
            }
        }).then(function (resposta) {
            // Atualiza o estado com os dados recebidos da API
            setTabela(resposta.data.dataTabelas)
        }).catch(function (erro) {
            // Trata erros de autorização e exibe uma mensagem para o usuário
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
            else {
                toast.error(erro.response.data.message || erro.message)
            }
        })
    }

    return (
        <>
            <Button
                invisivel={false}
                className="btn btn-dark text-info-emphasis bg-primary border-0 rounded w-100"
                descricaoBotao="Adicionar Campo"
                icone={mdiPlusBox}
                onclick={handleShow}
                type='button'
            />

            <Modal
                show={show}
                onHide={handleClose}
                onShow={preencherSelectBox}
                backdrop="static"
                keyboard={false}
                size='xl'
                centered
            >
                <Modal.Header closeButton className='bg-white'>
                    <Modal.Title>Adicionar Campo</Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-white'>
                    <Row>
                        <Col>
                            <InputSemBorda
                                label="ID do Campo"
                                name="idCampo"
                                readonly={true}
                                type="text"
                                placeholder="ID do Campo"
                                value={idCampo}
                                onChange={setIdCampo}
                                classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                            />
                        </Col>
                        <Col>
                            <div className="col-16 form-floating">
                                <select
                                    autoFocus
                                    required
                                    className="form-select bordasInferiorInput text-black text-capitalize"
                                    onChange={(event) => setTipoCampo(event.target.value)}
                                    value={tipoCampo}
                                >
                                    <option value={""}>Selecione...</option>
                                    <option value={"1"}>Text</option>
                                    <option value={"2"}>Number</option>
                                    <option value={"3"}>Select</option>
                                    <option value={"4"}>Boolean</option>
                                </select>
                                <label className="form-label">Tipo do Campo</label>
                            </div>
                        </Col>
                        <Col>
                            <InputSemBorda
                                label="Label do Campo"
                                name="labelCampo"
                                type="text"
                                placeholder="Label do Campo"
                                value={labelCampo}
                                onChange={setLabelCampo}
                                classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <div className="form-check mt-3">
                                <input className="form-check-input" type="checkbox" onChange={function (e) {
                                    setObrigatorio(e.target.checked)
                                }} checked={obrigatorio} value="" id="flexCheckDefault" />
                                <label className="form-check-label ms-1 mt-1">
                                    É obrigatório?
                                </label>
                            </div>
                        </Col>
                        <Col sm={3}>
                            <div className="form-check mt-3">
                                <input className="form-check-input" type="checkbox" onChange={function (e) {
                                    setAnexoObg(e.target.checked)
                                }} checked={anexoObg} value="" />
                                <label className="form-check-label ms-1 mt-1">
                                    Anexo obrigatório?
                                </label>
                            </div>
                        </Col>
                        <Col sm={3} hidden={tipoCampo !== '3'}>
                            <div className="col-16 form-floating  mt-2">
                                <select
                                    autoFocus
                                    required
                                    className="form-select bordasInferiorInput text-black text-capitalize"
                                    onChange={(event) => setTabelaSelecionada(event.target.value)}
                                    value={tabelaSelecionada}

                                >
                                    <option value={""}>Selecione...</option>
                                    {tabela.map((item: any) => (
                                        <option value={item.descricao}>{item.descricao}</option>
                                    ))}
                                </select>
                                <label className="form-label">Tabela de Respostas</label>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='bg-white'>
                    <Button
                        invisivel={false}
                        type="submit"
                        className="btn btn-dark text-info-emphasis bg-primary border-0 rounded"
                        descricaoBotao="Confirmar"
                        onclick={function () {
                            if (!tipoCampo || !labelCampo) {
                                toast.info('Existem campos não preenchidos')
                            } else {
                                onClick(idCampo, tipoCampo, tabelaSelecionada, labelCampo, obrigatorio, anexoObg)
                                handleClose()
                                setUltimoCodigo(ultimoCodigo + 1)
                                setLabelCampo('')
                            }
                        }}
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalIncluirCampos;