import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { Tab, Box, Switch } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import axios from 'axios';
import { mdiCheckCircle, mdiKeyboardReturn, mdiSendCircle } from "@mdi/js";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";
import { Button } from "../../../components/Formularios/Buttons/Button";
import { getSessionData } from "../../../utils/storageUtils";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { mascaraTelefoneCelular } from "../../../hooks/mascaras";
import { mascaraTelefoneFixo } from "../../../hooks/mascaras";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import ModalEditarCampos from "../../../components/Formularios/FormulariosEditar/EditarCampos";
import DetalhesTicket from "../controleTickets/detalhesTicket";

interface Cliente {
    id_cliente: number; // ou string, dependendo do seu caso
    fantasia: string;
}

function CriarTicket() {
    const [nomeUsuario, setNomeUsuario] = useState<string>("");
    const [emailUsuario, setEmailUsuario] = useState<string>("");
    const [telefoneUsuario, setTelefoneUsuario] = useState<string>("");
    const [empresa, setEmpresa] = useState<string>("");
    const [categoria, setCategoria] = useState([]);
    const [sistema, setSistema] = useState([]);
    const [tituloTicket, setTituloTicket] = useState<string>("")
    const [descricaoTicket, setDescricaoTicket] = useState<string>("")
    const [sistemaSelecionado, setSistemaSelecionado] = useState<string>("");
    const [categoriaSelecionada, setCategoriaSelecionada] = useState<string>("");
    const [controleReadonlyTelefone, setControleReadonlyTelefone] = useState(true);
    const [posicaoTelefone, setPosicaoTelefone] = useState<'vazio' | 'preenchido'>('vazio');
    const [clientes, setClientes] = useState<Cliente[]>([]);
    const [clienteSelecionado, setClienteSelecionado] = useState<string>("");
    const [idCliente, setidCliente] = useState<number>();
    const [prioridades, setPrioridades] = useState([]);
    const [prioridadeSelecionada, setPrioridadeSelecionada] = useState<string>("Baixa");
    const [idUsuarioCriacao, setIdUsuarioCriacao] = useState<string>("");
    const [tipoCadastro, setTipoCadastro] = useState([]);
    const [tipoCadastroSelecionado, setTipoCadastroSelecionado] = useState<string>("");
    const [referencia, setReferencia] = useState<string>("");
    const [tipoProcesso, setTipoProcesso] = useState([]);
    const [tipoProcessoSelecionado, setTipoProcessoSelecionado] = useState<string>("");
    const [tiposPedido, setTiposPedido] = useState([]);
    const [tiposPedidoSelecionado, setTiposPedidoSelecionado] = useState<string>("");
    const [servicos, setServicos] = useState([]);
    const [servicoSelecionado, setServicoSelecionado] = useState<string>("");
    const [etapaNFE, setEtapaNFE] = useState([]);
    const [etapaNFESelecionada, setEtapaNFESelecionada] = useState<string>("");
    const [depositante, setDepositante] = useState<string>("");
    const navigate = useNavigate()
    const [listaArquivos, setListaArquivos] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false);
    const [dadosDetalhes, setDadosDetalhes] = useState<any>([])
    const [listaCodSist, setListaCodSist] = useState([])
    const token = getSessionData('MultfilialWebToken') || "";
    const [disabledPrioridade, setDisabledPrioridade] = useState<boolean>(false)

    // Funcao criada para verificar se o usuario tem a devida permissao para definir a prioridade do ticket
    function verificaPermissaoPrioridade() {
        const idUsuario = getSessionData("DadosUsuarioID")
        const idFilial = getSessionData("DadosIdfilial")

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/verifica/permissao/prioridade/${idUsuario}/${idFilial}/${idCliente}`, {
            headers: {
                Authorization: token // Adiciona o token de autorização no cabeçalho da requisição
            }
        }).then(function (resposta) {
            if (resposta.data.data.escolherPrioridade == 1) {
                setDisabledPrioridade(false)
            } else {
                setDisabledPrioridade(true)
                setPrioridadeSelecionada('Baixa')
            }
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    useEffect(function () {
        verificaPermissaoPrioridade()
    }, [clienteSelecionado])

    // Funcao criada para preencher o select box de clientes na tela de criacao de ticket
    function buscarClientesSelectbox() {
        const id_filial = getSessionData("DadosIdfilial")
        const idUsuario = getSessionData('DadosUsuarioID')

        // Faz uma requisição GET para obter dados de vários select boxes da API
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/clientes/selectBox`, {
            headers: {
                Authorization: token // Adiciona o token de autorização no cabeçalho da requisição
            },
            params: {
                id_filial,
                idUsuario
            }
        }).then(function (resposta) {
            setClientes(resposta.data.dataClientes)
        }).catch(function (erro) {
            // Trata erros de autorização e exibe uma mensagem para o usuário
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    useEffect(function () {
        buscarClientesSelectbox()
        buscarPrioridadesSelectbox()
    }, [])

    // Funcao criada para preencher o select box de prioridades na tela de criacao de ticket
    function buscarPrioridadesSelectbox() {
        const id_filial = getSessionData("DadosIdfilial")

        // Faz uma requisição GET para obter dados de vários select boxes da API
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/prioridades/selectBox`, {
            headers: {
                Authorization: token // Adiciona o token de autorização no cabeçalho da requisição
            },
            params: {
                id_filial,
            }
        }).then(function (resposta) {
            setPrioridades(resposta.data.dataPrioridades)
        }).catch(function (erro) {
            // Trata erros de autorização e exibe uma mensagem para o usuário
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    function buscarSistemasSelectbox() {
        const id_filial = getSessionData("DadosIdfilial")

        // Faz uma requisição GET para obter dados de vários select boxes da API
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/sistemas/selectBox`, {
            headers: {
                Authorization: token // Adiciona o token de autorização no cabeçalho da requisição
            },
            params: {
                id_filial,
                idCliente
            }
        }).then(function (resposta) {
            setSistema(resposta.data.dataSistemas)
        }).catch(function (erro) {
            // Trata erros de autorização e exibe uma mensagem para o usuário
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    useEffect(function () {
        buscarSistemasSelectbox()
        if (clienteSelecionado) {
            buscarSistemasSelectbox()
        } else if (clienteSelecionado === '') {
            setSistemaSelecionado('')
        }
    }, [clienteSelecionado])

    // Função assíncrona para preencher os campos dos select boxes com dados da API
    function preencherSelectBox() {
        const id_filial = getSessionData("DadosIdfilial")
        const idUsuario = getSessionData('DadosUsuarioID')

        // Faz uma requisição GET para obter dados de vários select boxes da API
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/preencher/selectbox`, {
            headers: {
                Authorization: token // Adiciona o token de autorização no cabeçalho da requisição
            },
            params: {
                sistemaSelecionado,
                id_filial,
            }
        }).then(function (resposta) {
            // Atualiza o estado com os dados recebidos da API
            setCategoria(resposta.data.dataCategorias)
            setTipoCadastro(resposta.data.dataTipoCadastros)
            setTipoProcesso(resposta.data.dataTiposProcesso)
            setEtapaNFE(resposta.data.dataEtapaNFE)
            setTiposPedido(resposta.data.dataTiposPedido)
            setServicos(resposta.data.dataServicos)

        }).catch(function (erro) {
            // Trata erros de autorização e exibe uma mensagem para o usuário
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    // Hook useEffect para executar a função preencherSelectBox e carregarDados quando o componente é montado
    useEffect(() => {
        preencherListaCodSist()
        carregarDados() // Carrega os dados do usuário da API
    }, []) // Dependência vazia significa que o useEffect será chamado apenas uma vez após a montagem do componente

    useEffect(() => {
        preencherSelectBox() // Preenche os select boxes com dados da API
    }, [sistemaSelecionado])

    // Função para converter um arquivo em uma string base64 quando um arquivo é selecionado
    const converterAnexo = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file: any = event.target.files; // Obtém os arquivos selecionados

        const objListaArquivos: any = [] // Cria uma lista para armazenar os arquivos convertidos

        for (let i = 0; i < file.length; i = i + 1) {
            const arquivo = file[i]
            const objArquivo = {
                nomeArquivo: arquivo.name, // Nome do arquivo
                tipoArquivo: arquivo.type, // Tipo do arquivo
                base64: '' // Inicializa o valor base64 como uma string vazia
            }

            if (arquivo) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    if (reader.result) {
                        // Garante que reader.result seja tratado como uma string
                        const base64String = reader.result as string;
                        objArquivo.base64 = base64String // Armazena o valor base64 no objeto do arquivo
                        objListaArquivos.push(objArquivo) // Adiciona o arquivo convertido à lista
                    }
                };

                reader.readAsDataURL(arquivo); // Lê o arquivo como uma URL de dados base64
            }
        }
        setListaArquivos(objListaArquivos) // Atualiza o estado com a lista de arquivos convertidos
    };

    // Função para carregar os dados do usuário a partir da API
    function carregarDados() {
        const idUsuario = getSessionData('DadosUsuarioID') // Obtém o ID do usuário do sessionStorage
        const idFilial = getSessionData('DadosIdfilial')

        // Faz uma requisição GET para obter os dados do usuário da API
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/usuario/carregar/${idUsuario}/${idFilial}`, {
            headers: { Authorization: token } // Adiciona o token de autorização no cabeçalho da requisição
        }).then(function (resposta) {
            setLoading(true); // Define o estado de carregamento como falso

            // Atualiza o estado com os dados recebidos da API
            setIdUsuarioCriacao(resposta.data[0].id_usuario)
            setNomeUsuario(resposta.data[0].nome)
            setEmailUsuario(resposta.data[0].email)
            setTelefoneUsuario(resposta.data[0].telefone)
            setLoading(false); // Define o estado de carregamento como verdadeiro

            // Se o telefone estiver vazio, permitir a edição e mostrar o campo
            if (resposta.data[0].telefone == '' || resposta.data[0].telefone == null) {
                setControleReadonlyTelefone(false); // Permitir edição do telefone
                setPosicaoTelefone('vazio'); // Exibir o campo na posição 'vazio'
            } else {
                setControleReadonlyTelefone(true); // Bloquear edição
                setPosicaoTelefone('preenchido'); // Exibir o campo na posição 'preenchido'
            }

            mascararTelefones() // Aplica a máscara no telefone do usuário
            setEmpresa(resposta.data[0].filial[0].fantasia) // Define o nome da empresa
        }).catch(function (erro) {
            // Trata erros de autorização e exibe uma mensagem para o usuário
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    // Função para enviar os dados do formulário para a API
    function enviarDados(e: any) {
        const idFilial = getSessionData("DadosIdfilial")

        e.preventDefault() // Previne o comportamento padrão de envio do formulário
        //looping para pegar os valores dos campos dinamicos
        const valoresDadosDetalhes = []
        for (let i = 0; i < dadosDetalhes.length; i = i + 1) {
            const idAcesso = dadosDetalhes[i].id_campo
            const campo: any = document.getElementById(idAcesso)
            const labelCampo: any = document.getElementById('label' + idAcesso)
            if (campo) {
                valoresDadosDetalhes.push({
                    id_campo: dadosDetalhes[i].id_campo,
                    value: campo.value,
                    tipo: campo.tagName,
                    label: labelCampo.textContent
                })
            }
        }
        const dados = {
            nomeUsuario,
            sistemaSelecionado,
            categoriaSelecionada,
            clienteSelecionado,
            idCliente,
            prioridadeSelecionada,
            tituloTicket,
            descricaoTicket,
            idFilial,
            idUsuarioCriacao,
            listaArquivos,
            tipoCadastroSelecionado,
            referencia,
            tipoProcessoSelecionado,
            etapaNFESelecionada,
            depositante,
            tiposPedidoSelecionado,
            servicoSelecionado,
            empresa,
            telefoneUsuario,
            emailUsuario: emailUsuario,
            detalhesCampos: dadosDetalhes, //guarda o nome dos campos dinamicos a serem acessados
            valoresDadosDetalhes: valoresDadosDetalhes
        }
        // Verifica se a descrição do ticket foi preenchida
        if (descricaoTicket === '') {
            toast.info('O ticket não pode ser criado sem uma descrição') // Exibe uma mensagem informativa
            return
        } else if (tituloTicket === '') {
            toast.info('O ticket não pode ser criado sem um titulo') // Exibe uma mensagem informativa
            return
        } else if (telefoneUsuario === '') {
            toast.info('Insira um telefone para contato válido') // Exibe uma mensagem informativa
            return
        } else if (clienteSelecionado === '') {
            toast.info('Selecione um cliente válido para prosseguir.')
        }
        setLoading(true); // Define o estado de carregamento como verdadeiro
        // Faz uma requisição POST para enviar os dados do ticket para a API
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/enviar/dadosTicket`, dados, {
            headers: { Authorization: token } // Adiciona o token de autorização no cabeçalho da requisição
        }).then(function (resposta) {
            toast.success(resposta.data.message) // Exibe uma mensagem de sucesso
            setTimeout(() => {
                navigate('/tickets/visualizacao/') // Redireciona o usuário para a página anterior após 2 segundos
            }, 2000);
            setLoading(false)
        }).catch(function (erro) {
            setLoading(false)
            toast.error(erro.response.data.message) // Exibe uma mensagem de erro
        })
    }

    // Função para aplicar uma máscara de telefone no valor do telefone do usuário
    function mascararTelefones() {
        if (telefoneUsuario.length == 11) {
            setTelefoneUsuario(mascaraTelefoneCelular(telefoneUsuario)) // Aplica a máscara de celular
        } else if (telefoneUsuario.length == 10) {
            setTelefoneUsuario(mascaraTelefoneFixo(telefoneUsuario)) // Aplica a máscara de telefone fixo
        }
    }

    //carrega as listas do codigo de sistema
    function preencherListaCodSist() {
        const id_filial = getSessionData("DadosIdfilial")

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/preencher/lista/codsist/${id_filial}`, {
            headers: {
                Authorization: token // Adiciona o token de autorização no cabeçalho da requisição
            }
        }).then(function (resposta) {
            setListaCodSist(resposta.data.dataListaCodSist)
        }).catch(function (erro) {
            // Trata erros de autorização e exibe uma mensagem para o usuário
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    function buscarDetalhesForm() {
        const id_filial = getSessionData('DadosIdfilial')

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/detalhes/form/${sistemaSelecionado}/${categoriaSelecionada}/${id_filial}`, {
            headers: { Authorization: token } // Adiciona o token de autorização no cabeçalho da requisição
        }).then(function (resposta) {
            setDadosDetalhes(resposta.data.data)
        }).catch(function (erro) {
            // Trata erros de autorização e exibe uma mensagem para o usuário
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    useEffect(function () {
        if (categoriaSelecionada !== '' && sistemaSelecionado !== '') {
            buscarDetalhesForm()
        } else {
            setDadosDetalhes([])
        }
    }, [categoriaSelecionada, sistemaSelecionado])

    return (
        <>
            <div className="col">
                <ContainerTitulo titulo="Criação de Ticket" />
                <form onSubmit={enviarDados}>
                    <Row className="d-flex flex-wrap">
                        <Col sm={12}>
                            <Card className="p-4">
                                <Card className="p-3 border shadow">
                                    <Row>
                                        <div className="col col-sm col-md-2 col-lg-2" hidden={posicaoTelefone === 'preenchido'}>
                                            <div className="form-floating">
                                                <InputSemBorda
                                                    label="Seu Telefone"
                                                    name="telefoneUsuario"
                                                    required
                                                    type="text"
                                                    readonly={controleReadonlyTelefone}
                                                    placeholder="Telefone"
                                                    value={telefoneUsuario}
                                                    onChange={setTelefoneUsuario}
                                                    onBlur={mascararTelefones}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                                />
                                            </div>
                                        </div>
                                        <Col sm={3} className="mb-3">
                                            <div className="col-16 form-floating">
                                                <select
                                                    autoFocus
                                                    required
                                                    className="form-select bordasInferiorInput text-black text-capitalize"
                                                    onChange={(event) => {
                                                        const selectedClient = event.target.value;
                                                        setClienteSelecionado(selectedClient);

                                                        // Aqui, você pode encontrar o cliente selecionado pelo nome (fantasia)
                                                        const cliente = clientes.find(item => item.fantasia === selectedClient);
                                                        if (cliente) {
                                                            setidCliente(cliente.id_cliente); // Atualiza o ID do cliente apenas quando um cliente é selecionado
                                                        }
                                                    }}
                                                    value={clienteSelecionado}
                                                >
                                                    <option value={""}>Selecione...</option>
                                                    {clientes.map((item: any) => (
                                                        <option key={item.id_cliente} value={item.fantasia}>
                                                            {item.fantasia}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label className="form-label">Cliente</label>
                                            </div>
                                        </Col>
                                        <Col sm={3} className="mb-3" hidden={!clienteSelecionado}>
                                            <div className="col-16 form-floating">
                                                <select
                                                    autoFocus
                                                    required
                                                    className="form-select bordasInferiorInput text-black text-capitalize"
                                                    onChange={(event) => setSistemaSelecionado(event.target.value)}
                                                    value={sistemaSelecionado}
                                                >
                                                    <option value={""}>Selecione...</option>
                                                    {sistema.map((item: any) => (
                                                        <option value={item.descricao}>{item.descricao}</option>
                                                    ))}
                                                </select>
                                                <label className="form-label">Sistema</label>
                                            </div>
                                        </Col>
                                        <Col sm={3} className="mb-3" hidden={!sistemaSelecionado || !clienteSelecionado}>
                                            <div className="col-16 form-floating">
                                                <select
                                                    autoFocus
                                                    required
                                                    className="form-select bordasInferiorInput text-black text-capitalize"
                                                    onChange={(event) => setCategoriaSelecionada(event.target.value)}
                                                    value={categoriaSelecionada}
                                                >
                                                    <option value={""}>Selecione...</option>
                                                    {categoria.map((item: any) => (
                                                        <option value={item.descricao}>{item.descricao}</option>
                                                    ))}
                                                </select>
                                                <label className="form-label">Categoria</label>
                                            </div>
                                        </Col>
                                        <Col sm={3} className="mb-3" hidden={!clienteSelecionado}>
                                            <div className="col-16 form-floating">
                                                <select
                                                    autoFocus
                                                    // required={!escolhePrioridade}
                                                    className="form-select bordasInferiorInput text-black text-capitalize"
                                                    onChange={(event) => setPrioridadeSelecionada(event.target.value)}
                                                    value={prioridadeSelecionada}
                                                    disabled={disabledPrioridade}
                                                >
                                                    <option value={""}>Selecione...</option>
                                                    {prioridades.map((item: any) => (
                                                        <option value={item.descricao}>{item.descricao}</option>
                                                    ))}
                                                </select>
                                                <label className="form-label">Prioridade</label>
                                            </div>
                                        </Col>
                                        {dadosDetalhes.map((campo: any) => {
                                            const tabela: any = listaCodSist[campo.descricao]
                                            return (
                                                <>
                                                    {campo.tipo_campo === '3' && (
                                                        <Col sm={3} key={campo.id_det}>
                                                            <div className="form-floating">
                                                                <select
                                                                    autoFocus
                                                                    required={campo.obrigatorio}
                                                                    className="form-select bordasInferiorInput text-black text-capitalize"
                                                                    id={campo.id_campo}
                                                                >
                                                                    <option value={""}>Selecione...</option>
                                                                    {
                                                                        tabela.map(function (option: any) {
                                                                             return <option value={option.descricao}>{option.descricao}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                                <label id={'label' + campo.id_campo} htmlFor={campo.id_campo}>{campo.label_campo}</label>
                                                            </div>
                                                        </Col>
                                                    )}

                                                    {campo.tipo_campo === '2' && (
                                                        <Col sm={3} key={campo.id_det}>
                                                            <div className="form-floating">
                                                                <input
                                                                    id={campo.id_campo}
                                                                    required={campo.obrigatorio}
                                                                    className="form-control bordasInferiorInput text-black text-capitalize"
                                                                    placeholder=" "
                                                                    type="number"
                                                                />
                                                                <label id={'label' + campo.id_campo} htmlFor={campo.id_campo}>{campo.label_campo}</label>
                                                            </div>
                                                        </Col>
                                                    )}

                                                    {campo.tipo_campo === '1' && (
                                                        <Col sm={3} key={campo.id_det}>
                                                            <div className="form-floating">
                                                                <input
                                                                    id={campo.id_campo}
                                                                    required={campo.obrigatorio}
                                                                    className="form-control bordasInferiorInput text-black text-capitalize"
                                                                    placeholder=" "
                                                                    type="text"
                                                                />
                                                                <label id={'label' + campo.id_campo} htmlFor={campo.id_campo}>{campo.label_campo}</label>
                                                            </div>
                                                        </Col>
                                                    )}
                                                </>
                                            );
                                        })}
                                    </Row>
                                    <Row>
                                        <div className="col col-sm col-md-2 col-lg-6">
                                            <div className="mb-3">
                                                <InputSemBorda
                                                    label="Título do Ticket"
                                                    name="ticket"
                                                    type="text"
                                                    required
                                                    placeholder="Título do Ticket"
                                                    value={tituloTicket}
                                                    onChange={setTituloTicket}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                                />
                                            </div>
                                        </div>

                                    </Row>
                                    <Row>
                                        <label htmlFor="formDescricaoChamado">Descrição do Chamado</label>
                                        <ReactQuill
                                            theme="snow"
                                            value={descricaoTicket}
                                            onChange={setDescricaoTicket}
                                            style={{ height: '200px', width: '100%' }}
                                        />
                                    </Row>
                                    <Row>
                                        <div style={{ marginTop: '50px' }}>
                                            <label htmlFor="fileInput">Inserir um anexo</label>
                                            <h6 className="mt-0 mb-1">(Caso seja necessário inserir mais de um anexo, você deve selecionar todos de uma só vez.)</h6>
                                            <input
                                                multiple
                                                type="file"
                                                className="form-control-file w-50"
                                                id="fileInput"
                                                onChange={converterAnexo}
                                            />
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="mt-3 d-flex justify-content-end mb-3">
                                            <Button
                                                invisivel={false}
                                                type="submit"
                                                className="btn btn-light text-info-emphasis bg-white border-0"
                                                descricaoBotao="Enviar"
                                                icone={mdiSendCircle}
                                            />
                                        </div>
                                    </Row>
                                </Card>
                                <Card className="p-3 border shadow">
                                    <Row>
                                        <Col sm={3} className="mb-3">
                                            <div className="form-floating">
                                                <InputSemBorda
                                                    label="Seu nome"
                                                    name="nomeUsuario"
                                                    type="text"
                                                    readonly
                                                    placeholder="Nome"
                                                    value={nomeUsuario}
                                                    onChange={setNomeUsuario}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={3} className="mb-3">
                                            <div className="form-floating">
                                                <InputSemBorda
                                                    label="E-mail"
                                                    name="emailUsuario"
                                                    readonly
                                                    type="text"
                                                    placeholder="E-mail"
                                                    value={emailUsuario}
                                                    onChange={setEmailUsuario}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={3} className="mb-3">
                                            <div className="form-floating">
                                                <InputSemBorda
                                                    label="Filial"
                                                    name="empresaUsuario"
                                                    type="text"
                                                    readonly
                                                    placeholder="Filial"
                                                    value={empresa}
                                                    onChange={setEmpresa}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={3} className="mb-3" hidden={posicaoTelefone === 'vazio'}>
                                            <div className="form-floating">
                                                <InputSemBorda
                                                    label="Telefone para contato"
                                                    name="telefoneUsuario"
                                                    required
                                                    type="text"
                                                    readonly={controleReadonlyTelefone}
                                                    placeholder="Telefone"
                                                    value={telefoneUsuario}
                                                    onChange={setTelefoneUsuario}
                                                    onBlur={mascararTelefones}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Card>
                        </Col>
                    </Row>
                </form>
                <ModalLoading show={loading} label="Carregando..." />
            </div>
        </>
    )
}

export default CriarTicket