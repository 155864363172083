import { ITabela } from "./TabelaInterface";
import React, { useState } from "react";
import { Button } from "../Buttons/Button";
import { mdiEye, mdiFileEditOutline, mdiTrashCanOutline } from "@mdi/js";
import Avatar from "@mui/material/Avatar";
import Paginacao from "../Pagination";
import { Badge } from "react-bootstrap";

interface TabelaProps {
  coluna: ITabela[];
  dados: any[];
  itemsPerPage: number;
  usaVisu?: boolean;
  usaEdit?: boolean;
  usaDelete?: boolean;
  onVisuClick: (dados: any) => void;
  onEditClick: (dados: any) => void;
  onDeleteClick: (dados: any) => void;
  id?: string;
  descEditar?: string;
  descExcluir?: string;
  descVisualizar?: string;
  iconeEditar?: string;
  iconeExcluir?: string;
  iconeVisualizar?: string,
  permissao_visualizar: boolean,
  permissao_editar: boolean,
  permissao_excluir: boolean
}
const Tabela: React.FC<TabelaProps> = ({
  coluna,
  dados,
  itemsPerPage,
  usaVisu = true,
  usaEdit = true,
  usaDelete = true,
  onVisuClick,
  onDeleteClick,
  onEditClick,
  id,
  descEditar,
  descExcluir,
  descVisualizar,
  iconeEditar,
  iconeExcluir,
  iconeVisualizar,
  permissao_visualizar,
  permissao_editar,
  permissao_excluir
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(dados.length / itemsPerPage);
  const itens = dados.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="table-responsive">
      <table className="table table-centered w-100 dt-responsive nowrap table-striped table-hover">
        <thead className="table-cabecalho">
          <tr>
            {coluna.map((item, index) => (
              <th key={index}>{item.titulo}</th>
            ))}
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {itens.map((item: any, index) => (
            <tr key={index}>
              {coluna.map((coluna, index: any) => (
                <td key={index}>
                  {coluna.acesso === "avatar" ? (
                    item[coluna.acesso] !== "" ? (
                      <Avatar
                        src={`data:image/png;base64,${item[coluna.acesso]}`}
                        alt="Avatar"
                      />
                    ) : (
                      <Avatar src={""} alt="Avatar" />
                    )
                  ) : coluna.acesso === "situacao" ? (
                    item[coluna.acesso] === 1 ? (
                      <Badge bg="primary">Ativo</Badge>
                    ) : (
                      <Badge bg="danger">Inativo</Badge>
                    )
                  ) : coluna.acesso === "status" ? (
                    item[coluna.acesso] == "1" ? (
                      <Badge bg="primary">ATIVO</Badge>
                    ) : item[coluna.acesso] == "0" ? (
                      <Badge bg="warning">PAUSADO</Badge>
                    ) : item[coluna.acesso] === "EM OPERACAO" ? (
                      <Badge bg="warning">EM OPERAÇÃO</Badge>
                    ) : item[coluna.acesso] === "CONCLUIDO" ? (
                      <Badge bg="success">CONCLUIDO</Badge>
                    ) : item[coluna.acesso] === "CANCELADO" ? (
                      <Badge bg="danger">CANCELADO</Badge>
                    ) : (
                      item[coluna.acesso]
                    )
                  ) : (
                    item[coluna.acesso]
                  )}
                </td>
              ))}
              <td>
                {usaVisu && (
                  <Button
                    invisivel={permissao_visualizar}
                    onclick={() => onVisuClick(item)}
                    icone={iconeVisualizar || mdiEye}
                    type="button"
                    className="botaoTransparente"
                    title={descVisualizar || "Visualizar"}
                  />
                )}
                {usaEdit && item.id_mensagem && item.id_mensagem == "novo" ?
                  ""
                  : <Button
                    invisivel={permissao_editar}
                    onclick={() => onEditClick(item)}
                    icone={iconeEditar || mdiFileEditOutline}
                    type="button"
                    className="botaoTransparente"
                    title={descEditar || "Editar"}
                  />}
                {usaDelete && (
                  <Button
                    invisivel={permissao_excluir}
                    onclick={() => onDeleteClick(item)}
                    icone={iconeExcluir || mdiTrashCanOutline}
                    type="button"
                    className="botaoTransparente"
                    title={descExcluir || "Excluir"}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Paginacao
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        dados={dados}
      />
    </div>
  );
};
export default Tabela;
