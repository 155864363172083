import { Card, Form, } from "react-bootstrap";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import ApiGet from "../../../api/endPoints/useGet";
import {
    mdiCheckCircle,
    mdiKeyboardReturn,
} from "@mdi/js";
import { Button as ButtonCustom } from "../../../components/Formularios/Buttons/Button";
import {
    Box,
    Switch,
    Tab
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import { getSessionData } from "../../../utils/storageUtils";
import axios from "axios";
import ClickableTable from "../../../components/ClickableTable/ClickableTabl";
import menuBot from "./interfaces/menuBot";
import FormMenuBot from "./components/formMenuBot";
import extendendoMenuBot from "./interfaces/extendendoMenuBot";
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";
function FormularioBots() {

    const params = useParams()
    const navigate = useNavigate()
    const token = sessionStorage.getItem("MultfilialWebToken")!
    //#region Controla as guias
    const [guia, setGuia] = useState("1")

    const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
        setGuia(novaGuia);
    };
    //#endregion

    const [nome, set_nome] = useState("")
    const [status, set_status] = useState(true)
    const id_filial = getSessionData("DadosIdfilial")
    const [funcionamentoInico, setFuncionamentoIncio] = useState("")
    const [funcionamentoFim, setFuncionamentoFim] = useState("")
    const [usaInteracoes, setUsaInteracoes] = useState(false)

    //#region Dias da semana
    const [segunda, setSegunda] = useState<boolean>(false);
    const [terca, setTerca] = useState<boolean>(false);
    const [quarta, setQuarta] = useState<boolean>(false);
    const [quinta, setQuinta] = useState<boolean>(false);
    const [sexta, setSexta] = useState<boolean>(false);
    const [sabado, setSabado] = useState<boolean>(false);
    const [domingo, setDomingo] = useState<boolean>(false);

    function criarBot(e: any) {

        e.preventDefault()
        const dados = {
            nome: nome,
            status: status,
            id_bot: params.id_bot,
            usuarioCriacao: getSessionData("NomeUsuario"),
            inicio: funcionamentoInico,
            fim: funcionamentoFim,
            usaInteracoes,
            segunda: segunda ? 1 : 0,
            terca: terca ? 1 : 0,
            quarta: quarta ? 1 : 0,
            quinta: quinta ? 1 : 0,
            sexta: sexta ? 1 : 0,
            sabado: sabado ? 1 : 0,
            domingo: domingo ? 1 : 0
        }

        if (funcionamentoFim === "" || funcionamentoInico === "") {
            toast.info("Preencha horário inicio e fim do funcionamento do bot")
            setLoading(false)
            return
        }

        axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/bote/mensagens/${getSessionData("DadosIdfilial")}`, dados, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        }).then(function (resposta) {

            if (resposta.data.codigo == 200) {

                toast.success(resposta.data.message)
                setTimeout(function () {
                    navigate(-1)
                }, 1000)
            }
            else {

                toast.error(resposta.data.message)
            }
        }).catch(function (erro) {

            toast.error(erro)
        })
    }

    const [loading, setLoading] = useState<boolean>(false);

    function CarregaInfoBot(id_bot: any) {

        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/bot/filial/${id_bot}/${id_filial}`, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        }).then(function (resposta) {
            set_status(resposta.data.bot_info[0].status)
            set_nome(resposta.data.bot_info[0].nome)
            setFuncionamentoIncio(resposta.data.bot_info[0].inicio)
            setFuncionamentoFim(resposta.data.bot_info[0].fim)
            setUsaInteracoes(resposta.data.bot_info[0].usainteracao)
            setSegunda(resposta.data.bot_info[0].segunda)
            setTerca(resposta.data.bot_info[0].terca)
            setQuarta(resposta.data.bot_info[0].quarta)
            setQuinta(resposta.data.bot_info[0].quinta)
            setSexta(resposta.data.bot_info[0].sexta)
            setSabado(resposta.data.bot_info[0].sabado)
            setDomingo(resposta.data.bot_info[0].domingo)
            setLoading(false)
        }).catch(function (erro) {

            setLoading(false)
            toast.error("Erro ao carregar dados")
        })
    }

    useEffect(function () {

        if (params.acao != "criar") {

            CarregarPermissões(getSessionData("id_perfil"), id_filial)
            CarregaInfoBot(params.id_bot)
        }
    }, [])

    //verifica permissão --- inicio
    const [permissao_editar, set_permissao_editar] = useState(true)
    const [permissao_excluir, set_permissao_excluir] = useState(true)

    async function CarregarPermissões(id_perfil: any, id_filial: any) {
        setLoading(true);
        const headers = {
            Authorization: getSessionData("MultfilialWebToken")!,
        };
        const dados = {
            url: "/perfil/carregar/:idperfil/:idfilial",
            parametros: {
                idperfil: id_perfil,
                idfilial: id_filial
            },
            headers: headers,
        };
        ApiGet(dados).then(function (response) {
            if (response[0].EditarBot === 1) {
                set_permissao_editar(false)
            }
            if (response[0].ExcluirBot === 1) {
                set_permissao_excluir(false)
            }

            setLoading(false)
        })
    }

    function atualizarBot(e: any) {

        e.preventDefault()
        setLoading(true)
        const dados = {
            nome: nome,
            status: status,
            id_bot: params.id_bot,
            usuarioCriacao: getSessionData("NomeUsuario"),
            inicio: funcionamentoInico,
            fim: funcionamentoFim,
            usaInteracoes,
            segunda: segunda ? 1 : 0,
            terca: terca ? 1 : 0,
            quarta: quarta ? 1 : 0,
            quinta: quinta ? 1 : 0,
            sexta: sexta ? 1 : 0,
            sabado: sabado ? 1 : 0,
            domingo: domingo ? 1 : 0
        }

        if (funcionamentoFim === "" || funcionamentoInico === "") {
            toast.info("Preencha horário inicio e fim do funcionamento do bot")
            setLoading(false)
            return
        }

        axios.put(`${process.env.REACT_APP_API_BASE_URL}/atualiza/bot/filial/${getSessionData("DadosIdfilial")}`, dados, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        }).then(function (resposta) {
            setLoading(false)
            if (resposta.data.codigo == 200) {

                toast.success(resposta.data.message)
                setTimeout(function () {
                    navigate(-1)
                }, 1000)
            }
            else {

                toast.error(resposta.data.message)
            }
        }).catch(function (erro) {
            setLoading(false)
            toast.error(erro)
        })
    }

    const [qrcode64, set_qrcode64] = useState("")
    function ConsultarQRCode() {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consultar/qr/bot/${params.id_bot}/${getSessionData("DadosIdfilial")}`, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        }).then(function (resposta) {
            setLoading(false)
            if (resposta.data.codigo == 200) {

                CarregaInfoBot(params.id_bot)
                set_qrcode64(resposta.data.qrcode_64)
            }
            else {

                toast.error(resposta.data.message)
            }
        }).catch(function (erro) {
            setLoading(false)
            toast.error(erro)
        })
    }

    function PararBot() {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/parar/qr/bot/${params.id_bot}/${getSessionData("DadosIdfilial")}`, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        }).then(function (resposta) {

            setLoading(false)
            if (resposta.data.codigo == 200) {

                CarregaInfoBot(params.id_bot)
                set_qrcode64("")
                toast.success(resposta.data.message)
            }
            else {

                toast.error(resposta.data.message)
            }
        }).catch(function (erro) {

            setLoading(false)
            toast.error(erro)
        })
    }

    const [guiaAtual, set_guiaAtual] = useState("")
    //#region região da aba INTERAÇÕES
    //tipando meus objetos Menu e Array de Menus
    const [ListaDeMenus, setListaDeMenusMenus] = useState<menuBot[]>([])
    const [menu, setMenu] = useState<menuBot>({
        id_menu: "",
        id_filial: sessionStorage.getItem("DadosIdfilial")!,
        id_bot: params.id_bot,
        nomeMenu: "",
        data_criacao: "",
        usuario: ""
    })
    //criando os campos da tabela para listagem dos menus
    const colunasMenu: ITabela[] = [
        { titulo: "Menu", acesso: "nomeMenu" },
        { titulo: "Usuario", acesso: "usuario" }
    ]
    //estado do modal de criar/editar/visualizar menu
    const [showModalMenuBot, setShowModalMenuBot] = useState<boolean>(false)
    const [acaoModalMenu, setAcaoModalMenu] = useState<extendendoMenuBot>({
        fechar: function () {

        },
        acao: "novo",
        ListaOpcoes: []
    })
    //função criada para fechar modal do bot independente da AÇÃO
    function manipularEstadoModal() {
        setShowModalMenuBot(!showModalMenuBot)
    }
    //carregando menus criados
    function CarregarMenusCriados() {
        setLoading(true)
        axios.get(process.env.REACT_APP_API_BASE_URL + `/carregar/menus/bot/${id_filial}/${params.id_bot}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setLoading(false)
            setListaDeMenusMenus(resposta.data.ListaDeMenus)
        }).catch(function (erro) {
            setLoading(false)
            toast.error(erro.response.data.message)
        })
    }
    useEffect(function () {
        if (showModalMenuBot == false) {
            CarregarMenusCriados()
        }
    }, [showModalMenuBot])
    //função para deletar o menu e suas opções
    function DeletarMenu(menu: menuBot) {
        showConfirmationDialog("Deletar Menu", "Deseja excluir o Meny?", "question")
            .then(function (acao) {
                if (acao.confirmed) {
                    setLoading(true)

                    axios.delete(process.env.REACT_APP_API_BASE_URL + `/deletar/menu/criado/${id_filial}/${menu.id_bot}/${menu.id_menu}`, {
                        headers: {
                            Authorization: token
                        }
                    }).then(function (resposta) {
                        setLoading(false)
                        toast.success(resposta.data.message)
                        CarregarMenusCriados()
                    }).catch(function (erro) {
                        setLoading(false)
                        toast.error(erro.response.data.message)
                    })
                }
            })
    }
    //abrir modal do menu para ação de criar novo
    function abrirMenuCriarNovo() {
        setAcaoModalMenu({
            ...acaoModalMenu,
            acao: "novo",
            id_menu: "novo",
            id_bot: params.id_bot,
            id_filial: id_filial!,
            nomeMenu: ""
        })
        manipularEstadoModal()
    }
    //abrir modal do menu para ação de visualizar
    function abrirMenuVisualizar(menu: menuBot) {
        setAcaoModalMenu({
            ...acaoModalMenu,
            acao: "visualizar",
            id_menu: menu.id_menu,
            id_bot: menu.id_bot,
            id_filial: menu.id_filial,
            nomeMenu: menu.nomeMenu
        })
        manipularEstadoModal()
    }
    //abrir modal do menu para ação de editar
    function abrirMenuEditar(menu: menuBot) {
        setAcaoModalMenu({
            ...acaoModalMenu,
            acao: "editar",
            id_menu: menu.id_menu,
            id_bot: menu.id_bot,
            id_filial: menu.id_filial,
            nomeMenu: menu.nomeMenu
        })
        manipularEstadoModal()
    }
    return (
        <>
            <div className="col-sm col-md-12 col-lg-12 mt-2">
                <ContainerTitulo titulo={params.acao == "criar" ? "Criar novo Bot" : params.acao == "editar" ? "Editar Bot" : "Visualizar Bot"} />
                <Card>
                    <Form onSubmit={params.acao == "criar" ? criarBot : atualizarBot}>
                        <Box sx={{ width: "100%" }}>
                            <TabContext value={guia}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <TabList
                                        onChange={capturaGuia}
                                        TabIndicatorProps={{
                                            style: {
                                                backgroundColor: "#000",
                                                color: "#000",
                                            },
                                        }}
                                    >
                                        <Tab onClick={function () {
                                            set_guiaAtual("")
                                        }} label="Dados Básicos" value="1" />

                                        <Tab onClick={function () {
                                            set_guiaAtual("")
                                        }} label="Opções" value="2" />
                                        {
                                            params.acao != "criar" &&
                                            <Tab onClick={function () {

                                                set_guiaAtual("3")
                                            }} label="Interações" value="3" />
                                        }

                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <div className="container m-0 p-0">
                                        <div className="row m-0 p-0">
                                            <div className="col-sm-1 col-md-3 col-lg-2">
                                                <InputSemBorda
                                                    label="ID"
                                                    name="ID"
                                                    type="text"
                                                    placeholder="ID"
                                                    required
                                                    readonly={params.acao === "visualizar" ? true : false}
                                                    value={params.id_bot || ""}
                                                    onChange={function () {

                                                    }}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                                                />
                                            </div>
                                            <div className="col-sm-4 col-md-6 col-lg-4">
                                                <InputSemBorda
                                                    label="Nome"
                                                    name="Nome"
                                                    type="text"
                                                    placeholder="Nome"
                                                    required
                                                    readonly={params.acao === "visualizar" ? true : false}
                                                    value={nome}
                                                    onChange={function (e) {
                                                        set_nome(e.replace(/[^a-zA-Z]/g, ''))
                                                    }}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value="2">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm col-md-6 col-lg-2 pt-2">
                                                <button type="button" disabled={params.acao === "visualizar" || params.acao == "criar" || status == true ? true : false} className="d-block w-100 btn btn-secondary btn-sm" onClick={ConsultarQRCode}>Ligar Bot</button>
                                                <br />
                                                <div className="" hidden={qrcode64 != "" && qrcode64 != undefined ? false : true}>
                                                    <img className="d-block m-auto w-75" src={qrcode64} alt="" />
                                                    <br />
                                                    <ul>
                                                        <li>Leia o QRcode com o WhatsApp que deseja vincular o Bot.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-sm col-md-6 col-lg-2 pt-2">
                                                <button disabled={params.acao === "visualizar" || params.acao == "criar"} type="button" className="d-block w-100 btn btn-secondary btn-sm" onClick={PararBot}>Pausar Bot</button>
                                                <br />
                                            </div>
                                            <div className="col-sm-4 col-md-6 col-lg-4">
                                                <InputSemBorda
                                                    label="Horário de funcionamento - Inicio"
                                                    name="Nome"
                                                    type="time"
                                                    placeholder="Horário de funcionamento"
                                                    required
                                                    readonly={params.acao === "visualizar" ? true : false}
                                                    value={funcionamentoInico}
                                                    onChange={setFuncionamentoIncio}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                                                />
                                            </div>
                                            <div className="col-sm-4 col-md-6 col-lg-4">
                                                <InputSemBorda
                                                    label="Horário de funcionamento - Fim"
                                                    name="Nome"
                                                    type="time"
                                                    placeholder="Horário de funcionamento"
                                                    required
                                                    readonly={params.acao === "visualizar" ? true : false}
                                                    value={funcionamentoFim}
                                                    onChange={setFuncionamentoFim}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                                                />
                                            </div>
                                            <div className="w-100"></div>
                                            <div className="col-sm-4 col-md-2 col-lg-2 text-center">
                                                <label htmlFor="" className="form-label me-2">
                                                    Ativo
                                                </label>
                                                <Switch
                                                    checked={status}
                                                    onChange={function () {
                                                    }}
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className="col-sm-4 col-md-3 col-lg-3">
                                                <label htmlFor="" className="form-label me-2">
                                                    Usa Interações
                                                </label>
                                                <Switch
                                                    checked={usaInteracoes}
                                                    onChange={function () {
                                                        setUsaInteracoes(!usaInteracoes)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="container mt-3 mb-4">
                                        <fieldset className="p-2">
                                            <legend className="w-auto px-2">Selecione os dias</legend>

                                            <div className="row mt-2">
                                                <div className="col-sm-4 col-md-2 col-lg-2 text-center d-flex flex-column align-items-center">
                                                    <label htmlFor="" className="form-label me-2">
                                                        Segunda-feira
                                                    </label>
                                                    <Switch
                                                        checked={segunda}
                                                        onChange={(e) => setSegunda(e.target.checked)}
                                                    />
                                                </div>

                                                <div className="col-sm-4 col-md-2 col-lg-2 text-center d-flex flex-column align-items-center">
                                                    <label htmlFor="" className="form-label me-2">
                                                        Terça-feira
                                                    </label>
                                                    <Switch
                                                        checked={terca}
                                                        onChange={(e) => setTerca(e.target.checked)}
                                                    />
                                                </div>

                                                <div className="col-sm-4 col-md-2 col-lg-2 text-center d-flex flex-column align-items-center">
                                                    <label htmlFor="" className="form-label me-2">
                                                        Quarta-feira
                                                    </label>
                                                    <Switch
                                                        checked={quarta}
                                                        onChange={(e) => setQuarta(e.target.checked)}
                                                    />
                                                </div>

                                                <div className="col-sm-4 col-md-2 col-lg-2 text-center d-flex flex-column align-items-center">
                                                    <label htmlFor="" className="form-label me-2">
                                                        Quinta-feira
                                                    </label>
                                                    <Switch
                                                        checked={quinta}
                                                        onChange={(e) => setQuinta(e.target.checked)}
                                                    />
                                                </div>

                                                <div className="col-sm-4 col-md-2 col-lg-2 text-center d-flex flex-column align-items-center">
                                                    <label htmlFor="" className="form-label me-2">
                                                        Sexta-feira
                                                    </label>
                                                    <Switch
                                                        checked={sexta}
                                                        onChange={(e) => setSexta(e.target.checked)}
                                                    />
                                                </div>

                                                <div className="col-sm-4 col-md-2 col-lg-2 text-center d-flex flex-column align-items-center">
                                                    <label htmlFor="" className="form-label me-2">
                                                        Sábado
                                                    </label>
                                                    <Switch
                                                        checked={sabado}
                                                        onChange={(e) => setSabado(e.target.checked)}
                                                    />
                                                </div>

                                                <div className="col-sm-4 col-md-2 col-lg-2 text-center d-flex flex-column align-items-center">
                                                    <label htmlFor="" className="form-label me-2">
                                                        Domingo
                                                    </label>
                                                    <Switch
                                                        checked={domingo}
                                                        onChange={(e) => setDomingo(e.target.checked)}
                                                    />
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>


                                </TabPanel>
                                <TabPanel value="3">
                                    <div className="container m-0 p-0">
                                        <div className="row">
                                            <div className="col-sm col-md-12 col-lg-12">
                                                <button onClick={abrirMenuCriarNovo} type="button" className="btn btn-sm btn-secondary btn-sm">
                                                    <i className="bi bi-plus-lg me-1"></i>
                                                    Novo Menu
                                                </button>
                                            </div>
                                        </div>
                                        {ListaDeMenus.length > 0 &&
                                            <div className="row">
                                                <div className="col-sm col-md-12 col-lg-12">
                                                    <p className="text-center">Lista de menus criados.</p>
                                                </div>
                                                <ClickableTable
                                                    coluna={colunasMenu}
                                                    data={ListaDeMenus}
                                                    onRowClick={function () {

                                                    }}
                                                    itemsPerPage={10}
                                                    usaAcoes={true}
                                                    usaEditar={true}
                                                    usaExcluir={true}
                                                    usaVisualizar={true}
                                                    acaoEditar={abrirMenuEditar}
                                                    acaoExcluir={DeletarMenu}
                                                    acaoVisualizar={abrirMenuVisualizar}
                                                />
                                            </div>
                                        }
                                    </div>
                                </TabPanel>
                            </TabContext>
                        </Box>
                        <Card.Footer>
                            <div className="d-flex my-2 gap-1 justify-content-between">
                                <ButtonCustom
                                    invisivel={false}
                                    type="button"
                                    className="btn btn-light text-danger bg-white border-0"
                                    descricaoBotao="Voltar"
                                    icone={mdiKeyboardReturn}
                                    onclick={() => {
                                        navigate(-1);
                                    }}
                                />
                                <ButtonCustom
                                    invisivel={false}
                                    type="submit"
                                    className="btn btn-light text-info-emphasis bg-white border-0"
                                    descricaoBotao="Salvar"
                                    icone={mdiCheckCircle}
                                    disabled={params.acao === "visualizar" ? true : false}
                                />
                            </div>
                        </Card.Footer>
                    </Form>
                </Card>

                <ModalLoading show={loading} label="Carregando" />
                <FormMenuBot
                    show={showModalMenuBot}
                    fechar={manipularEstadoModal}
                    id_filial={menu.id_filial}
                    id_bot={menu.id_bot}
                    id_menu={acaoModalMenu.id_menu}
                    nomeMenu={acaoModalMenu.nomeMenu}
                    acao={acaoModalMenu.acao}
                    ListaOpcoes={[]}
                />
            </div >
        </>
    )
}

export default FormularioBots