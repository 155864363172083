import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda";
import { Button } from "../../../../components/Formularios/Buttons/Button"
import {
    Switch,
    Avatar,
    IconButton,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Box,
    Tab,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { Button as ButtonCustom } from "../../../../components/Formularios/Buttons/Button";
import {
    mdiCheckAll,
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiPlus,
    mdiBookArrowDown
} from "@mdi/js";
import { useNavigate } from "react-router-dom";
import ModalUploadImage from "../../../../components/Formularios/Modal/ModalUpload";
import { toast } from "react-toastify";
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading";
import ApiGet from "../../../../api/endPoints/useGet";
import ApiPut from "../../../../api/endPoints/usePut";
import ApiPost from "../../../../api/endPoints/usePost";
import ConverterBase64 from "../../../../components/ConversorBase64";
import { getSessionData } from "../../../../utils/storageUtils";
import ValidaComplexidadeSenha from "../../../../components/Formularios/ValidaComplexidadeSenha";
import ConsultaComplexidade from "../../../../components/ComplexidadeSenha";
import { TextoFormatoCaptalize } from "../../../../components/Auxiliar/ConvTextoCaptalize";
import { TextoFormatoLowerCase } from "../../../../components/Auxiliar/ConvTextoLower";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface";
import Tabela from "../../../../components/Formularios/Table";
import { showConfirmationDialog } from "../../../../components/Formularios/Modal/ModalExcluir";
import ApiDelete from "../../../../api/endPoints/useDelete";
import axios from "axios";
import ClickableTable from "../../../../components/ClickableTable/ClickableTabl";

interface FormMetasProps {
    idmeta?: string;
    acao?: string;
}

interface Cliente {
    id_cliente: number; // ou string, dependendo do seu caso
    fantasia: string;
}

interface Sistema {
    codigo: number; // ou string, dependendo do seu caso
    descricao: string;
}

const FormMetas: React.FC<FormMetasProps> = ({ idmeta, acao }) => {
    const [clientes, setClientes] = useState<Cliente[]>([]);
    const [clienteSelecionado, setClienteSelecionado] = useState<string>("");
    const [idCliente, setidCliente] = useState<number>();
    const [sistema, setSistema] = useState<Sistema[]>([]);
    const [idSistema, setIdSistema] = useState<number>();
    const [sistemaSelecionado, setSistemaSelecionado] = useState<string>("");
    const [prioridades, setPrioridades] = useState([]);
    const [prioridadeSelecionada, setPrioridadeSelecionada] = useState<string>("");
    const [disabledPrioridade, setDisabledPrioridade] = useState<boolean>(false)
    const [metaPrimeiroContato, setMetaPrimeiroContato] = useState('')
    const [metaConclusao, setMetaConclusao] = useState('')
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false);
    const token = getSessionData("MultfilialWebToken") || "";

    // Funcao criada para preencher o select box de clientes na tela de criacao de ticket
    function buscarClientesSelectbox() {
        const id_filial = getSessionData("DadosIdfilial")
        const id_usuario = getSessionData("DadosUsuarioID")

        // Faz uma requisição GET para obter dados de vários select boxes da API
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/clientes/selectBox/metas`, {
            headers: {
                Authorization: token // Adiciona o token de autorização no cabeçalho da requisição
            },
            params: {
                id_filial,
                id_usuario
            }
        }).then(function (resposta) {
            setClientes(resposta.data.dataClientes)
        }).catch(function (erro) {
            // Trata erros de autorização e exibe uma mensagem para o usuário
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    useEffect(function () {
        buscarClientesSelectbox()
    }, [])

    // Funcao criada para preencher o select box de prioridades na tela de criacao de ticket
    function buscarPrioridadesSelectbox() {
        const id_filial = getSessionData("DadosIdfilial")

        // Faz uma requisição GET para obter dados de vários select boxes da API
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/prioridades/selectBox/metas`, {
            headers: {
                Authorization: token // Adiciona o token de autorização no cabeçalho da requisição
            },
            params: {
                id_filial,
                idSistema,
                acao
            }
        }).then(function (resposta) {
            setPrioridades(resposta.data.dataPrioridades)
        }).catch(function (erro) {
            // Trata erros de autorização e exibe uma mensagem para o usuário
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    useEffect(function () {
        buscarPrioridadesSelectbox()
    }, [sistemaSelecionado])

    function buscarSistemasSelectbox() {
        const id_filial = getSessionData("DadosIdfilial")

        // Faz uma requisição GET para obter dados de vários select boxes da API
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/sistemas/selectBox/metas`, {
            headers: {
                Authorization: token // Adiciona o token de autorização no cabeçalho da requisição
            },
            params: {
                id_filial,
                idCliente
            }
        }).then(function (resposta) {
            setSistema(resposta.data.dataSistemas)
        }).catch(function (erro) {
            // Trata erros de autorização e exibe uma mensagem para o usuário
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    useEffect(function () {
        if (clienteSelecionado) {
            buscarSistemasSelectbox()
        } else if (clienteSelecionado === '') {
            setSistemaSelecionado('')
        }
    }, [clienteSelecionado])

    function gravarDados(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const idFilial = getSessionData("DadosIdfilial")

        const dados = {
            idCliente,
            idSistema,
            prioridadeSelecionada,
            idFilial,
            metaPrimeiroContato,
            metaConclusao
        }

        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/gravar/dados/metas`, dados, {
            headers: {
                Authorization: token // Adiciona o token de autorização no cabeçalho da requisição
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            setTimeout(function () {
                navigate(-1)
            }, 2000)
            setLoading(false)
        }).catch(function (erro) {
            setLoading(false)
            // Trata erros de autorização e exibe uma mensagem para o usuário
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    //Função que ira preencher os dados da meta ja cadastrada na tela de edição ou visualização
    function buscarDadosCadastrados() {
        const idFilial = getSessionData("DadosIdfilial")

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/dados/metas/${idFilial}/${idmeta}`, {
            headers: {
                Authorization: token // Adiciona o token de autorização no cabeçalho da requisição
            }
        }).then(function (resposta) {
            setClienteSelecionado(resposta.data.data.fantasia)
            setidCliente(resposta.data.data.id_cliente)
            setSistemaSelecionado(resposta.data.data.descricao)
            setIdSistema(resposta.data.data.id_sistema)
            setPrioridadeSelecionada(resposta.data.data.prioridade)
            setMetaPrimeiroContato(resposta.data.data.primeiro_contato)
            setMetaConclusao(resposta.data.data.conclusao)
        }).catch(function (erro) {
            // Trata erros de autorização e exibe uma mensagem para o usuário
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    useEffect(function () {
        if (acao === 'Editar' || acao === 'Visualizar') {
            buscarDadosCadastrados()
        }
    }, [])

    function alterarDados(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const idFilial = getSessionData("DadosIdfilial")

        const dados = {
            idCliente,
            idSistema,
            prioridadeSelecionada,
            idFilial,
            metaPrimeiroContato,
            metaConclusao,
            idmeta
        }

        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/alterar/dados/metas`, dados, {
            headers: {
                Authorization: token // Adiciona o token de autorização no cabeçalho da requisição
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            setTimeout(function () {
                navigate(-1)
            }, 2000)
            setLoading(false)
        }).catch(function (erro) {
            setLoading(false)
            // Trata erros de autorização e exibe uma mensagem para o usuário
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    return (
        <>
            <Card>
                <Form onSubmit={acao === 'Novo' ? gravarDados : alterarDados}>
                    <Card.Body>
                        <Box sx={{ width: "100%" }}>
                            <Row className="d-flex flex-wrap">
                                <Col sm={12}>
                                    <Card className="p-3 border shadow">
                                        <Row>
                                            <div className="col-12 col-sm-6 col-md-2 col-lg-3">
                                                <div className="form-floating">
                                                    <select
                                                        autoFocus
                                                        required
                                                        className="form-select bordasInferiorInput text-black text-capitalize"
                                                        onChange={(event) => {
                                                            const selectedClient = event.target.value;
                                                            setClienteSelecionado(selectedClient);

                                                            const cliente = clientes.find(item => item.fantasia === selectedClient);
                                                            if (cliente) {
                                                                setidCliente(cliente.id_cliente);
                                                            }
                                                        }}
                                                        value={clienteSelecionado}
                                                        disabled={acao === 'Visualizar'}
                                                    >
                                                        <option value={""}>Selecione...</option>
                                                        {clientes.map((item: any) => (
                                                            <option key={item.id_cliente} value={item.fantasia}>
                                                                {item.fantasia}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <label className="form-label">Cliente</label>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-2 col-lg-3" hidden={!clienteSelecionado}>
                                                <div className="form-floating">
                                                    <select
                                                        autoFocus
                                                        required
                                                        className="form-select bordasInferiorInput text-black text-capitalize"
                                                        onChange={(event) => {
                                                            const selectedSistema = event.target.value;
                                                            setSistemaSelecionado(selectedSistema);

                                                            const system = sistema.find(item => item.descricao === selectedSistema);
                                                            if (system) {
                                                                setIdSistema(system.codigo);
                                                            }
                                                        }}
                                                        value={sistemaSelecionado}
                                                        disabled={acao === 'Visualizar'}
                                                    >
                                                        <option value={""}>Selecione...</option>
                                                        {sistema.map((item: any) => (
                                                            <option value={item.descricao}>{item.descricao}</option>
                                                        ))}
                                                    </select>
                                                    <label className="form-label">Sistema</label>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-2 col-lg-3" hidden={!clienteSelecionado || !sistemaSelecionado}>
                                                <div className="form-floating">
                                                    <select
                                                        autoFocus
                                                        className="form-select bordasInferiorInput text-black text-capitalize"
                                                        onChange={(event) => setPrioridadeSelecionada(event.target.value)}
                                                        value={prioridadeSelecionada}
                                                        disabled={disabledPrioridade || acao === 'Visualizar'}
                                                    >
                                                        <option value={""}>Selecione...</option>
                                                        {prioridades.map((item: any) => (
                                                            <option value={item.descricao}>{item.descricao}</option>
                                                        ))}
                                                    </select>
                                                    <label className="form-label">Prioridade</label>
                                                </div>
                                            </div>
                                        </Row>
                                        <Row>
                                            <div className="col-12 col-sm-6 col-md-2 col-lg-3 mt-2">
                                                <div className="form-floating" style={{ marginTop: '-2px' }}>
                                                    <InputSemBorda
                                                        readonly={acao === 'Visualizar'}
                                                        label="Meta Primeiro Contato"
                                                        name="metaPC"
                                                        required
                                                        type="number"
                                                        placeholder="Meta Primeiro Contato"
                                                        value={metaPrimeiroContato}
                                                        onChange={setMetaPrimeiroContato}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                                    />
                                                    <h6>Tempo esperado em formato decimal para primeiro contato no ticket. (Ex: 1h30min = 1.5)</h6>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-2 col-lg-3 mt-2">
                                                <div className="form-floating" style={{ marginTop: '-2px' }}>
                                                    <InputSemBorda
                                                        readonly={acao === 'Visualizar'}
                                                        label="Meta Conclusão"
                                                        name="metaConclusao"
                                                        required
                                                        type="number"
                                                        placeholder="Meta Conclusão"
                                                        value={metaConclusao}
                                                        onChange={setMetaConclusao}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                                    />
                                                    <h6>Tempo esperado em formato decimal para conclusão do ticket. (Ex: 2h30min = 2.5)</h6>
                                                </div>
                                            </div>
                                        </Row>
                                    </Card>
                                    <Row>
                                        <div className="col col-sm col-md-2 col-lg-6">
                                            <div className="d-flex justify-content-start mb-2">
                                                <Button
                                                    invisivel={false}
                                                    className="btn btn-dark text-info-emphasis bg-secondary border-0 rounded"
                                                    descricaoBotao="Voltar"
                                                    type='button'
                                                    onclick={function () {
                                                        navigate(-1)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col col-sm col-md-2 col-lg-6">
                                            <div className="d-flex justify-content-end mb-2">
                                                <Button
                                                    invisivel={acao === 'Visualizar'}
                                                    className="btn btn-dark text-info-emphasis bg-primary border-0 rounded"
                                                    descricaoBotao="Salvar"
                                                    icone={mdiCheckAll}
                                                    type='submit'
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                        </Box>
                    </Card.Body>
                </Form>
            </Card>
        </>
    )
}

export default FormMetas