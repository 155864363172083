import { Modal } from "react-bootstrap"
import { useEffect, useState } from "react"
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda"
import extendendoMenuBot from "../interfaces/extendendoMenuBot"
import itemMenuBot from "../interfaces/itemMenuBot"
import ClickableTable from "../../../../components/ClickableTable/ClickableTabl"
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface"
import FormItemMenuBot from "./formItemMenuBot"
import { showConfirmationDialog } from "../../../../components/Formularios/Modal/ModalExcluir"
import { toast } from "react-toastify"
import axios from "axios"
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading"
//extentendo a tipagem para adicionar novas propriedades ao tipo "menuBot"
function FormMenuBot({
    id_menu = "",
    id_filial,
    id_bot,
    nomeMenu = "",
    fechar,
    show,
    acao,
    ListaOpcoes
}: extendendoMenuBot) {
    //variaveis locais
    const token = sessionStorage.getItem("MultfilialWebToken")!
    const [idMenu, setIdMenu] = useState<string>(id_menu)
    const [nome_menu, setNome_menu] = useState<string>(nomeMenu)
    const [listaOpcoesMenu, setListaOpcoesMenu] = useState(ListaOpcoes)
    //dispara ação do form
    const [showModalCarregando, setShowModalCarregando] = useState(false)
    function Submit(evento: React.FormEvent<HTMLFormElement>) {
        evento.preventDefault()
        setShowModalCarregando(true)
        if (acao == "novo") {
            //cria novo menu
            axios.post(process.env.REACT_APP_API_BASE_URL + `/criar/menu/opcaoes/${id_filial}/${id_bot}`, {
                nome_menu,
                usuario: sessionStorage.getItem("NomeUsuario")!
            }, {
                headers: {
                    Authorization: token
                }
            }).then(function (resposta) {
                setShowModalCarregando(false)
                toast.success(resposta.data.message)
                fecharModal()
            }).catch(function (erro) {
                setShowModalCarregando(false)
                toast.error(erro.response.data.message)
            })
        }
        else if (acao == "editar") {
            axios.put(process.env.REACT_APP_API_BASE_URL + `/atualizar/menu/nome/${id_filial}/${id_bot}/${id_menu}`, {
                nome_menu
            }, {
                headers: {
                    Authorization: token
                }
            }).then(function (resposta) {
                setShowModalCarregando(false)
                toast.success(resposta.data.message)
                fecharModal()
            }).catch(function (erro) {
                setShowModalCarregando(false)
                toast.error(erro.response.data.message)
            })
        }
    }
    //limpar Inputs
    function limparInputs() {
        setIdMenu("")
        setNome_menu("")
        setListaOpcoesMenu([])
    }
    //função para fecharModal
    function fecharModal() {
        fechar()
        limparInputs()
    }
    //colunas do item do menu
    const colunasItem: ITabela[] = [
        { titulo: "Opção", acesso: "id_item" },
        { titulo: "Menu de Retorno", acesso: "nomeMenu" },
        { titulo: "Descrição", acesso: "descricao" }
    ]
    //#region região para controlar o modal de criar item(opção) do menu
    const [showFormOpcoesMenu, setShowFormOpcoesMenu] = useState(false)
    function alterarEstadoOpcoesMenu() {
        if (acao != "novo") {
            setShowFormOpcoesMenu(!showFormOpcoesMenu)
        }
        else {
            toast.info("É necessario criar o menu para criar suas opções.")
        }

    }
    //deleta opção da lista de opções
    function deletarOpcao(item: itemMenuBot) {
        showConfirmationDialog('Deletar Opção do Menu.', 'Confirmar a exclusão do menu?', 'question')
            .then(function (confirmed) {
                if (confirmed.confirmed) {
                    setShowModalCarregando(true)
                    axios.delete(process.env.REACT_APP_API_BASE_URL + `/delete/opcao/menu/${item.id_filial}/${item.id_menu}/${item.id_item}`, {
                        headers: {
                            Authorization: token
                        }
                    }).then(function (resposta) {
                        setShowModalCarregando(false)
                        toast.success(resposta.data.message)
                        carregarInfoMenu(id_filial, id_bot, id_menu)
                    }).catch(function (erro) {
                        toast.error(erro.response.data.message)
                        setShowModalCarregando(false)
                    })
                }
            })
    }
    //carrega informações do menu (menu e suas opções)
    function carregarInfoMenu(idFilial: string | number | undefined, idBot: string | number | undefined, idMenu: string | number | undefined) {
        setShowModalCarregando(true)
        axios.get(process.env.REACT_APP_API_BASE_URL + `/carregar/menu/selecionado/${idFilial}/${idBot}/${idMenu}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setShowModalCarregando(false)
            setListaOpcoesMenu(resposta.data.opcoes)
            setNome_menu(resposta.data.menu.nomeMenu)
        }).catch(function (erro) {
            setShowModalCarregando(false)
            toast.error(erro.response.data.message)
        })
    }
    //useEffect, roda sempre o que o modal abre
    useEffect(function () {
        if (show == true && (show == true || showFormOpcoesMenu == false) && acao !== "novo") {
            //carrega informações do menu
            carregarInfoMenu(id_filial, id_bot, id_menu)
        }
    }, [show, showFormOpcoesMenu])
    //objeto para controlar modal de criar opção
    const [opcao, setOpcao] = useState<itemMenuBot>()
    //abrir modal para criar nova opção
    function abrirModalNovaOpcao() {
        setOpcao({
            ...opcao,
            id_item: "",
            id_menu: id_menu,
            id_filial: id_filial,
            id_bot: id_bot,
            usuario: sessionStorage.getItem("NomeUsuario")!,
            acao: "novo",
            descricao: "",
            id_menu_retorno: "",
            sem_retorno: false
        })
        alterarEstadoOpcoesMenu()
    }
    //abrir modal para EDITAR opção
    function abrirModalEditarOpcao(item: itemMenuBot) {
        setOpcao({
            ...opcao,
            id_item: item.id_item,
            id_menu: item.id_menu,
            id_filial: item.id_filial,
            id_bot: item.id_bot,
            usuario: sessionStorage.getItem("NomeUsuario")!,
            acao: "editar",
            descricao: item.descricao,
            id_menu_retorno: item.id_menu_retorno,
            sem_retorno: item.sem_retorno
        })
        alterarEstadoOpcoesMenu()
    }
    //abrir modal para VISUALIZAR opção
    function abrirModalvisualizarOpcao(item: itemMenuBot) {
        setOpcao({
            ...opcao,
            id_item: item.id_item,
            id_menu: item.id_menu,
            id_filial: item.id_filial,
            id_bot: item.id_bot,
            usuario: sessionStorage.getItem("NomeUsuario")!,
            acao: "visualizar",
            descricao: item.descricao,
            id_menu_retorno: item.id_menu_retorno,
            sem_retorno: item.sem_retorno
        })
        alterarEstadoOpcoesMenu()
    }
    return (
        <div>
            <Modal centered size="xl" show={show} onHide={fecharModal}>
                <Modal.Header className="bg-white" closeButton>
                    {acao == "novo" ? "Novo Menu" : nomeMenu}
                </Modal.Header>
                <Modal.Body className="bg-white">
                    <div className="container">
                        <form onSubmit={Submit}>
                            <div className="row">
                                <div className="col-sm col-md-2 col-lg-2">
                                    <InputSemBorda
                                        value={id_menu}
                                        type="text"
                                        name="nomedomenu"
                                        onChange={setIdMenu}
                                        label="Id.Menu"
                                        placeholder=" "
                                        readonly
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                    />
                                </div>
                                <div className="col-sm col-md-6 col-lg-3">
                                    <InputSemBorda
                                        value={nome_menu}
                                        type="text"
                                        name="nomedomenu"
                                        onChange={setNome_menu}
                                        label="Nome do Menu"
                                        placeholder=" "
                                        required
                                        readonly={acao == "visualizar" ? true : false}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                    />
                                </div>

                                {
                                    acao != "novo" &&
                                    <div className="col-sm col-md-12 col-lg-2 pt-3 text-end">
                                        <button disabled={acao == "visualizar" ? true : false} onClick={abrirModalNovaOpcao} type="button" className="btn btn-danger btn-sm w-100">Add opção ao Menu</button>
                                    </div>
                                }

                                <div className="col-sm col-md-3 col-lg-2 p-0 pt-3">
                                    <button disabled={acao == "visualizar" ? true : false} type="submit" className="btn btn-sm btn-secondary w-100">
                                        <i className="bi bi-floppy me-1"></i>
                                        {
                                            acao == 'novo' ?
                                                "Criar Menu" : "Salvar"
                                        }
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div className="container ms-0 me-0 mb-0 mt-3 p-0">
                            <div className="row">
                                <div className="col-sm col-md-12 col-lg-12">
                                    <p className="text-center">Lista das opções do Menu</p>
                                </div>
                            </div>
                            <ClickableTable
                                coluna={colunasItem}
                                data={listaOpcoesMenu}
                                onRowClick={function () {

                                }}
                                itemsPerPage={10}
                                usaAcoes={true}
                                usaEditar={acao != "visualizar"}
                                usaExcluir={acao != "visualizar"}
                                usaVisualizar={true}
                                acaoEditar={abrirModalEditarOpcao}
                                acaoExcluir={deletarOpcao}
                                acaoVisualizar={abrirModalvisualizarOpcao}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <ModalLoading
                    show={showModalCarregando}
                    label="Carregando criações"
                />
            </Modal>
            <FormItemMenuBot
                id_item={opcao?.id_item}
                show={showFormOpcoesMenu}
                fechar={alterarEstadoOpcoesMenu}
                id_menu={opcao?.id_menu}
                nomeMenu={nomeMenu}
                id_filial={opcao?.id_filial}
                id_bot={opcao?.id_bot}
                descricao={opcao?.descricao}
                acao={opcao?.acao}
                sem_retorno={opcao?.sem_retorno}
                id_menu_retorno={opcao?.id_menu_retorno}
            />
        </div>
    )
}
export default FormMenuBot