import { useParams } from "react-router-dom";
import ContainerTitulo from "../../components/Formularios/Container/ContainerTitulo";
import FormAgente from "./components/formulario";

function AgentesEditar() {
    const id_usuario = useParams();

    return (
        <div className="col mt-2">
            <ContainerTitulo titulo="Editar agente" />
            <FormAgente idusuario={id_usuario.id} acao="Visualizar" />
        </div>
    )
}

export default AgentesEditar