import { Card, Col, Row } from "react-bootstrap"
import ContainerTitulo from "../../components/Formularios/Container/ContainerTitulo"
import { useEffect, useState } from "react";
import ChatModal from "./components/chatModal";
import axios from "axios";
import { getSessionData } from "../../utils/storageUtils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useConversa } from "../../context/conversaContext";

function ChatSuporte() {
    const [mostraChatModal, setMostraChatModal] = useState<boolean>(false)
    const [clientes, setClientes] = useState([])
    const [clienteSelecionado, setClienteSelecionado] = useState<string>('')
    const [chatsPendentes, setChatsPendentes] = useState([])
    // const [idConversaAtendimento, setIdConversaAtendimento] = useState<string>('')
    const { idDaConversa, setIdDaConversa, tipoUsuario, setTipoUsuario } = useConversa();

    const [viewChatsPendentes, setViewChatsPendentes] = useState<boolean>(true)

    const idPerfil = getSessionData('id_perfil') || ""
    const idFilial = getSessionData('DadosIdfilial') || ""
    const idUsuario = getSessionData('DadosUsuarioID') || ""

    const token = getSessionData('MultfilialWebToken') || "";

    const navigate = useNavigate()

    async function carregaChatsPendentes() {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregaChats/suporte/${idPerfil}/${idFilial}/${idUsuario}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setChatsPendentes(resposta.data.data)
        }).catch(function (erro) {
            const status = erro.response.status;
            setChatsPendentes([])
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente");
                navigate("/login")
            }
        })
    }

    async function carregaClientes() {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/criacaoChat/clientes/${idFilial}/${idUsuario}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setClientes(resposta.data.data)
        }).catch(function (erro) {
            const status = erro.response.status;
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente");
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
            }
        })
    }

    async function vinculaAgenteChat(idConversa: number) {
        await axios.put(`${process.env.REACT_APP_API_BASE_URL}/vincularAgente/chat`, {
            idUsuario,
            idConversa
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
        }).catch(function (erro) {
            const status = erro.response.status;
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente");
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
            }
        })
    }

    //O objetivo dessa função é dividir um array original em subarrays menores, com um tamanho máximo definido
    const chunkArray = (array: any[], size: number) => {
        return array.reduce((resultArray: any[][], item, index) => {
            const chunkIndex = Math.floor(index / size); // Calcula o índice do "chunk" para o item atual
            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = []; // Se o "chunk" ainda não existe, cria um novo
            }
            resultArray[chunkIndex].push(item); // Adiciona o item ao "chunk" correspondente
            return resultArray; // Retorna o array com todos os "chunks" até o momento
        }, []);
    };

    const rows = chunkArray(chatsPendentes, 3);

    useEffect(() => {
        carregaChatsPendentes()
        carregaClientes()
    }, [])

    useEffect(() => {
        if (chatsPendentes.length != 0) {
            setViewChatsPendentes(false)
        } else {
            setViewChatsPendentes(true)
        }
    }, [chatsPendentes])

    return (
        <>
            <ContainerTitulo titulo="Chat do Suporte" />

            <Card>
                <Card.Body>
                    <Row>
                        <Col md={3} sm={6} xs={7}>
                            <div className="container mt-2">
                                <button
                                    onClick={() => {
                                        if (!clienteSelecionado) {
                                            toast.info("Selecione um cliente para iniciar chat")
                                        } else {
                                            setMostraChatModal(true)
                                        }
                                    }}
                                    className="btn btn-primary"
                                >
                                    Iniciar Chat com Suporte
                                </button>
                            </div>
                        </Col>

                        <Col md={4} sm={6} xs={6}>
                            <div className="form-floating">
                                <select
                                    autoFocus
                                    className="form-select bordasInferiorInput text-black text-capitalize"
                                    onChange={(event) => setClienteSelecionado(event.target.value)}
                                    // onKeyDown={(event) => handleKeyDown(event, odoresRef)}
                                    value={clienteSelecionado}
                                >
                                    <option value=''>Selecione...</option>
                                    {clientes.map((cliente: any) => (
                                        <option value={cliente.id_cliente} key={cliente.id_cliente}>{cliente.fantasia}</option>
                                    ))}
                                </select>
                                <label className="form-label">Clientes</label>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-3" hidden={viewChatsPendentes}>
                        <div className="container mt-3 mb-4">
                            <fieldset className="p-2">
                                <legend className="w-auto px-2">Chats Pendentes de Atendimento</legend>
                                {rows.map((row, rowIndex) => (
                                    <div className="row mb-3" key={rowIndex}>
                                        {row.map((chat: any) => (
                                            <div className="col-md-4 col-sm-6 col-12" key={chat.id_conversa}>
                                                <button
                                                    className="btn bg-primary text-white w-100 rounded"
                                                    onClick={() => {
                                                        vinculaAgenteChat(chat.id_conversa);
                                                        console.log(chat)
                                                        console.log(chat.id_conversa)
                                                        setIdDaConversa(chat.id_conversa);
                                                        setTipoUsuario("Suporte");
                                                        setMostraChatModal(true);
                                                    }}
                                                >
                                                    {chat.nome_usuario}
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </fieldset>
                        </div>
                    </Row>
                </Card.Body>
            </Card >

            <ChatModal
                onClose={() => {
                    setMostraChatModal(false)
                    carregaChatsPendentes()
                }}
                show={mostraChatModal}
                idCliente={clienteSelecionado}
                idConversaAtendimento={idDaConversa || ''}
                tipoUsuario={tipoUsuario}
            />
        </>
    )
}

export default ChatSuporte