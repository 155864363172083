import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import InputSemBorda from "../../../../../components/Formularios/Inputs/InputsSemBorda";
import {
  Switch,
  Avatar,
  Button,
  IconButton,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
  Tab,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { Button as ButtonCustom } from "../../../../../components/Formularios/Buttons/Button";
import {
  mdiCheckAll,
  mdiCheckCircle,
  mdiKeyboardReturn,
  mdiPlus,
  mdiBookArrowDown
} from "@mdi/js";
import { useNavigate } from "react-router-dom";
import ModalUploadImage from "../../../../../components/Formularios/Modal/ModalUpload";
import { toast } from "react-toastify";
import ModalLoading from "../../../../../components/Formularios/Modal/ModalLoading";
import ApiGet from "../../../../../api/endPoints/useGet";
import ApiPut from "../../../../../api/endPoints/usePut";
import ApiPost from "../../../../../api/endPoints/usePost";
import ConverterBase64 from "../../../../../components/ConversorBase64";
import { getSessionData } from "../../../../../utils/storageUtils";
import ValidaComplexidadeSenha from "../../../../../components/Formularios/ValidaComplexidadeSenha";
import ConsultaComplexidade from "../../../../../components/ComplexidadeSenha";
import { TextoFormatoCaptalize } from "../../../../../components/Auxiliar/ConvTextoCaptalize";
import { TextoFormatoLowerCase } from "../../../../../components/Auxiliar/ConvTextoLower";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ITabela } from "../../../../../components/Formularios/Table/TabelaInterface";
import Tabela from "../../../../../components/Formularios/Table";
import CarregarDadosEmpresa from "../CarregarEmpresa";
import { url_CarregarDados_Empresa } from "../auxiliar";
import { showConfirmationDialog } from "../../../../../components/Formularios/Modal/ModalExcluir";
import ApiDelete from "../../../../../api/endPoints/useDelete";
import axios from "axios";
import ClickableTable from "../../../../../components/ClickableTable/ClickableTabl";

interface FormUsuarioProps {
  idusuario?: string;
  acao?: string;
}

const FormUsuario: React.FC<FormUsuarioProps> = ({ idusuario, acao }) => {
  const token = getSessionData("MultfilialWebToken") || "";
  const isSuper = getSessionData("isPerfilSuper") || "";
  const idUsuario = getSessionData("DadosUsuarioID") || ""
  const isVinculo = getSessionData("isPerfilVinculo") || "";

  // const InformaVinculo = isSuper === "1" ? true : false;

  //#region Controla as guias
  const [guia, setGuia] = useState("1");

  const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
    setGuia(novaGuia);
  };
  //#endregion

  const navigate = useNavigate();
  const [nome, setNome] = useState<string>("");
  const [login, setLogin] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [senha, setSenha] = useState<string>("");
  const [codigoUsuario, setCodigoUsuario] = useState<string>("");
  const [confirmSenha, setConfirmSenha] = useState<string>("");
  const [telefone, setTelefone] = useState<string>("");
  const [avatar, setAvatar] = useState<string>("");
  const [ativo, setAtivo] = useState<boolean>(true);
  const [empresaVinculada, setEmpresaVinculada] = useState<string>("");
  const [master, setMaster] = useState<number>(0);
  const senhaRef = useRef<HTMLInputElement>(null);
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [mostraClienteVinculados, setMostraClienteVinculados] = useState<boolean>(false);
  const [dadosperfil, setDadosPerfil] = useState<any[]>([]);
  const [perfil, setPerfil] = useState<string>("");
  const [idPerfil, setIdPerfil] = useState(null)
  const [isAgente, setIsAgente] = useState<boolean>(false)
  const [agente, setAgente] = useState<string>('')

  //Controle sobre a exibição do moadal listando os clientes
  const [abrirModalListarClientes, setAbrirModalListarClientes] = useState<boolean>(false);
  const abrirModalClientes = () => setAbrirModalListarClientes(true);
  const fecharModalClientes = () => setAbrirModalListarClientes(false);

  //Controle sobre a exibição do moadal dando a opção de selecionar o perfil
  const [abrirModalSelecionarPerfil, setAbrirModalSelecionarPerfil] = useState<boolean>(false);
  const abrirModalPerfis = () => setAbrirModalSelecionarPerfil(true);
  const fecharModalPerfis = () => setAbrirModalSelecionarPerfil(false);

  const [visualizarSelecionarCliente, setVisualizarSelecionarCliente] = useState<boolean>(false);
  const [visualizarSelecionarPerfil, setVisualizarSelecionarPerfil] = useState<boolean>(true);

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      setShowUpload(true);
      ConverterBase64(file)
        .then((retorno) => {
          setShowUpload(false);
          setAvatar(retorno);
        })
        .catch((erro) => {
          setShowUpload(false);
          toast.error(erro);
        });
    }
  };
  const handleRemoveAvatar = () => {
    setAvatar("");
  };

  //#region REALIZA REQUISIÇÃO PARA EXIBIR OS DADOS DO USUÁRIO

  const carregarPerfil = async (idusuario: any) => {
    const id_filial = getSessionData("DadosIdfilial")
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: `/perfil/listar/${isSuper}/${id_filial}`,
      parametros: {},
      headers: headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setDadosPerfil(retorno);
      })
      .catch((erro) => {
        toast.error(`Erro ao carregar registro. Motivo: ${erro.message}`);
      });
  };
  useEffect(() => {
    carregarPerfil(idusuario);
  }, []);
  //#endregion
  //#region REALIZA REQUISIÇÃO PARA EXIBIR OS DADOS DO USUÁRIO
  const [loading, setLoading] = useState<boolean>(false);
  const carregarDados = async (idusuario: any) => {
    const id_filial = getSessionData('DadosIdfilial')

    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: `/usuario/carregar/:idusuario/${id_filial}`,
      parametros: {
        idusuario: idusuario,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setNome(retorno[0].nome);
        setLogin(retorno[0].nome_usuario);
        setEmail(retorno[0].email);
        setSenha(retorno[0].senha);
        setConfirmSenha(retorno[0].senha);
        setTelefone(retorno[0].telefone);
        if (retorno[0].avatar !== null) {
          setAvatar(retorno[0].avatar);
        }
        if (retorno[0].situacao === 1) {
          setAtivo(true);
        } else {
          setAtivo(false);
        }
        setPerfil(retorno[0].id_perfil);
        setEmpresaVinculada(retorno[0].vinculo);
        setDadosFilial(retorno[0].filial);
        setDadosClientes(retorno[0].clientes);
        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        toast.error(`Erro ao carregar dados. Motivo: ${erro.message}`);
      });
  };
  useEffect(() => {
    if (idusuario !== "novo") {
      carregarDados(idusuario);
    }
  }, [idusuario]);
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA ATUALIZAR OS DADOS DO USUÁRIO
  const atualizarDados = async (
    codigo: any,
    nome: string,
    email: string,
    senha: string,
    telefone: string,
    avatar: string,
    situacao: number,
    id_perfil: string,
    vinculo: string
  ) => {
    const dados = {
      nome: TextoFormatoCaptalize(nome.toLowerCase()),
      email: TextoFormatoLowerCase(email.toLowerCase()),
      senha,
      telefone,
      avatar,
      situacao,
      id_perfil,
      vinculo,
    };

    ApiPut(`/usuario/atualizar/${codigo}`, dados, token)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate("/administrativo/usuario"), 2000);
      })
      .catch((erro) => {
        toast.error(`Erro ao atualizar dados. Motivo: ${erro.message}`);
      });
  };

  //#endregion
  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS DO USUÁRIO
  const gravarDados = (
    nome: string,
    usuario: string,
    email: string,
    senha: string,
    telefone: string,
    avatar: string,
    situacao: number,
    id_perfil: string,
    vinculo: string,
    filiais: any
  ) => {
    const dados = {
      nome: TextoFormatoCaptalize(nome.toLowerCase()),
      usuario: TextoFormatoLowerCase(usuario.toLowerCase()),
      email: TextoFormatoLowerCase(email.toLowerCase()),
      senha,
      telefone,
      avatar,
      situacao,
      id_perfil,
      vinculo,
      filiais,
    };
    ApiPost("/usuario/gravar", dados, token)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        toast.info('Vincule o(s) cliente(s) ao usuário.', { autoClose: 7000 })
        setCodigoUsuario(retorno.id_usuario)
        listarClientes(retorno.id_usuario)
        setMostraClienteVinculados(false)

      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`Erro ao gravar dados. Motivo: ${msg}`);
      });
  };

  useEffect(function () {
    if (acao === 'Novo') {
      setMostraClienteVinculados(true)
    }

    setCodigoUsuario(idusuario || '')
  }, [])

  //#endregion

  const gravar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validaSenha = ConsultaComplexidade(senha);
    if (senha !== confirmSenha) {
      toast.error("A senha e a confirmação não conferem.");
      setSenha("");
      setConfirmSenha("");
      senhaRef.current?.focus();
    } else {
      if (validaSenha < 60 && acao === "Novo") {
        toast.error("A senha não atende aos requisitos de segurança.");
        setSenha("");
        setConfirmSenha("");
        senhaRef.current?.focus();
        return;
      } else {
        if (codigoUsuario === "novo") {
          if (dadosFilial.length === 0 && master === 0) {
            toast.error(
              "Para cadastrar o usuário. Você deve informar pelo menos 1 Filial."
            );
            return;
          } else if (dadosFilial.length > 0 && master === 0) {
            gravarDados(
              nome,
              login,
              email,
              senha,
              telefone,
              avatar,
              ativo ? 1 : 0,
              perfil,
              isVinculo !== "" ? isVinculo : empresaVinculada,
              dadosFilial
            );
            toast.info("Inserindo novo registro...");
          } else if (master === 1) {
            gravarDados(
              nome,
              login,
              email,
              senha,
              telefone,
              avatar,
              ativo ? 1 : 0,
              perfil,
              empresaVinculada,
              dadosFilial
            );
            toast.info("Inserindo novo registro...");
          }
        } else {
          atualizarDados(
            codigoUsuario,
            nome,
            email,
            senha,
            telefone,
            avatar,
            ativo ? 1 : 0,
            perfil,
            empresaVinculada
          );
          toast.info("Atualizando dados do registro...");
        }
      }
    }
  };

  //#endregion

  //#region Dados para vincular a filial do usuario
  const colunasFilial: ITabela[] = [
    { titulo: "", acesso: "avatar" },
    { titulo: "Código", acesso: "id_filial" },
    { titulo: "Filial", acesso: "fantasia" },
    { titulo: "Bairro", acesso: "bairro" },
    { titulo: "UF", acesso: "uf" },
    { titulo: "Responsável", acesso: "contato" },
  ];

  const [dadosFilial, setDadosFilial] = useState<any[]>([]);
  const selecionarFilial = (dados: any) => {
    if (acao === "Novo") {
      const filialSelecionada = dadosFilial.some(
        (item: any) => item.id_filial === dados.id_filial
      );

      if (!filialSelecionada) {
        setDadosFilial([...dadosFilial, dados]);
        setAbrirModalListarFilial(false);
      } else {
        toast.error("Clínica já vinculada ao usuário.");
      }
    } else if (acao === "Editar") {
      const filialSelecionada = dadosFilial.some(
        (item: any) => item.id_filial === dados.id_filial
      );

      if (!filialSelecionada) {
        const dadosfilial = {
          id_usuario: idusuario,
          id_filial: dados.id_filial,
        };

        //  ApiPost("/usuario/gravar", dados, token)
        ApiPost("/usuario/gravar/filial", dadosfilial, token)
          .then((retorno) => {
            toast.success(retorno.message);
            carregarDados(idusuario);
            setAbrirModalListarFilial(false);
          })
          .catch((erro) => {
            toast.error(
              `Erro ao incluir registro. Motivo: ${erro.response.data.message}`
            );
          });
      } else {
        toast.error("Filial já vinculada ao usuário.");
      }
    }
  };
  //#region LISTAR FILIAL PARA VINCULAR AO USUÁRIO
  const colunasListarFilial: ITabela[] = [
    { titulo: "", acesso: "avatar" },
    { titulo: "Código", acesso: "id_filial" },
    { titulo: "Filial", acesso: "fantasia" },
    { titulo: "Bairro", acesso: "bairro" },
    { titulo: "UF", acesso: "uf" },
    { titulo: "Responsável", acesso: "contato" },
  ];
  const [dadosListarFilial, setDadosListarFilial] = useState([]);

  const listarFilial = async (empresavinculada: string) => {
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/filial/listar/vinculada/:vinculo/:idUsuarioLogado/:idUsuarioListar",
      parametros: {
        vinculo: empresavinculada,
        idUsuarioLogado: idUsuario,
        idUsuarioListar: codigoUsuario
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setAbrirModalListarFilial(true);
        setDadosListarFilial(retorno);
      })
      .catch((erro) => {
        toast.error(`Erro ao carregar registro. Motivo: ${erro.message}`);
      });
  };

  //#endregion
  const [abrirModalListarFilial, setAbrirModalListarFilial] =
    useState<boolean>(false);
  const informarfilial = () => {
    if (
      (empresaVinculada === "" || empresaVinculada === null) &&
      isVinculo === ""
    ) {
      toast.error("Para Informar as Clínicas vincule o usuário a uma Empresa.");
    } else {
      listarFilial(empresaVinculada || isVinculo);
    }
  };
  const fecharModalFilial = () => setAbrirModalListarFilial(false);

  //#endregion

  //#region Listar Empresa Contratante
  const [empresa, setEmpresa] = useState<any[]>([]);
  const listarEmpresa = async () => {
    CarregarDadosEmpresa(url_CarregarDados_Empresa)
      .then((retorno) => {
        setEmpresa(retorno);
      })
      .catch((erro) => {
        const msg = erro.response?.data.message;
        toast.error(`Erro ao listar dados empresa. Motivo: ${msg}`);
      });
  };
  useEffect(() => {
    listarEmpresa();
  }, []);

  //#endregion

  const handlePerfilChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = e.target.value as string;

    // Encontrar o item de perfil correspondente aos dadosperfil
    const selectedPerfil = dadosperfil.find(
      (item) => item.id_perfil === selectedValue
    );

    if (selectedPerfil) {
      setPerfil(selectedPerfil.id_perfil);
      setMaster(selectedPerfil.master);
    } else {
      setPerfil("");
      setMaster(0);
    }
  };

  //#region Excluir Filial do Usuário
  const excluirFilial = async (dados: any) => {
    if (acao === "Visualizar") return;
    else
      await showConfirmationDialog(
        "Desvincular Filial",
        `Tem certeza que deseja Desvincular a Filial \n ${dados.fantasia}?`,
        "question"
      )
        .then((result) => {
          if (result.confirmed) {
            if (acao === "Novo") {
              const dadosFiltrados = dadosFilial.filter(
                (item: any) => item.id_filial !== dados.id_filial
              );
              setDadosFilial(dadosFiltrados);
            } else if (acao === "Editar") {
              const headers = {
                Authorization: token,
              };
              const dadosfilial = {
                url: `/usuario/excluirfilial/?id_usuario=${idusuario}&id_filial=${dados.id_filial}`,
                parametros: {},
                headers,
              };
              ApiDelete(dadosfilial)
                .then((retorno) => {
                  toast.success(retorno.message);
                  carregarDados(idusuario);
                })
                .catch((erro) => {
                  toast.error(
                    `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
                  );
                });
            }
          }
        })
        .catch((erro) => {
          toast.error(
            `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
          );
        });
  };
  //#endregion

  //#region LISTAR CLIENTE PARA VINCULAR AO USUARIO
  const colunasListarClientes: ITabela[] = [

    { titulo: "Id", acesso: "id_cliente" },
    { titulo: "CNPJ", acesso: "cnpj" },
    { titulo: "Cliente", acesso: "fantasia" }
  ]

  const [dadosListarClientes, setDadosListarClientes] = useState([]);

  function listarClientes(codigoUsuario: string) {
    const id_filial = getSessionData("DadosIdfilial")

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/clientes/usuario/${codigoUsuario}/${id_filial}`, {
      headers: { Authorization: token }
    }).then(function (resposta) {
      setDadosListarClientes(resposta.data.data)
    }).catch(function (erro) {
      const status = erro.response.status
      if (status == 403 || status == 401) {
        toast.error("Acesso negado, faça seu login novamente")
      }
    })
  }

  useEffect(function () {
    listarClientes(idusuario || '')
  }, [])
  //#endregion

  //#region Dados para vincular o cliente do agente
  const colunas: ITabela[] = [
    { titulo: "Código", acesso: "id_cliente" },
    { titulo: "Cliente", acesso: "fantasia" },
    { titulo: "CNPJ", acesso: "cnpj" },
    { titulo: "Responsável", acesso: "contato" },
    { titulo: "Contato", acesso: "telefone" },
    { titulo: "E-mail", acesso: "email" },
    { titulo: "Agente", acesso: "isAgente"},
  ];

  const [dadosClientes, setDadosClientes] = useState<any[]>([]);

  const selecionarCliente = (dados: any) => {
    const id_filial = getSessionData("DadosIdfilial")

    if (codigoUsuario === "Novo" || codigoUsuario === 'novo') {
      const clienteSelecionado = dadosClientes.some(
        (item: any) => item.id_cliente === dados.id_cliente
      );

      if (!clienteSelecionado) {
        setDadosClientes([...dadosClientes, dados]);
        setAbrirModalListarClientes(false);
      } else {
        toast.error("Cliente já vinculado ao agente.");
      }
    } else {
      const clienteSelecionado = dadosClientes.some(
        (item: any) => item.id_cliente === dados.id_cliente
      );

      if (!clienteSelecionado) {
        const dadosClientes = {
          id_usuario: codigoUsuario,
          id_cliente: dados.id_cliente,
          id_filial: id_filial,
          is_agente: isAgente
        };

        ApiPost("/gravar/usuario/cliente", dadosClientes, token)
          .then((retorno) => {
            toast.success(retorno.message);
            carregarDados(codigoUsuario);
            listarClientes(codigoUsuario)
            setAbrirModalListarClientes(false);
            setVisualizarSelecionarCliente(false)
            setIsAgente(false)
          })
          .catch((erro) => {
            toast.error(
              `Erro ao incluir registro. Motivo: ${erro.response.data.message}`
            );
          });
      } else {
        toast.error("Cliente já vinculado ao agente.");
      }
    }
  };
  //#endregion

  //#region Excluir Cliente do Agente
  const excluirCliente = async (dados: any) => {
    const id_filial = getSessionData("DadosIdfilial")

    await showConfirmationDialog(
      "Desvincular Cliente",
      `Tem certeza que deseja desvincular o Cliente?`,
      "question"
    )
      .then((result) => {
        if (result.confirmed) {
          if (acao === "Novo") {
            const dadosFiltrados = dadosClientes.filter(
              (item: any) => item.id_cliente !== dados.id_cliente
            );
            setDadosClientes(dadosFiltrados);
          } else if (acao === "Editar" || "Visualizar") {
            const headers = {
              Authorization: token,
            };
            const dadosClientes = {
              url: `/remover/usuario/cliente/${idusuario}/${dados.id_cliente}/${id_filial}`,
              parametros: {},
              headers,
            };
            ApiDelete(dadosClientes)
              .then((retorno) => {
                toast.success(retorno.message);
                carregarDados(idusuario);
                listarClientes(codigoUsuario)
              })
              .catch((erro) => {
                toast.error(
                  `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
                );
              });
          }
        }
      })
      .catch((erro) => {
        toast.error(
          `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
        );
      });
  };
  //#endregion

  return (
    <>
      <Card>
        <Form onSubmit={gravar}>
          <Card.Body>
            <Box sx={{ width: "100%" }}>
              <TabContext value={guia}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={capturaGuia}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#000",
                        color: "#000",
                      },
                    }}
                  >
                    <Tab label="Dados Básicos" value="1" />
                    <Tab label="Filial" value="2" />
                    <Tab label="Clientes Vinculados" value="3" hidden={mostraClienteVinculados} />
                  </TabList>
                </Box>

                <TabPanel value="1">
                  <Row>
                    <Col sm={7}>
                      <Row>
                        <Col sm={4}>
                          <InputSemBorda
                            name="codigo"
                            label="Código Usuário"
                            type="text"
                            placeholder="Código Usuário"
                            value={codigoUsuario}
                            onChange={() => { }}
                            classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                            readonly
                          />
                        </Col>
                        <Col sm={6}>
                          <InputSemBorda
                            name="nome"
                            label="Nome Usuário"
                            type="text"
                            placeholder="Nome"
                            value={nome || ""}
                            onChange={setNome}
                            classNameInputsSemBorda="form-control bordasInferiorInput text-capitalize text-black"
                            required={true}
                            readonly={acao === "Visualizar" ? true : false}
                          />
                        </Col>
                        <Col sm={2}>
                          <br />
                          <label htmlFor="" className="form-label me-2">
                            Ativo
                          </label>
                          <Switch
                            checked={ativo}
                            onChange={() => setAtivo(!ativo)}
                            disabled={acao === "Visualizar" ? true : false}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={5}>
                          <InputSemBorda
                            name="login"
                            label="Login"
                            type="text"
                            placeholder="Login"
                            value={login || ""}
                            onChange={setLogin}
                            classNameInputsSemBorda="form-control bordasInferiorInput text-lower text-black"
                            readonly={
                              acao === "Editar" || acao === "Visualizar"
                                ? true
                                : false
                            }
                            required={true}
                          />
                        </Col>
                        <Col sm={7}>
                          <InputSemBorda
                            name="email"
                            label="E-mail"
                            type="email"
                            placeholder="E-mail"
                            value={email || ""}
                            onChange={setEmail}
                            classNameInputsSemBorda="form-control bordasInferiorInput text-lower text-black"
                            readonly={acao === "Visualizar" ? true : false}
                            required={true}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <InputSemBorda
                            name="senha"
                            label="Senha"
                            type="password"
                            placeholder="Senha"
                            value={senha || ""}
                            onChange={setSenha}
                            classNameInputsSemBorda="form-control bordasInferiorInput text-capitalize text-black"
                            readonly={
                              acao === "Visualizar" || acao === "Editar"
                                ? true
                                : false
                            }
                            useRef={senhaRef}
                          />
                          {acao === "Novo" && (
                            <ValidaComplexidadeSenha senha={senha} />
                          )}
                        </Col>
                        <Col sm={6}>
                          <InputSemBorda
                            name="confirmsenha"
                            label="Confirmar Senha"
                            type="password"
                            placeholder="Confirmar Senha"
                            value={confirmSenha || ""}
                            onChange={setConfirmSenha}
                            classNameInputsSemBorda="form-control bordasInferiorInput text-capitalize text-black"
                            readonly={
                              acao === "Visualizar" || acao === "Editar"
                                ? true
                                : false
                            }
                            required={true}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <InputSemBorda
                            name="fone"
                            label="Telefone"
                            type="tel"
                            placeholder="Telefone"
                            value={telefone || ""}
                            onChange={setTelefone}
                            classNameInputsSemBorda="form-control bordasInferiorInput text-capitalize text-black"
                            readonly={acao === "Visualizar" ? true : false}
                          />
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            variant="standard"
                            sx={{ m: 1.5, width: "100%" }}
                          >
                            <InputLabel id="demo-simple-select-standard-label">
                              Perfil
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={perfil}
                              onChange={handlePerfilChange as any}
                              label="Perfil"
                              required
                              disabled={acao === "Visualizar" ? true : false}
                            >
                              <MenuItem value="">
                                <em>Selecione...</em>
                              </MenuItem>

                              {dadosperfil.map(function (item: any) {

                                const NivelPerfilUser = getSessionData("NivelPerfil") || ""

                                if (item.nivel >= NivelPerfilUser) {
                                  return (
                                    <MenuItem
                                      value={item.id_perfil}
                                      data-master={item.master}
                                    >
                                      {item.nome_perfil}
                                    </MenuItem>
                                  )
                                }

                              })}
                            </Select>
                          </FormControl>
                        </Col>
                      </Row>
                      {isSuper === "1" && (
                        <Row>
                          <Col>
                            <FormControl
                              variant="standard"
                              sx={{ m: 1.5, width: "100%" }}
                              required
                            >
                              <InputLabel>Empresa Vinculada</InputLabel>
                              <Select
                                value={empresaVinculada}
                                onChange={(e: SelectChangeEvent) =>
                                  setEmpresaVinculada(e.target.value)
                                }
                                label="Empresa Vinculada"
                                disabled={acao === "Visualizar" ? true : false}
                              >
                                <MenuItem value="">
                                  <em>Selecione...</em>
                                </MenuItem>
                                {empresa.map((item: any) => (
                                  <MenuItem value={item.id}>
                                    {item.fantasia}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Col>
                        </Row>
                      )}
                    </Col>
                    <Col sm={5}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        {avatar ? (
                          <Avatar
                            src={`data:image/png;base64,${avatar || null}`}
                            alt="Avatar"
                            sx={{ width: 240, height: 240 }}
                          />
                        ) : (
                          <Avatar
                            src={""}
                            alt="Avatar"
                            sx={{ width: 240, height: 240 }}
                          />
                        )}
                        <input
                          type="file"
                          accept="image/*"
                          id="avatar-input"
                          onChange={handleAvatarChange}
                          style={{ display: "none" }}
                          disabled={acao === "Visualizar" ? true : false}
                        />
                        <label htmlFor="avatar-input">
                          <IconButton color="primary" component="span">
                            <PhotoCameraIcon />
                          </IconButton>
                        </label>
                        {avatar && acao !== "Visualizar" && (
                          <Button
                            variant="outlined"
                            onClick={handleRemoveAvatar}
                          >
                            Remover Avatar
                          </Button>
                        )}
                      </Stack>
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel value="2">
                  <ButtonCustom
                    invisivel={false}
                    type="button"
                    className="btn btn-light text-info-emphasis bg-white border-0"
                    descricaoBotao="Incluir"
                    icone={mdiPlus}
                    onclick={informarfilial}
                    disabled={acao === "Visualizar" ? true : false}
                  />
                  <hr />
                  <Row>
                    <Tabela
                      coluna={colunasFilial}
                      dados={dadosFilial}
                      itemsPerPage={10}
                      onVisuClick={() => { }}
                      onEditClick={() => { }}
                      onDeleteClick={(item) => excluirFilial(item)}
                      usaVisu={false}
                      usaEdit={false}
                      id="codigo"
                      permissao_editar={true}
                      permissao_visualizar={false}
                      permissao_excluir={false}
                    />
                  </Row>
                </TabPanel>
                <TabPanel value="3">
                  <ButtonCustom
                    invisivel={false}
                    type="button"
                    className="btn btn-light text-info-emphasis bg-white border-0"
                    descricaoBotao="Incluir"
                    icone={mdiPlus}
                    onclick={abrirModalClientes}
                    disabled={false}
                  />
                  <hr />
                  <Row>
                    <Tabela
                      coluna={colunas}
                      dados={dadosClientes}
                      itemsPerPage={10}
                      onVisuClick={() => { }}
                      onEditClick={() => { }}
                      onDeleteClick={excluirCliente}
                      usaVisu={false}
                      usaEdit={false}
                      id="codigo"
                      permissao_editar={true}
                      permissao_visualizar={false}
                      permissao_excluir={false}
                    />
                  </Row>
                </TabPanel>
              </TabContext>
            </Box>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                invisivel={false}
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  navigate("/administrativo/usuario");
                }}
              />
              <ButtonCustom
                invisivel={false}
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
                disabled={acao === "Visualizar" ? true : false}
              />
            </div>
          </Card.Footer>
        </Form>
      </Card>

      {/* MODAL LISTAR FILIAIS PARA VINCULAR AO USUÁRIO */}
      <Modal
        show={abrirModalListarFilial}
        className="modal-full-width "
        size="xl"
        keyboard={false}
        centered
      >
        <Modal.Header className="bg-white">
          <Modal.Title>Listagem de Filiais</Modal.Title>
        </Modal.Header>
        <Form //onSubmit={handleSubmit}
        >
          <Modal.Body className="bg-white">
            <Row>
              <Tabela
                coluna={colunasListarFilial}
                dados={dadosListarFilial}
                itemsPerPage={10}
                usaVisu={false}
                usaEdit={true}
                usaDelete={false}
                onVisuClick={() => { }}
                onEditClick={selecionarFilial}
                onDeleteClick={() => { }}
                descEditar="Selecionar"
                iconeEditar={mdiCheckAll}
                id="codigo"
                permissao_editar={false}
                permissao_visualizar={false}
                permissao_excluir={false}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer className="bg-white">
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                invisivel={false}
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={fecharModalFilial}
              />
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* MODAL LISTAR CLIENTES PARA VINCULAR AO USUÁRIO */}
      <Modal
        show={abrirModalListarClientes}
        className="modal-full-width "
        size="xl"
        keyboard={false}
        centered
      >
        <Modal.Header className="bg-white">
          <Modal.Title>Listagem de Clientes</Modal.Title>
        </Modal.Header>
        <Form //onSubmit={handleSubmit}
        >
          <Modal.Body className="bg-white">
            <Row>
              <ClickableTable
                coluna={colunasListarClientes}
                data={dadosListarClientes}
                itemsPerPage={10}
                usaAcoes={true}
                usaPerfil={visualizarSelecionarPerfil}
                usaEditar={visualizarSelecionarCliente}
                onRowClick={() => { }}
                acaoPerfil={() => {
                  abrirModalPerfis()
                  fecharModalClientes()
                }}
                acaoEditar={selecionarCliente}
                acaoExcluir={() => { }}
                acaoVisualizar={() => { }}
                iconeEditar="bi bi-check-lg"
                corIconeEditar="black"
                tamanhoIcones="fs-3"
                labelpesquisa="Filtrar por Cliente"
                acessopesquisa="fantasia"
              />
            </Row>
          </Modal.Body>
          <Modal.Footer className="bg-white">
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                invisivel={false}
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={fecharModalClientes}
              />
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* MODAL SELECIONAR UM PERFIL E VINCULAR AO CLIENTE */}
      <Modal
        show={abrirModalSelecionarPerfil}
        className="modal-full-width "
        size="sm"
        keyboard={false}
        centered
      >
        <Modal.Header className="bg-white">
          <Modal.Title>Verfique se o usuário é agente</Modal.Title>
        </Modal.Header>
        <Form //onSubmit={handleSubmit}
        >
          <Modal.Body className="bg-white">
            <div className="row">
              <div className="d-flex justify-content-center mt-2 mb-2">
                <div className="mt-1">
                  <label htmlFor="" className="form-label">
                    É agente?
                  </label>
                </div>
                <Switch
                  checked={isAgente}
                  onChange={() => setIsAgente(!isAgente)}
                  disabled={acao === "Visualizar" ? true : false}
                />
              </div>
              <div className="row">
                <h6>Desligado = Não</h6>
              </div>
              <div className="row">
                <h6>Ligado = Sim</h6>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="bg-white">
            <div className="d-flex my-2 gap-1 justify-content-between w-100">
              <ButtonCustom
                invisivel={false}
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  fecharModalPerfis();
                  abrirModalClientes();
                  setVisualizarSelecionarCliente(false);
                }}
              />
              <ButtonCustom
                invisivel={false}
                type="button"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
                disabled={acao === "Visualizar" ? true : false}
                onclick={() => {
                  if (!perfil) {
                    toast.info('Informe um perfil válido para continuar');
                  } else {
                    fecharModalPerfis();
                    abrirModalClientes();
                    setVisualizarSelecionarCliente(true);
                  }
                }}
              />
            </div>
          </Modal.Footer>
        </Form>
      </Modal >

      <ModalUploadImage
        show={showUpload}
        label="Realizando Upload da Imagem ..."
      />
      <ModalLoading show={loading} label="Carregando dados do usuário ..." />

    </>
  );
};
export default FormUsuario;
