import { Row, Col, Card } from "react-bootstrap";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";

import AddIcon from "@mui/icons-material/Add";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";

import { useEffect, useState } from "react";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";

import { toast } from "react-toastify";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";
import { getSessionData } from "../../../utils/storageUtils";
import ClickableTable from "../../../components/ClickableTable/ClickableTabl";
import axios from "axios";

function Clientes() {
    const token = getSessionData("MultfilialWebToken") || "";
    const isSuper = getSessionData("isPerfilSuper") || "";
    const isVinculo = getSessionData("isPerfilVinculo") || "";//id da empresa
    const id_perfil = getSessionData("id_perfil")
    const id_filial = getSessionData("DadosIdfilial")
    const id_usuario = getSessionData("DadosUsuarioID")
    const navigate = useNavigate();

    interface Action {
        icon: JSX.Element;
        name: string;
    }
    const actions: Action[] = [
        {
            icon: (
                <AddIcon
                    onClick={() => navigate("/cadastro/clientes/clientes/novo")}
                />
            ),
            name: "Incluir",
        },
    ];

    const visualizar = (obj: any) => {
        navigate("/cadastro/clientes/clientes/visualizar/" + obj.id_cliente);
    };

    const editar = async (obj: any) => {
        navigate("/cadastro/clientes/clientes/editar/" + obj.id_cliente);
    };

    function excluirCliente(obj: any) {
        const id_filial = getSessionData("DadosIdfilial")

        showConfirmationDialog('Excluir cliente',
            'Você tem certeza que deseja excluir o cliente selecionado?', "question", 0).then(function (confirmed) {
                if (confirmed.confirmed) {
                    setLoading(true)
                    axios.delete(`${process.env.REACT_APP_API_BASE_URL}/excluir/cliente/${obj.id_cliente}/${id_filial}`, {
                        headers: { Authorization: token } // Adiciona o token de autorização no cabeçalho da requisição
                    }).then(function (resposta) {
                        toast.success(resposta.data.message)
                        carregarClientes()
                        setLoading(false)
                    }).catch(function (erro) {
                        setLoading(false)
                        const status = erro.response.status
                        if (status == 403 || status == 401) {
                            toast.error("Acesso negado, faça seu login novamente")
                        }
                    })
                }
            })
    }

    const colunas: ITabela[] = [

        { titulo: "Código", acesso: "id_cliente" },
        { titulo: "Cliente", acesso: "fantasia" },
        { titulo: "E-mail", acesso: "email" },
        { titulo: "Cidade", acesso: "cidade" },
        { titulo: "Situação", acesso: "status" },
    ]

    //#region REALIZA REQUISIÇÃO PARA LISTAR DADOS DA TABELA
    const [dados, setDados] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
    const buscarDados = async (id_usuario: any) => {
    };

    useEffect(() => {
        buscarDados(id_usuario);
        CarregarPermissões(id_perfil, id_filial)
    }, []);
    //#endregion

    //verifica permissão --- inicio
    const [permissao_visualizar, set_permissao_visualizar] = useState(true)
    const [permissao_incluir, set_permissao_incluir] = useState(true)
    const [permissao_editar, set_permissao_editar] = useState(true)
    const [permissao_excluir, set_permissao_excluir] = useState(true)

    async function CarregarPermissões(id_perfil: any, id_filial: any) {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/perfil/carregar/${id_perfil}/${id_filial}`, {
            headers: {
                Authorization: token,
            }
        }).then(function (resposta) {
            if (resposta.data[0].visualizarCadClientes === 1) {
                set_permissao_visualizar(true)
            }
            if (resposta.data[0].incluirCadClientes === 1) {
                set_permissao_incluir(false)
            }
            if (resposta.data[0].editarCadClientes === 1) {
                set_permissao_editar(true)
            }
            if (resposta.data[0].excluirCadClientes === 1) {
                set_permissao_excluir(true)
            }
            setLoading(false)
        }).catch(function (erro) {
            setLoading(false)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    useEffect(function () {
        buscarDados(id_usuario)
    }, [])

    function carregarClientes() {
        const id_filial = getSessionData("DadosIdfilial")

        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/clientes/${id_filial}`, {
            headers: {
                Authorization: token,
            }
        }).then(function (resposta) {
            setDados(resposta.data.data)
            setLoading(false)
        }).catch(function (erro) {
            setLoading(false)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    useEffect(function () {
        carregarClientes()
    }, [])

    return (
        <>
            <div className="col mt-2">
                <ContainerTitulo titulo="Listagem de Clientes" />

                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <SpeedDial
                                        hidden={permissao_incluir}
                                        ariaLabel="SpeedDial basic example"
                                        direction="left"
                                        sx={{
                                            right: 0,
                                            position: "absolute",
                                        }}
                                        icon={<SpeedDialIcon />}
                                    >
                                        {actions.map((action) => (
                                            <SpeedDialAction
                                                key={action.name}
                                                icon={action.icon}
                                                tooltipTitle={action.name}
                                            />
                                        ))}
                                    </SpeedDial>
                                </Row>
                                <Row>
                                    <ClickableTable
                                        coluna={colunas}
                                        data={dados}
                                        usaRowClick={false}
                                        onRowClick={function () {

                                        }}
                                        itemsPerPage={10}
                                        acaoVisualizar={visualizar}
                                        acaoEditar={editar}
                                        acaoExcluir={excluirCliente}
                                        usaAcoes={true}
                                        usaEditar={permissao_editar}
                                        usaVisualizar={permissao_visualizar}
                                        usaExcluir={permissao_excluir}
                                        labelpesquisa="Procurar por Cliente"
                                        acessopesquisa="fantasia"
                                    />
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
            <ModalLoading show={loading} label="Carregando" />
        </>
    );
}

export default Clientes