import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { io, Socket } from "socket.io-client";
import { getSessionData } from "../../../utils/storageUtils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ModalConfirmacao from "../../../components/ModalConfirmacao/ModalConfirmacao";
import { Mensagem, useConversa } from "../../../context/conversaContext";
import { v4 as uuidv4 } from 'uuid';

interface ChatModalProps {
    onClose: () => void;
    show: boolean;
    idCliente: string;
    idConversaAtendimento?: string;
    tipoUsuario: string;
}

function ChatModal({ onClose, show, idCliente, idConversaAtendimento, tipoUsuario }: ChatModalProps) {

    // const [mensagens, setMensagens] = useState<any[]>([]);
    const [novaMensagem, setNovaMensagem] = useState<string>("")
    const [idConversa, setIdConversa] = useState<string>(''); // Armazenar o ID da conversa
    const [socket, setSocket] = useState<Socket | null>(null);
    const [mostraModalConfirm, setMostraModalConfirm] = useState<boolean>(false)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [conversaEmAndamento, setConversaEmAndamento] = useState<boolean>(false)
    const [disabledMessages, setDisabledMessages] = useState<boolean>(false)

    const { minimizado, setMinimizado, mensagens, setMensagens, setIdDaConversa, adicionarMensagem } = useConversa()

    const token = getSessionData('MultfilialWebToken') || "";
    const idFilial = getSessionData('DadosIdfilial') || ""
    const idUsuario = getSessionData('DadosUsuarioID') || ""
    const nomeUsuario = getSessionData('NomeUsuario') || ""

    const novaMensagemRef = useRef<HTMLInputElement>(null)

    const navigate = useNavigate()

    async function iniciarChatSuporte(idConversa: string) {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/iniciarChat`, {
            idConversa,
            idUsuario,
            idFilial,
            idCliente
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success("Chat iniciado, aguarde um agente conectar a conversa")
        }).catch(function (erro) {
            const status = erro.response.status;
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente");
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
                setMensagens([])
                setIdDaConversa(null)
                setShowModal(false)
                setMostraModalConfirm(false)
                setConversaEmAndamento(false)
            }
        })
    }

    async function encerrarChatSuporte() {
        await axios.put(`${process.env.REACT_APP_API_BASE_URL}/encerrarChat`, {
            idConversa,
            idFilial
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
        }).catch(function (erro) {
            const status = erro.response.status;
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente");
                navigate("/login")
            } else if (status !== 409) {
                toast.error(erro.response.data.message)
            }
        })
    }

    function inicializarSocket(idConversa?: string) {
        const newSocket = io("http://localhost:8080"); // Conecta ao servidor
        setSocket(newSocket);

        // Listener para mensagens do suporte
        const handleMensagem = (data: any) => {
            // setMensagens((prevMensagens) => [...prevMensagens, data]);
            adicionarMensagem(data)
        };

        if (tipoUsuario === 'Suporte') {

            // Caso exista um ID de conversa, entra na conversa
            newSocket.emit("entrarChat", idConversa);
            // console.log(`Conectado ao chat existente com ID: ${idConversa}`);
            newSocket.on("mensagemCliente", handleMensagem);

        } else if (!idConversa) {

            newSocket.emit("iniciarConversa");
            newSocket.on("mensagemSuporte", handleMensagem);

            // Ouvir o evento de resposta com o ID da nova conversa
            newSocket.on("conversaIniciada", (id: string) => {
                setIdConversa(id);
                setIdDaConversa(id)

                iniciarChatSuporte(id);
            });
        }
        setConversaEmAndamento(true)

        //Listener para quando suporte entrar na sala
        newSocket.on("suporteEntrou", () => {
            setDisabledMessages(false)
            if (tipoUsuario === 'Cliente') {
                toast.info("Agente conectado a conversa")
            }
            novaMensagemRef.current?.focus()
        })

        // Listener para erros de conversa
        newSocket.on("erroConversa", (data: any) => {
            toast.error(data.mensagem);
            setMensagens([])
        });

        // Listener para encerramento do chat
        newSocket.on("chatEncerrado", (data: any) => {
            if (tipoUsuario !== data.tipoUsuarioComando) {
                toast.info(data.mensagem);
                newSocket.disconnect();
                setSocket(null);
                setShowModal(false)
                setMinimizado(false)
            }
            setMensagens([])
            setIdDaConversa(null)
            setShowModal(false)
            handleClose();
        });

        // Cleanup quando o modal fechar
        return () => {
            // Remove os listeners com base no tipo de usuário
            if (tipoUsuario === "Cliente") {
                newSocket.off("mensagemSuporte");
            } else {
                newSocket.off("mensagemCliente");
            }
            newSocket.off("erroConversa");
        };
    }

    function enviarMensagem() {
        if (novaMensagem.trim() !== "" && socket) {
            // Enviar a mensagem para o servidor
            socket.emit(
                `${tipoUsuario === 'Cliente' ? 'mensagemCliente' : 'mensagemSuporte'}`,
                idConversa,
                nomeUsuario,
                novaMensagem
            );

            const mensagemObj: Mensagem = {
                id: "eu",
                nome: nomeUsuario,
                mensagem: novaMensagem,
                tipoUsuario: tipoUsuario,
            };

            adicionarMensagem(mensagemObj)
            setNovaMensagem("")

            // Adicionar a mensagem ao estado local (mensagens do cliente)
            // setMensagens((prevMensagens) => [
            //     ...prevMensagens,
            //     { id: "eu", nome: nomeUsuario, mensagem: novaMensagem, tipoUsuario: tipoUsuario } // Inclua o nome do usuário
            // ]);
            // setNovaMensagem(""); // Limpar o campo de texto
        }
    };

    const handleClose = () => {
        console.log(socket)
        if (socket) {
            console.log("entrou aqui")
            socket.emit("encerrarChat", idConversa, tipoUsuario);
            socket.disconnect(); // Desconecta ao fechar
            setSocket(null);

            console.log(tipoUsuario)
            // Remove os listeners com base no tipo de usuário
            if (tipoUsuario === "Cliente") {
                socket.off("mensagemSuporte");
            } else {
                socket.off("mensagemCliente");
            }

            setShowModal(false)
            setMinimizado(false)
            setMostraModalConfirm(false)
            setConversaEmAndamento(false)
            setMensagens([])
            setIdDaConversa(null)
            encerrarChatSuporte()
        }
        onClose();
    };

    useEffect(() => {
        setShowModal(show)
    }, [show])

    return (
        <>
            {!minimizado && (
                <Modal
                    show={showModal}
                    // onHide={() => {
                    //     setShowModal(false)
                    //     setMostraModalConfirm(true)
                    // }}
                    onShow={() => {
                        if (tipoUsuario === 'Cliente' && !idConversaAtendimento) {
                            setDisabledMessages(true)
                        } else {
                            setDisabledMessages(false)
                        }

                        setIdConversa(idConversaAtendimento || '')

                        if (!conversaEmAndamento) {
                            if (idConversaAtendimento) {
                                inicializarSocket(idConversaAtendimento)
                            } else if (!conversaEmAndamento) {
                                inicializarSocket()
                            }
                        }
                    }}
                    centered
                >
                    <Modal.Header className="bg-white">
                        <Modal.Title>Chat com Suporte</Modal.Title>
                        <button
                            className="btn btn-link text-secondary ms-auto"
                            style={{ fontSize: "1.2rem" }}
                            onClick={() => setMinimizado(true)}
                        >
                            <i className="bi bi-dash"></i>
                        </button>
                        <button
                            className="btn btn-link text-secondary"
                            style={{ fontSize: "1.5rem" }}
                            onClick={() => {
                                setMostraModalConfirm(true)
                            }}
                        >
                            <i className="bi bi-x"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="bg-white">
                        <div style={{ height: "300px", overflowY: "auto" }}>
                            {mensagens.map((msg, index) => (
                                <div
                                    key={index}
                                    className={`mb-2 ${msg.id === "eu" ? "text-end" : "text-start"}`}
                                >
                                    <div className="small text-muted">
                                        {msg.id === "eu" ? "Você" : msg.nome || "Usuário"}
                                    </div>

                                    <span
                                        className={`badge ${msg.id === "eu" ? "bg-primary" : "bg-secondary"}`}
                                    >
                                        {msg.mensagem}
                                    </span>
                                </div>
                            ))}
                        </div>
                        <Row className="w-100">
                            <Col md={9}>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        disabled={disabledMessages}
                                        value={novaMensagem}
                                        ref={novaMensagemRef}
                                        autoFocus
                                        onChange={(e) => setNovaMensagem(e.target.value)}
                                        onKeyDown={(e) => e.key === "Enter" && enviarMensagem()}
                                        placeholder="Digite sua mensagem..."
                                    />
                                </div>
                            </Col>
                            <Col md={3}>
                                <button
                                    className="btn btn-primary w-100"
                                    disabled={disabledMessages}
                                    onClick={enviarMensagem}
                                >
                                    Enviar
                                </button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            )}

            <ModalConfirmacao
                mostrar={mostraModalConfirm}
                msgAcao1="Cancelar"
                msgAcao2="Confirmar"
                msgPrincipal="Deseja encerrar o chat com suporte?"
                Acao1={() => {
                    setMostraModalConfirm(false)
                    setShowModal(true)
                }}
                Acao2={handleClose}
                backgroundColor="bg-dark"
            />
        </>
    )
}

export default ChatModal