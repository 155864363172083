import { useNavigate } from "react-router-dom";
import ModalLoading from "../../../../../components/Formularios/Modal/ModalLoading";
import { useState, useEffect } from "react";
import ApiGet from "../../../../../api/endPoints/useGet";
import { toast } from "react-toastify";
import { Card, Col, Form, Row } from "react-bootstrap";
import InputSemBorda from "../../../../../components/Formularios/Inputs/InputsSemBorda";
import SelectInput from "../../../../../shared/SelectInputs";
import { Tab, Box, Switch } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button } from "../../../../../components/Formularios/Buttons/Button";
import { mdiCheckCircle, mdiKeyboardReturn } from "@mdi/js";
import ApiPost from "../../../../../api/endPoints/usePost";
import ApiPut from "../../../../../api/endPoints/usePut";
import { getSessionData } from "../../../../../utils/storageUtils";

interface FormPerfilProps {
  idperfil?: string;
  acao?: string;
}

const FormPerfil = ({ idperfil, acao }: FormPerfilProps) => {
  const token = getSessionData("MultfilialWebToken") || "";
  const isSuper = getSessionData("isPerfilSuper") || "";
  const id_filial = getSessionData("DadosIdfilial")
  const navigate = useNavigate();

  //#region Controla as guias
  const [guia, setGuia] = useState("1");

  const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
    setGuia(novaGuia);
  };
  //#endregion

  const [id_perfil, setIdPerfil] = useState<string>("");
  const [nome_perfil, setNomePerfil] = useState<string>("");
  const [ativo, setAtivo] = useState<boolean>(true);
  const [master, setMaster] = useState<boolean>(false);
  const [NivelPerfil, setNivelPerfil] = useState<any>("")

  //#region REALIZA REQUISIÇÃO PARA EXIBIR OS DADOS DO USUÁRIO
  const [loading, setLoading] = useState<boolean>(false);
  const carregarDados = async (idperfil: any, id_filial: any) => {
    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/perfil/carregar/:idperfil/:idfilial",
      parametros: {
        idperfil: idperfil,
        idfilial: id_filial
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((retorno) => {

        setIdPerfil(retorno[0].id_perfil[0])
        setNomePerfil(retorno[0].nome_perfil)
        setNivelPerfil(retorno[0].nivel)

        if (retorno[0].situacao === 1) {
          setAtivo(true);
        } else {
          setAtivo(false);
        }
        if (retorno[0].master === 1) {
          setMaster(true);
          setAllPermissaoPerfilMaster(!master)
        } else {
          setMaster(false);
        }

        if (retorno[0].editar_codsistema === 1) {
          setEditarCodSistema(true)
        }
        else {
          setEditarCodSistema(false)
        }

        const setFunctions: { [key: string]: (value: boolean) => void } = {
          visualizar_empresa_holding: setVisualizarEmpresaHolding,
          incluir_empresa_holding: setIncluirEmpresaHolding,
          editar_empresa_holding: setEditarEmpresaHolding,
          excluir_empresa_holding: setExcluirEmpresaHolding,
          visualizar_perfil_permissao: setVisualizarPerfilPermissao,
          incluir_perfil_permissao: setIncluirPerfilPermissao,
          editar_perfil_permissao: setEditarPerfilPermissao,
          excluir_perfil_permissao: setExcluirPerfilPermissao,
          visualizar_usuario: setVisualizarUsuario,
          incluir_usuario: setIncluirUsuario,
          editar_usuario: setEditarUsuario,
          excluir_usuario: setExcluirUsuario,
          visualizar_codsistema: setVisualizarCodSistema,
          incluir_codsistema: setIncluirCodSistema,
          editar_codsistema: setEditarCodSistema,
          excluir_codsistema: setExcluirCodSistema,
          visualizarfilial: setVisualizarFilial,
          incluirfilial: setIncluirFilial,
          editarfilial: setEditarFilial,
          excluirfilial: setExcluirFilial,
          visualizarBot: setVisualizarBot,
          incluirBot: setIncluirBot,
          EditarBot: setEditarBot,
          ExcluirBot: setExcluirBot,
          incluirTicket: setIncluirTicket,
          editarTicket: setEditarTicket,
          visualizarTicket: setVisualizarTicket,
          editarControleTicket: setEditarControleTicket,
          visualizarControleTicket: setVisualizarControleTicket,
          visualizarFormularios: setVisualizarFormularios,
          incluirFormularios: setIncluirFormularios,
          editarFormularios: setEditarFormularios,
          excluirFormularios: setExcluirFormularios,
          visualizarCadClientes: setVisualizarCadClientes,
          incluirCadClientes: setIncluirCadClientes,
          editarCadClientes: setEditarCadClientes,
          excluirCadClientes: setExcluirCadClientes,
          visualizarConfiguracaoAgentes: setVisualizarConfiguracaoAgentes,
          incluirConfiguracaoAgentes: setIncluirConfiguracaoAgentes,
          editarConfiguracaoAgentes: setEditarConfiguracaoAgentes,
          excluirConfiguracaoAgentes: setExcluirConfiguracaoAgentes,
          visualizarGestaoAtendimentos: setVisualizarGestaoAtendimentos,
          visualizarRelatorioSatisfacao: setVisualizarRelatorioSatisfacao,
          visualizarMetas: setVisualizarMetas,
          incluirMetas: setIncluirMetas,
          editarMetas: setEditarMetas,
          excluirMetas: setExcluirMetas
        };

        // Iterar sobre as propriedades e definir os estados com base nos valores de retorno[0] -
        //OBS: obg gpt
        for (const key in retorno[0]) {
          if (key in setFunctions) {
            setFunctions[key](retorno[0][key] === 1);
          }
        }

        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        toast.error(`Erro ao carregar dados. Motivo: ${erro.message}`);
      });
  };
  useEffect(() => {
    if (idperfil !== "novo") {
      carregarDados(idperfil, id_filial);
    }

  }, [idperfil]);
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA ATUALIZAR OS DADOS DO USUÁRIO
  const atualizarDados = async (
    id_perfil: string,
    nome_perfil: string,
    situacao: number,

    visualizarEmpresaHolding: number,
    incluirEmpresaHolding: number,
    EditarEmpresaHolding: number,
    ExcluirEmpresaHolding: number,

    visualizarPerfilPermissao: number,
    incluirPerfilPermissao: number,
    EditarPerfilPermissao: number,
    ExcluirPerfilPermissao: number,


    visualizarUsuario: number,
    incluirUsuario: number,
    EditarUsuario: number,
    ExcluirUsuario: number,

    visualizarCodSistema: number,
    incluirCodSistema: number,
    EditarCodSistema: number,
    ExcluirCodSistema: number,

    visualizarFilial: number,
    incluirFilial: number,
    EditarFilial: number,
    ExcluirFilial: number,

    visualizarBot: number,
    incluirBot: number,
    EditarBot: number,
    ExcluirBot: number,

    NivelPerfil: number,

    incluirTicket: number,
    editarTicket: number,
    visualizarTicket: number,

    editarControleTicket: number,
    visualizarControleTicket: number,

    visualizarFormularios: number,
    incluirFormularios: number,
    editarFormularios: number,
    excluirFormularios: number,

    visualizarCadClientes: number,
    incluirCadClientes: number,
    editarCadClientes: number,
    excluirCadClientes: number,

    visualizarConfiguracaoAgentes: number,
    incluirConfiguracaoAgentes: number,
    editarConfiguracaoAgentes: number,
    excluirConfiguracaoAgentes: number,

    visualizarGestaoAtendimentos: number,

    visualizarRelatorios: number,
    visualizarRelatorioSatisfacao: number,

    visualizarMetas: number,
    incluirMetas: number,
    editarMetas: number,
    excluirMetas: number

  ) => {
    const dados = {
      nome_perfil,
      situacao,
      //dados para permissao 
      visualizarEmpresaHolding,
      incluirEmpresaHolding,
      EditarEmpresaHolding,
      ExcluirEmpresaHolding,

      visualizarPerfilPermissao,
      incluirPerfilPermissao,
      EditarPerfilPermissao,
      ExcluirPerfilPermissao,


      visualizarUsuario,
      incluirUsuario,
      EditarUsuario,
      ExcluirUsuario,
      AcessoTotalUsuario,

      visualizarCodSistema,
      incluirCodSistema,
      EditarCodSistema,
      ExcluirCodSistema,

      visualizarFilial,
      incluirFilial,
      EditarFilial,
      ExcluirFilial,

      visualizarBot,
      incluirBot,
      EditarBot,
      ExcluirBot,

      NivelPerfil,

      incluirTicket,
      editarTicket,
      visualizarTicket,

      editarControleTicket,
      visualizarControleTicket,

      visualizarFormularios,
      incluirFormularios,
      editarFormularios,
      excluirFormularios,

      visualizarCadClientes,
      incluirCadClientes,
      editarCadClientes,
      excluirCadClientes,

      visualizarConfiguracaoAgentes,
      incluirConfiguracaoAgentes,
      editarConfiguracaoAgentes,
      excluirConfiguracaoAgentes,

      visualizarGestaoAtendimentos,

      visualizarRelatorios,
      visualizarRelatorioSatisfacao,

      visualizarMetas,
      incluirMetas,
      editarMetas,
      excluirMetas
    };

    ApiPut(`/perfil/atualizar/${id_perfil}/${id_filial}`, dados, token)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        toast.error(
          `Erro ao atualizar dados. Motivo: ${erro.response.data.message}`
        );
      });
  };
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS DO USUÁRIO
  const gravarDados = (
    nome_perfil: string,
    situacao: number,
    master: number,

    visualizarEmpresaHolding: number,
    incluirEmpresaHolding: number,
    EditarEmpresaHolding: number,
    ExcluirEmpresaHolding: number,

    visualizarPerfilPermissao: number,
    incluirPerfilPermissao: number,
    EditarPerfilPermissao: number,
    ExcluirPerfilPermissao: number,


    visualizarUsuario: number,
    incluirUsuario: number,
    EditarUsuario: number,
    ExcluirUsuario: number,

    visualizarCodSistema: number,
    incluirCodSistema: number,
    EditarCodSistema: number,
    ExcluirCodSistema: number,

    visualizarFilial: number,
    incluirFilial: number,
    EditarFilial: number,
    ExcluirFilial: number,

    visualizarBot: number,
    incluirBot: number,
    EditarBot: number,
    ExcluirBot: number,

    NivelPerfil: number,

    incluirTicket: number,
    editarTicket: number,
    visualizarTicket: number,

    editarControleTicket: number,
    visualizarControleTicket: number,

    visualizarFormularios: number,
    incluirFormularios: number,
    editarFormularios: number,
    excluirFormularios: number,

    visualizarCadClientes: number,
    incluirCadClientes: number,
    editarCadClientes: number,
    excluirCadClientes: number,

    visualizarConfiguracaoAgentes: number,
    incluirConfiguracaoAgentes: number,
    editarConfiguracaoAgentes: number,
    excluirConfiguracaoAgentes: number,

    visualizarGestaoAtendimentos: number,

    visualizarRelatorios: number,
    visualizarRelatorioSatisfacao: number,

    visualizarMetas: number,
    incluirMetas: number,
    editarMetas: number,
    excluirMetas: number

  ) => {
    const dados = {
      nome_perfil,
      situacao,
      master,
      id_filial,

      //dados para permissao 
      visualizarEmpresaHolding,
      incluirEmpresaHolding,
      EditarEmpresaHolding,
      ExcluirEmpresaHolding,

      visualizarPerfilPermissao,
      incluirPerfilPermissao,
      EditarPerfilPermissao,
      ExcluirPerfilPermissao,


      visualizarUsuario,
      incluirUsuario,
      EditarUsuario,
      ExcluirUsuario,
      AcessoTotalUsuario,

      visualizarCodSistema,
      incluirCodSistema,
      EditarCodSistema,
      ExcluirCodSistema,

      visualizarFilial,
      incluirFilial,
      EditarFilial,
      ExcluirFilial,

      visualizarBot,
      incluirBot,
      EditarBot,
      ExcluirBot,

      NivelPerfil,

      incluirTicket,
      editarTicket,
      visualizarTicket,

      editarControleTicket,
      visualizarControleTicket,

      visualizarFormularios,
      incluirFormularios,
      editarFormularios,
      excluirFormularios,

      visualizarCadClientes,
      incluirCadClientes,
      editarCadClientes,
      excluirCadClientes,

      visualizarConfiguracaoAgentes,
      incluirConfiguracaoAgentes,
      editarConfiguracaoAgentes,
      excluirConfiguracaoAgentes,

      visualizarGestaoAtendimentos,

      visualizarRelatorios,
      visualizarRelatorioSatisfacao,

      visualizarMetas,
      incluirMetas,
      editarMetas,
      excluirMetas
    };

    ApiPost("/perfil/gravar", dados, token)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`Erro ao gravar dados. Motivo: ${msg}`);
      });
  };
  //#endregion

  const gravar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (idperfil === "novo") {
      gravarDados(nome_perfil, ativo ? 1 : 0, master ? 1 : 0,
        visualizarEmpresaHolding ? 1 : 0,
        incluirEmpresaHolding ? 1 : 0,
        EditarEmpresaHolding ? 1 : 0,
        ExcluirEmpresaHolding ? 1 : 0,
        visualizarPerfilPermissao ? 1 : 0,
        incluirPerfilPermissao ? 1 : 0,
        EditarPerfilPermissao ? 1 : 0,
        ExcluirPerfilPermissao ? 1 : 0,
        visualizarUsuario ? 1 : 0,
        incluirUsuario ? 1 : 0,
        EditarUsuario ? 1 : 0,
        ExcluirUsuario ? 1 : 0,
        visualizarCodSistema ? 1 : 0,
        incluirCodSistema ? 1 : 0,
        EditarCodSistema ? 1 : 0,
        ExcluirCodSistema ? 1 : 0,
        visualizarFilial ? 1 : 0,
        incluirFilial ? 1 : 0,
        EditarFilial ? 1 : 0,
        ExcluirFilial ? 1 : 0,
        visualizarBot ? 1 : 0,
        incluirBot ? 1 : 0,
        EditarBot ? 1 : 0,
        ExcluirBot ? 1 : 0,
        NivelPerfil,
        incluirTicket ? 1 : 0,
        editarTicket ? 1 : 0,
        visualizarTicket ? 1 : 0,
        editarControleTicket ? 1 : 0,
        visualizarControleTicket ? 1 : 0,
        visualizarFormularios ? 1 : 0,
        incluirFormularios ? 1 : 0,
        editarFormularios ? 1 : 0,
        excluirFormularios ? 1 : 0,
        visualizarCadClientes ? 1 : 0,
        incluirCadClientes ? 1 : 0,
        editarCadClientes ? 1 : 0,
        excluirCadClientes ? 1 : 0,
        visualizarConfiguracaoAgentes ? 1 : 0,
        incluirConfiguracaoAgentes ? 1 : 0,
        editarConfiguracaoAgentes ? 1 : 0,
        excluirConfiguracaoAgentes ? 1 : 0,
        visualizarGestaoAtendimentos ? 1 : 0,
        visualizarRelatorios ? 1 : 0,
        visualizarRelatorioSatisfacao ? 1 : 0,
        visualizarMetas ? 1 : 0,
        incluirMetas ? 1 : 0,
        editarMetas ? 1 : 0,
        excluirMetas ? 1 : 0,
      );
      toast.info("Inserindo novo registro...");
    } else {
      atualizarDados(id_perfil, nome_perfil, ativo ? 1 : 0,
        visualizarEmpresaHolding ? 1 : 0,
        incluirEmpresaHolding ? 1 : 0,
        EditarEmpresaHolding ? 1 : 0,
        ExcluirEmpresaHolding ? 1 : 0,
        visualizarPerfilPermissao ? 1 : 0,
        incluirPerfilPermissao ? 1 : 0,
        EditarPerfilPermissao ? 1 : 0,
        ExcluirPerfilPermissao ? 1 : 0,
        visualizarUsuario ? 1 : 0,
        incluirUsuario ? 1 : 0,
        EditarUsuario ? 1 : 0,
        ExcluirUsuario ? 1 : 0,
        visualizarCodSistema ? 1 : 0,
        incluirCodSistema ? 1 : 0,
        EditarCodSistema ? 1 : 0,
        ExcluirCodSistema ? 1 : 0,
        visualizarFilial ? 1 : 0,
        incluirFilial ? 1 : 0,
        EditarFilial ? 1 : 0,
        ExcluirFilial ? 1 : 0,
        visualizarBot ? 1 : 0,
        incluirBot ? 1 : 0,
        EditarBot ? 1 : 0,
        ExcluirBot ? 1 : 0,
        NivelPerfil ? 1 : 0,
        incluirTicket ? 1 : 0,
        editarTicket ? 1 : 0,
        visualizarTicket ? 1 : 0,
        editarControleTicket ? 1 : 0,
        visualizarControleTicket ? 1 : 0,
        visualizarFormularios ? 1 : 0,
        incluirFormularios ? 1 : 0,
        editarFormularios ? 1 : 0,
        excluirFormularios ? 1 : 0,
        visualizarCadClientes ? 1 : 0,
        incluirCadClientes ? 1 : 0,
        editarCadClientes ? 1 : 0,
        excluirCadClientes ? 1 : 0,
        visualizarConfiguracaoAgentes ? 1 : 0,
        incluirConfiguracaoAgentes ? 1 : 0,
        editarConfiguracaoAgentes ? 1 : 0,
        excluirConfiguracaoAgentes ? 1 : 0,
        visualizarGestaoAtendimentos ? 1 : 0,
        visualizarRelatorios ? 1 : 0,
        visualizarRelatorioSatisfacao ? 1 : 0,
        visualizarMetas ? 1 : 0,
        incluirMetas ? 1 : 0,
        editarMetas ? 1 : 0,
        excluirMetas ? 1 : 0,
      );
      toast.info("Atualizando dados do registro...");
    }
  };


  //controle "aba" ADMINISTRATIVO - inicio

  //informações de permissões
  const [visualizarEmpresaHolding, setVisualizarEmpresaHolding] = useState(false)
  const [incluirEmpresaHolding, setIncluirEmpresaHolding] = useState(false)
  const [EditarEmpresaHolding, setEditarEmpresaHolding] = useState(false)
  const [ExcluirEmpresaHolding, setExcluirEmpresaHolding] = useState(false)
  const [AcessoTotalEmpresaHolding, setAcessoTotalEmpresaHolding] = useState(false)

  const [visualizarPerfilPermissao, setVisualizarPerfilPermissao] = useState(false)
  const [incluirPerfilPermissao, setIncluirPerfilPermissao] = useState(false)
  const [EditarPerfilPermissao, setEditarPerfilPermissao] = useState(false)
  const [ExcluirPerfilPermissao, setExcluirPerfilPermissao] = useState(false)
  const [AcessoTotalPerfilPermissao, setAcessoTotalPerfilPermissao] = useState(false)

  const [visualizarUsuario, setVisualizarUsuario] = useState(false)
  const [incluirUsuario, setIncluirUsuario] = useState(false)
  const [EditarUsuario, setEditarUsuario] = useState(false)
  const [ExcluirUsuario, setExcluirUsuario] = useState(false)
  const [AcessoTotalUsuario, setAcessoTotalUsuario] = useState(false)

  const [visualizarCadClientes, setVisualizarCadClientes] = useState(false)
  const [incluirCadClientes, setIncluirCadClientes] = useState(false)
  const [editarCadClientes, setEditarCadClientes] = useState(false)
  const [excluirCadClientes, setExcluirCadClientes] = useState(false)
  const [acessoTotalCadClientes, setAcessoTotalCadClientes] = useState(false)

  const [visualizarCodSistema, setVisualizarCodSistema] = useState(false)
  const [incluirCodSistema, setIncluirCodSistema] = useState(false)
  const [EditarCodSistema, setEditarCodSistema] = useState(false)
  const [ExcluirCodSistema, setExcluirCodSistema] = useState(false)
  const [AcessoTotalCodSistema, setAcessoTotalCodSistema] = useState(false)

  const [visualizarFormularios, setVisualizarFormularios] = useState(false)
  const [incluirFormularios, setIncluirFormularios] = useState(false)
  const [editarFormularios, setEditarFormularios] = useState(false)
  const [excluirFormularios, setExcluirFormularios] = useState(false)
  const [AcessoTotalFormularios, setAcessoTotalFormularios] = useState(false)

  const [visualizarTicket, setVisualizarTicket] = useState(false)
  const [incluirTicket, setIncluirTicket] = useState(false)
  const [editarTicket, setEditarTicket] = useState(false)
  const [AcessoTotalTicket, setAcessoTotalTicket] = useState(false)

  const [visualizarControleTicket, setVisualizarControleTicket] = useState(false)
  const [editarControleTicket, setEditarControleTicket] = useState(false)
  const [AcessoTotalControleTicket, setAcessoTotalControleTicket] = useState(false)

  const [visualizarMetas, setVisualizarMetas] = useState(false)
  const [incluirMetas, setIncluirMetas] = useState(false)
  const [editarMetas, setEditarMetas] = useState(false)
  const [excluirMetas, setExcluirMetas] = useState(false)
  const [AcessoTotalControleMetas, setAcessoTotalControleMetas] = useState(false)

  const [visualizarRelatorios, setVisualizarRelatorios] = useState(false)

  const [visualizarConfiguracaoAgentes, setVisualizarConfiguracaoAgentes] = useState(false)
  const [incluirConfiguracaoAgentes, setIncluirConfiguracaoAgentes] = useState(false)
  const [editarConfiguracaoAgentes, setEditarConfiguracaoAgentes] = useState(false)
  const [excluirConfiguracaoAgentes, setExcluirConfiguracaoAgentes] = useState(false)
  const [acessoTotalConfiguracaoAgentes, setAcessoTotalConfiguracaoAgentes] = useState(false)

  const [visualizarGestaoAtendimentos, setVisualizarGestaoAtendimentos] = useState(false)
  const [visualizarRelatorioSatisfacao, setVisualizarRelatorioSatisfacao] = useState(false)

  const [visualizarChatSuporte, setVisualizarChatSuporte] = useState(false)
  const [enviarChatSuporte, setEnviarChatSuporte] = useState(false)
  const [apagarChatSuporte, setApagarChatSuporte] = useState(false)
  const [AcessoTotalChatSuporte, setAcessoTotalChatSuporte] = useState(false)



  //controle "aba" ADMINISTRATIVO - fim


  //controle "aba" CADASTRO - inicio
  const [visualizarFilial, setVisualizarFilial] = useState(false)
  const [incluirFilial, setIncluirFilial] = useState(false)
  const [EditarFilial, setEditarFilial] = useState(false)
  const [ExcluirFilial, setExcluirFilial] = useState(false)
  const [AcessoTotalFilial, setAcessoTotalFilial] = useState(false)

  const [visualizarBot, setVisualizarBot] = useState(false)
  const [incluirBot, setIncluirBot] = useState(false)
  const [EditarBot, setEditarBot] = useState(false)
  const [ExcluirBot, setExcluirBot] = useState(false)
  const [AcessoTotalBot, setAcessoTotalBot] = useState(false)


  //controle do acesso total perfil MASTER
  function setAllPermissaoPerfilMaster(status_master: any) {
    if (status_master == true) {
      setVisualizarEmpresaHolding(true)
      setIncluirEmpresaHolding(true)
      setEditarEmpresaHolding(true)
      setExcluirEmpresaHolding(true)
      setAcessoTotalEmpresaHolding(true)

      setVisualizarPerfilPermissao(true)
      setIncluirPerfilPermissao(true)
      setEditarPerfilPermissao(true)
      setExcluirPerfilPermissao(true)
      setAcessoTotalPerfilPermissao(true)

      setVisualizarUsuario(true)
      setIncluirUsuario(true)
      setEditarUsuario(true)
      setExcluirUsuario(true)
      setAcessoTotalUsuario(true)

      setVisualizarCodSistema(true)
      setIncluirCodSistema(true)
      setEditarCodSistema(true)
      setExcluirCodSistema(true)
      setAcessoTotalCodSistema(true)

      setVisualizarFormularios(true)
      setIncluirFormularios(true)
      setEditarFormularios(true)
      setExcluirFormularios(true)
      setAcessoTotalFormularios(true)

      setVisualizarFilial(true)
      setIncluirFilial(true)
      setEditarFilial(true)
      setExcluirFilial(true)
      setAcessoTotalFilial(true)

      setVisualizarBot(true)
      setIncluirBot(true)
      setEditarBot(true)
      setExcluirBot(true)
      setAcessoTotalBot(true)

      setVisualizarTicket(true)
      setIncluirTicket(true)
      setEditarTicket(true)
      setAcessoTotalTicket(true)

      setVisualizarControleTicket(true)
      setEditarControleTicket(true)
      setAcessoTotalControleTicket(true)

      setVisualizarFormularios(true)
      setIncluirFormularios(true)
      setEditarFormularios(true)
      setExcluirFormularios(true)
      setAcessoTotalFormularios(true)

      setVisualizarCadClientes(true)
      setIncluirCadClientes(true)
      setEditarCadClientes(true)
      setExcluirCadClientes(true)
      setAcessoTotalCadClientes(true)

      setVisualizarConfiguracaoAgentes(true)
      setIncluirConfiguracaoAgentes(true)
      setEditarConfiguracaoAgentes(true)
      setExcluirConfiguracaoAgentes(true)

      setVisualizarGestaoAtendimentos(true)
      setVisualizarRelatorios(true)

      setVisualizarMetas(true)
      setIncluirMetas(true)
      setEditarMetas(true)
      setExcluirMetas(true)
    }
    else {
      setVisualizarEmpresaHolding(false)
      setIncluirEmpresaHolding(false)
      setEditarEmpresaHolding(false)
      setExcluirEmpresaHolding(false)
      setAcessoTotalEmpresaHolding(false)

      setVisualizarPerfilPermissao(false)
      setIncluirPerfilPermissao(false)
      setEditarPerfilPermissao(false)
      setExcluirPerfilPermissao(false)
      setAcessoTotalPerfilPermissao(false)

      setVisualizarUsuario(false)
      setIncluirUsuario(false)
      setEditarUsuario(false)
      setExcluirUsuario(false)
      setAcessoTotalUsuario(false)

      setVisualizarCodSistema(false)
      setIncluirCodSistema(false)
      setEditarCodSistema(false)
      setExcluirCodSistema(false)
      setAcessoTotalCodSistema(false)

      setVisualizarFormularios(false)
      setIncluirFormularios(false)
      setEditarFormularios(false)
      setExcluirFormularios(false)
      setAcessoTotalFormularios(false)

      setVisualizarBot(false)
      setIncluirBot(false)
      setEditarBot(false)
      setExcluirBot(false)
      setAcessoTotalBot(false)

      setVisualizarTicket(false)
      setIncluirTicket(false)
      setEditarTicket(false)
      setAcessoTotalTicket(false)

      setVisualizarControleTicket(false)
      setEditarControleTicket(false)
      setAcessoTotalControleTicket(false)

      setVisualizarFormularios(false)
      setIncluirFormularios(false)
      setEditarFormularios(false)
      setExcluirFormularios(false)
      setAcessoTotalFormularios(false)

      setVisualizarCadClientes(false)
      setIncluirCadClientes(false)
      setEditarCadClientes(false)
      setExcluirCadClientes(false)
      setAcessoTotalCadClientes(false)

      setVisualizarConfiguracaoAgentes(false)
      setIncluirConfiguracaoAgentes(false)
      setEditarConfiguracaoAgentes(false)
      setExcluirConfiguracaoAgentes(false)

      setVisualizarGestaoAtendimentos(false)
      setVisualizarRelatorios(false)

      setVisualizarMetas(false)
      setIncluirMetas(false)
      setEditarMetas(false)
      setExcluirMetas(false)
    }

  }

  //manipulando o "acesso total" de cada modulo
  function acessoTotalCheked() {
    if (visualizarEmpresaHolding == true && incluirEmpresaHolding == true && EditarEmpresaHolding == true && ExcluirEmpresaHolding == true) {
      setAcessoTotalEmpresaHolding(true)
    }
    if (visualizarPerfilPermissao == true && incluirPerfilPermissao == true && EditarPerfilPermissao == true && ExcluirPerfilPermissao == true) {
      setAcessoTotalPerfilPermissao(true)
    }
    if (visualizarUsuario == true && incluirUsuario == true && EditarUsuario == true && ExcluirUsuario == true) {
      setAcessoTotalUsuario(true)
    }
    if (visualizarCodSistema == true && incluirCodSistema == true && EditarCodSistema == true && ExcluirCodSistema == true) {
      setAcessoTotalCodSistema(true)

    } if (visualizarFormularios == true && incluirFormularios == true && editarFormularios == true && excluirFormularios == true) {
      setAcessoTotalFormularios(true)

    } if (visualizarFilial == true && incluirFilial == true && EditarFilial == true && ExcluirFilial == true) {
      setAcessoTotalFilial(true)
    }
    if (visualizarBot == true && incluirBot == true && EditarBot == true && ExcluirBot == true) {
      setAcessoTotalBot(true)
    }
    if (visualizarTicket == true && incluirTicket == true && editarTicket == true) {
      setAcessoTotalTicket(true)
    }
    if (visualizarControleTicket == true && editarControleTicket == true) {
      setAcessoTotalControleTicket(true)
    }
    if (visualizarFormularios == true && editarFormularios == true && incluirFormularios == true && excluirFormularios == true) {
      setAcessoTotalFormularios(true)
    }
    if (visualizarCadClientes == true && editarCadClientes == true && incluirCadClientes == true && excluirCadClientes == true) {
      setAcessoTotalCadClientes(true)
    }
    if (visualizarConfiguracaoAgentes == true && editarConfiguracaoAgentes == true && incluirConfiguracaoAgentes == true && excluirConfiguracaoAgentes == true) {
      setAcessoTotalConfiguracaoAgentes(true)
    }
    if (visualizarMetas == true && incluirMetas == true && editarMetas == true && excluirMetas == true) {
      setAcessoTotalControleMetas(true)
    }
  }


  return (
    <>
      <Card>
        <Form onSubmit={gravar}>
          <Card.Body>
            <Box sx={{ width: "100%" }}>
              <TabContext value={guia}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={capturaGuia}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#000",
                        color: "#000",
                      },
                    }}
                  >
                    <Tab label="Geral" value="1" />
                    <Tab label="Permissões" value="2" onClick={function () {
                      acessoTotalCheked()
                    }} />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Row>
                    <Col>
                      <h4>Informações sobre campo Nivel Perfil</h4>
                      <tr>0 - Perfil Master</tr>
                      <tr>1 - Adm Filial</tr>
                      <tr>3 - Operacional</tr>
                      <tr>4 - Depositante</tr>
                      <tr><i>*Quanto menor o nivel mais permissões terá.</i></tr>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Cód. Perfil"
                        name="codperfil"
                        type="text"
                        placeholder="Cód. Perfil"
                        readonly
                        value={id_perfil || "novo"}
                        onChange={setIdPerfil}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                      />
                    </Col>
                    <Col sm={2}>
                      <div className="form-floating w-100">

                        <input type="text" list="datalistNivelPerfil"
                          className="form-control bordasInferiorInput text-black text-capitalize"
                          placeholder="Nivel Perfil"
                          value={NivelPerfil}
                          aria-label="Nivel Perfil"
                          onChange={function (e: any) {
                            if (e.target.value / 1 != e.target.value) {
                              toast.error("Valor inválido para o campo Nivel Perfil")
                            } else if (e.target.value > 4) {
                              toast.error("Valor inválido para o campo Nivel Perfil")
                            }
                            else {
                              setNivelPerfil(e.target.value)
                            }
                          }}
                        />
                        <label htmlFor="">Nivel Perfil</label>
                      </div>

                      <datalist id="datalistNivelPerfil">
                        {/* Opções do datalist */}
                        <option value="0" />
                        <option value="1" />
                        <option value="2" />
                        <option value="3" />
                        <option value="4" />
                      </datalist>
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Nome Perfil"
                        name="nomeperfil"
                        type="text"
                        placeholder="Nome Perfil"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={nome_perfil}
                        onChange={setNomePerfil}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                      />
                    </Col>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Ativo
                      </label>
                      <Switch
                        checked={ativo}
                        onChange={() => setAtivo(!ativo)}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Perfil Master
                      </label>
                      <Switch
                        checked={master}
                        onChange={function () {
                          setMaster(!master)
                          setAllPermissaoPerfilMaster(!master)
                        }}
                        disabled={
                          acao === "Visualizar" || isSuper === "0"
                            ? true
                            : false
                        }
                        color="error"
                      />
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel value="2">
                  {/*INICIO - ADMINISTRATIVO */}
                  <div className="accordion" id="acordeao-exemplo">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="titulo-1">
                        <h4

                          className="accordion-button"

                          data-bs-toggle="collapse"

                          data-bs-target="#item-1"

                          aria-expanded="true"
                          aria-controls="item-1"

                        >

                          Administrativo

                        </h4>

                      </h2>
                      <div

                        id="item-1"
                        className="accordion-collapse collapse show"
                        aria-labelledby="titulo-1"
                        data-bs-parent="#acordeao-exemplo"
                      >
                        <div className="accordion-body">

                          <div className="table-responsive">
                            {/*INICIO EMPRESA HOLDING */}
                            <table hidden={true} className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-relatorios">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Visualizar</th>
                                  <th className="text-center">Incluir</th>
                                  <th className="text-center">Editar</th>
                                  <th className="text-center">Excluir</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Empresa (Holding)</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuEmpresaHolding"
                                      checked={visualizarEmpresaHolding}
                                      onChange={function (e: any) {
                                        setVisualizarEmpresaHolding(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirEmpresaHolding"
                                      checked={incluirEmpresaHolding}
                                      onChange={function (e: any) {
                                        setIncluirEmpresaHolding(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarEmpresaHolding"
                                      checked={EditarEmpresaHolding}
                                      onChange={function (e: any) {
                                        setEditarEmpresaHolding(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirEmpresaHolding"
                                      checked={ExcluirEmpresaHolding}
                                      onChange={function (e: any) {
                                        setExcluirEmpresaHolding(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalEmpresaHolding"
                                      checked={AcessoTotalEmpresaHolding}
                                      onChange={function (e: any) {
                                        setAcessoTotalEmpresaHolding(e.target.checked)
                                        setExcluirEmpresaHolding(e.target.checked)
                                        setEditarEmpresaHolding(e.target.checked)
                                        setIncluirEmpresaHolding(e.target.checked)
                                        setVisualizarEmpresaHolding(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {/* FIM EMPRESA HOLDING */}
                            {/*INICIO PERFIL / PERMISSÃO */}
                            <table hidden={true} className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-relatorios">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Visualizar</th>
                                  <th className="text-center">Incluir</th>
                                  <th className="text-center">Editar</th>
                                  <th className="text-center">Excluir</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Perfil / Permissão</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuPerfilPermissao"
                                      checked={visualizarPerfilPermissao}
                                      onChange={function (e: any) {
                                        setVisualizarPerfilPermissao(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirPerfilPermissao"
                                      checked={incluirPerfilPermissao}
                                      onChange={function (e: any) {
                                        setIncluirPerfilPermissao(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarPerfilPermissao"
                                      checked={EditarPerfilPermissao}
                                      onChange={function (e: any) {
                                        setEditarPerfilPermissao(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirPerfilPermissao"
                                      checked={ExcluirPerfilPermissao}
                                      onChange={function (e: any) {
                                        setExcluirPerfilPermissao(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalPerfilPermissao"
                                      checked={AcessoTotalPerfilPermissao}
                                      onChange={function (e: any) {
                                        setAcessoTotalPerfilPermissao(e.target.checked)
                                        setExcluirPerfilPermissao(e.target.checked)
                                        setEditarPerfilPermissao(e.target.checked)
                                        setIncluirPerfilPermissao(e.target.checked)
                                        setVisualizarPerfilPermissao(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {/*FIM PERFIL / PERMISSÃO */}
                            {/*INICIO USUARIO/FORMULARIOS */}
                            <table className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-relatorios">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Visualizar</th>
                                  <th className="text-center">Incluir</th>
                                  <th className="text-center">Editar</th>
                                  <th className="text-center">Excluir</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Usuario</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuUsuario"
                                      checked={visualizarUsuario}
                                      onChange={function (e: any) {
                                        setVisualizarUsuario(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirUsuario"
                                      checked={incluirUsuario}
                                      onChange={function (e: any) {
                                        setIncluirUsuario(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarUsuario"
                                      checked={EditarUsuario}
                                      onChange={function (e: any) {
                                        setEditarUsuario(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirUsuario"
                                      checked={ExcluirUsuario}
                                      onChange={function (e: any) {
                                        setExcluirUsuario(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalUsuario"
                                      checked={AcessoTotalUsuario}
                                      onChange={function (e: any) {
                                        setAcessoTotalUsuario(e.target.checked)
                                        setExcluirUsuario(e.target.checked)
                                        setEditarUsuario(e.target.checked)
                                        setIncluirUsuario(e.target.checked)
                                        setVisualizarUsuario(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Formulários</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuFormularios"
                                      checked={visualizarFormularios}
                                      onChange={function (e: any) {
                                        setVisualizarFormularios(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirFormularios"
                                      checked={incluirFormularios}
                                      onChange={function (e: any) {
                                        setIncluirFormularios(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarFormularios"
                                      checked={editarFormularios}
                                      onChange={function (e: any) {
                                        setEditarFormularios(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirFormularios"
                                      checked={excluirFormularios}
                                      onChange={function (e: any) {
                                        setExcluirFormularios(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalFormularios"
                                      checked={AcessoTotalFormularios}
                                      onChange={function (e: any) {
                                        setAcessoTotalFormularios(e.target.checked)
                                        setExcluirFormularios(e.target.checked)
                                        setEditarFormularios(e.target.checked)
                                        setIncluirFormularios(e.target.checked)
                                        setVisualizarFormularios(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {/*FIM USUARIO/FORMULARIOS*/}
                            {/*INICIO CODIGO SISTEMA */}
                            <table hidden={true} className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-relatorios">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Visualizar</th>
                                  <th className="text-center">Incluir</th>
                                  <th className="text-center">Editar</th>
                                  <th className="text-center">Excluir</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Código do Sistema</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuCodSistema"
                                      checked={visualizarCodSistema}
                                      onChange={function (e: any) {
                                        setVisualizarCodSistema(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirCodSistema"
                                      checked={incluirCodSistema}
                                      onChange={function (e: any) {
                                        setIncluirCodSistema(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarCodSistema"
                                      checked={EditarCodSistema}
                                      onChange={function (e: any) {
                                        setEditarCodSistema(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirCodSistema"
                                      checked={ExcluirCodSistema}
                                      onChange={function (e: any) {
                                        setExcluirCodSistema(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalCodSistema"
                                      checked={AcessoTotalCodSistema}
                                      onChange={function (e: any) {
                                        setAcessoTotalCodSistema(e.target.checked)
                                        setExcluirCodSistema(e.target.checked)
                                        setEditarCodSistema(e.target.checked)
                                        setIncluirCodSistema(e.target.checked)
                                        setVisualizarCodSistema(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {/*FIM CODIGO SISTEMA */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* FIM - ADMINISTRATIVO */}
                  <br />
                  {/*INICIO - CADASTRO */}
                  <div className="accordion" id="acordeao-exemplo2">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="titulo-2">
                        <h4

                          className="accordion-button"

                          data-bs-toggle="collapse"

                          data-bs-target="#item-2"

                          aria-expanded="true"
                          aria-controls="item-2"

                        >

                          Cadastro

                        </h4>

                      </h2>
                      <div

                        id="item-2"
                        className="accordion-collapse collapse show"
                        aria-labelledby="titulo-2"
                        data-bs-parent="#acordeao-exemplo2"
                      >
                        <div className="accordion-body">

                          <div className="table-responsive">
                            <table className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-relatorios">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Visualizar</th>
                                  <th className="text-center">Incluir</th>
                                  <th className="text-center">Editar</th>
                                  <th className="text-center">Excluir</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Filial</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuFilial"
                                      checked={visualizarFilial}
                                      onChange={function (e: any) {
                                        setVisualizarFilial(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirFilial"
                                      checked={incluirFilial}
                                      onChange={function (e: any) {
                                        setIncluirFilial(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarFilial"
                                      checked={EditarFilial}
                                      onChange={function (e: any) {
                                        setEditarFilial(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirFilial"
                                      checked={ExcluirFilial}
                                      onChange={function (e: any) {
                                        setExcluirFilial(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalFilial"
                                      checked={AcessoTotalFilial}
                                      onChange={function (e: any) {
                                        setAcessoTotalFilial(e.target.checked)
                                        setExcluirFilial(e.target.checked)
                                        setEditarFilial(e.target.checked)
                                        setIncluirFilial(e.target.checked)
                                        setVisualizarFilial(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                                {/*FIM DA FILIAL */}
                                <tr id="tpr-1">
                                  <td className="w-25">Bot</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuBot"
                                      checked={visualizarBot}
                                      onChange={function (e: any) {
                                        setVisualizarBot(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirBot"
                                      checked={incluirBot}
                                      onChange={function (e: any) {
                                        setIncluirBot(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarBot"
                                      checked={EditarBot}
                                      onChange={function (e: any) {
                                        setEditarBot(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirBot"
                                      checked={ExcluirBot}
                                      onChange={function (e: any) {
                                        setExcluirBot(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalBot"
                                      checked={AcessoTotalBot}
                                      onChange={function (e: any) {
                                        setAcessoTotalBot(e.target.checked)
                                        setExcluirBot(e.target.checked)
                                        setEditarBot(e.target.checked)
                                        setIncluirBot(e.target.checked)
                                        setVisualizarBot(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Clientes</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuFormularios"
                                      checked={visualizarCadClientes}
                                      onChange={function (e: any) {
                                        setVisualizarCadClientes(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirFormularios"
                                      checked={incluirCadClientes}
                                      onChange={function (e: any) {
                                        setIncluirCadClientes(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarFormularios"
                                      checked={editarCadClientes}
                                      onChange={function (e: any) {
                                        setEditarCadClientes(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirFormularios"
                                      checked={excluirCadClientes}
                                      onChange={function (e: any) {
                                        setExcluirCadClientes(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalFormularios"
                                      checked={acessoTotalCadClientes}
                                      onChange={function (e: any) {
                                        setAcessoTotalCadClientes(e.target.checked)
                                        setVisualizarCadClientes(e.target.checked)
                                        setIncluirCadClientes(e.target.checked)
                                        setEditarCadClientes(e.target.checked)
                                        setExcluirCadClientes(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* FIM - CADASTROS*/}

                  {/*INICIO - TICKETS */}
                  <div className="accordion mt-3" id="acordeao-exemplo3">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="titulo-3">
                        <h4

                          className="accordion-button"

                          data-bs-toggle="collapse"

                          data-bs-target="#item-3"

                          aria-expanded="true"
                          aria-controls="item-3"

                        >

                          Tickets

                        </h4>

                      </h2>
                      <div

                        id="item-3"
                        className="accordion-collapse collapse show"
                        aria-labelledby="titulo-3"
                        data-bs-parent="#acordeao-exemplo3"
                      >
                        <div className="accordion-body">

                          <div className="table-responsive">
                            <table className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-tickets">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Visualizar</th>
                                  <th className="text-center">Incluir</th>
                                  <th className="text-center">Editar</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Tickets</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuTickets"
                                      checked={visualizarTicket}
                                      onChange={function (e: any) {
                                        setVisualizarTicket(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirTickets"
                                      checked={incluirTicket}
                                      onChange={function (e: any) {
                                        setIncluirTicket(e.target.checked)
                                        if (e.target.checked) {
                                          setVisualizarTicket(true)
                                        } else {
                                          setVisualizarTicket(false)
                                        }
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarTickets"
                                      checked={editarTicket}
                                      onChange={function (e: any) {
                                        setEditarTicket(e.target.checked)
                                        if (e.target.checked) {
                                          setVisualizarTicket(true)
                                        } else {
                                          setVisualizarTicket(false)
                                        }
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalTickets"
                                      checked={AcessoTotalTicket}
                                      onChange={function (e: any) {
                                        setAcessoTotalTicket(e.target.checked)
                                        setEditarTicket(e.target.checked)
                                        setIncluirTicket(e.target.checked)
                                        setVisualizarTicket(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* FIM - TICKETS*/}

                  {/*INICIO - CONTROLE DE TICKETS */}
                  <div className="accordion mt-3" id="acordeao-exemplo4">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="titulo-4">
                        <h4

                          className="accordion-button"

                          data-bs-toggle="collapse"

                          data-bs-target="#item-4"

                          aria-expanded="true"
                          aria-controls="item-4"

                        >

                          Controle de Tickets

                        </h4>

                      </h2>
                      <div

                        id="item-4"
                        className="accordion-collapse collapse show"
                        aria-labelledby="titulo-4"
                        data-bs-parent="#acordeao-exemplo4"
                      >
                        <div className="accordion-body">

                          <div className="table-responsive">
                            <table className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-tickets">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Visualizar</th>
                                  <th className="text-center">Editar</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Controle de Tickets</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visualizarControleTickets"
                                      checked={visualizarControleTicket}
                                      onChange={function (e: any) {
                                        setVisualizarControleTicket(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarControleTickets"
                                      checked={editarControleTicket}
                                      onChange={function (e: any) {
                                        setEditarControleTicket(e.target.checked)
                                        if (e.target.checked) {
                                          setVisualizarControleTicket(true)
                                        } else {
                                          setVisualizarControleTicket(false)
                                        }
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalControleTickets"
                                      checked={AcessoTotalControleTicket}
                                      onChange={function (e: any) {
                                        setAcessoTotalControleTicket(e.target.checked)
                                        setEditarControleTicket(e.target.checked)
                                        setVisualizarControleTicket(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                                <tr id="tpr-1">
                                  <td className="w-25">Relatórios</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visualizarRelatoriosTickets"
                                      checked={visualizarRelatorios}
                                      onChange={function (e: any) {
                                        setVisualizarRelatorios(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* FIM - CONTROLE DE TICKETS*/}

                  {/*INICIO - CONTROLE DE METAS */}
                  <div className="accordion mt-3" id="acordeao-exemplo4">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="titulo-4">
                        <h4

                          className="accordion-button"

                          data-bs-toggle="collapse"

                          data-bs-target="#item-4"

                          aria-expanded="true"
                          aria-controls="item-4"

                        >

                          Controle de Metas/SLA

                        </h4>

                      </h2>
                      <div

                        id="item-4"
                        className="accordion-collapse collapse show"
                        aria-labelledby="titulo-4"
                        data-bs-parent="#acordeao-exemplo4"
                      >
                        <div className="accordion-body">

                          <div className="table-responsive">
                            <table className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-tickets">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Visualizar</th>
                                  <th className="text-center">Incluir</th>
                                  <th className="text-center">Editar</th>
                                  <th className="text-center">Excluir</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Controle de Metas/SLA</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visualizarControleMetas"
                                      checked={visualizarMetas}
                                      onChange={function (e: any) {
                                        setVisualizarMetas(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirControleMetas"
                                      checked={incluirMetas}
                                      onChange={function (e: any) {
                                        setIncluirMetas(e.target.checked)
                                        if (e.target.checked) {
                                          setVisualizarMetas(true)
                                        } else {
                                          setVisualizarMetas(false)
                                        }
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarControleMetas"
                                      checked={editarMetas}
                                      onChange={function (e: any) {
                                        setEditarMetas(e.target.checked)
                                        if (e.target.checked) {
                                          setVisualizarMetas(true)
                                        } else {
                                          setVisualizarMetas(false)
                                        }
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_excluirControleMetas"
                                      checked={excluirMetas}
                                      onChange={function (e: any) {
                                        setExcluirMetas(e.target.checked)
                                        if (e.target.checked) {
                                          setVisualizarMetas(true)
                                        } else {
                                          setVisualizarMetas(false)
                                        }
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalControleTickets"
                                      checked={AcessoTotalControleMetas}
                                      onChange={function (e: any) {
                                        setAcessoTotalControleMetas(e.target.checked)
                                        setVisualizarMetas(e.target.checked)
                                        setIncluirMetas(e.target.checked)
                                        setEditarMetas(e.target.checked)
                                        setExcluirMetas(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* FIM - CONTROLE DE METAS*/}

                  {/*INICIO - CONFIGURACAO DE AGENTES */}
                  <div className="accordion mt-3" id="acordeao-exemplo5">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="titulo-5">
                        <h4

                          className="accordion-button"

                          data-bs-toggle="collapse"

                          data-bs-target="#item-5"

                          aria-expanded="true"
                          aria-controls="item-5"

                        >

                          Configuração de Agentes

                        </h4>

                      </h2>
                      <div

                        id="item-5"
                        className="accordion-collapse collapse show"
                        aria-labelledby="titulo-5"
                        data-bs-parent="#acordeao-exemplo5"
                      >
                        <div className="accordion-body">

                          <div className="table-responsive">
                            <table className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-tickets">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Visualizar</th>
                                  <th className="text-center">Incluir</th>
                                  <th className="text-center">Editar</th>
                                  <th className="text-center">Excluir</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Agentes</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visualizarConfiguracaoAgentes"
                                      checked={visualizarConfiguracaoAgentes}
                                      onChange={function (e: any) {
                                        setVisualizarConfiguracaoAgentes(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarConfiguracaoAgentes"
                                      checked={incluirConfiguracaoAgentes}
                                      onChange={function (e: any) {
                                        setIncluirConfiguracaoAgentes(e.target.checked)
                                        if (e.target.checked) {
                                          setVisualizarConfiguracaoAgentes(true)
                                        } else {
                                          setVisualizarConfiguracaoAgentes(false)
                                        }
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarConfiguracaoAgentes"
                                      checked={editarConfiguracaoAgentes}
                                      onChange={function (e: any) {
                                        setEditarConfiguracaoAgentes(e.target.checked)
                                        if (e.target.checked) {
                                          setVisualizarConfiguracaoAgentes(true)
                                        } else {
                                          setVisualizarConfiguracaoAgentes(false)
                                        }
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirConfiguracaoAgentes"
                                      checked={excluirConfiguracaoAgentes}
                                      onChange={function (e: any) {
                                        setExcluirConfiguracaoAgentes(e.target.checked)
                                        if (e.target.checked) {
                                          setVisualizarConfiguracaoAgentes(true)
                                        } else {
                                          setVisualizarConfiguracaoAgentes(false)
                                        }
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalConfiguracaoAgentes"
                                      checked={acessoTotalConfiguracaoAgentes}
                                      onChange={function (e: any) {
                                        setAcessoTotalConfiguracaoAgentes(e.target.checked)
                                        setVisualizarConfiguracaoAgentes(e.target.checked)
                                        setIncluirConfiguracaoAgentes(e.target.checked)
                                        setEditarConfiguracaoAgentes(e.target.checked)
                                        setExcluirConfiguracaoAgentes(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* FIM - CONFIGURACAO DE AGENTES */}

                  {/*INICIO - GESTÃO DE ATENDIMENTOS */}
                  <div className="accordion mt-3" id="acordeao-exemplo6">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="titulo-6">
                        <h4

                          className="accordion-button"

                          data-bs-toggle="collapse"

                          data-bs-target="#item-6"

                          aria-expanded="true"
                          aria-controls="item-6"

                        >

                          Gestão de Atendimentos

                        </h4>

                      </h2>
                      <div

                        id="item-6"
                        className="accordion-collapse collapse show"
                        aria-labelledby="titulo-6"
                        data-bs-parent="#acordeao-exemplo6"
                      >
                        <div className="accordion-body">

                          <div className="table-responsive">
                            <table className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-tickets">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Visualizar</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Gestão de Atendimentos</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visualizarGestaoAtendimentos"
                                      checked={visualizarGestaoAtendimentos}
                                      onChange={function (e: any) {
                                        setVisualizarGestaoAtendimentos(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                                <tr id="tpr-1">
                                  <td className="w-25">Relatório de Satisfação</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visualizarRelatorioSatisfacao"
                                      checked={visualizarRelatorioSatisfacao}
                                      onChange={function (e: any) {
                                        setVisualizarRelatorioSatisfacao(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* FIM - GESTÃO DE ATENDIMENTOS */}

                  {/*INICIO - CHAT SUPORTE */}
                  <div className="accordion mt-3" id="acordeao-exemplo7">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="titulo-7">
                        <h4

                          className="accordion-button"

                          data-bs-toggle="collapse"

                          data-bs-target="#item-7"

                          aria-expanded="true"
                          aria-controls="item-7"

                        >

                          Chat Suporte

                        </h4>

                      </h2>
                      <div

                        id="item-7"
                        className="accordion-collapse collapse show"
                        aria-labelledby="titulo-7"
                        data-bs-parent="#acordeao-exemplo7"
                      >
                        <div className="accordion-body">

                          <div className="table-responsive">
                            <table className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-tickets">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Visualizar</th>
                                  <th className="text-center">Enviar</th>
                                  <th className="text-center">Apagar</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Chat Suporte</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visualizarChatSuporte"
                                      checked={visualizarChatSuporte}
                                      onChange={function (e: any) {
                                        setVisualizarChatSuporte(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_enviarChatSuporte"
                                      checked={enviarChatSuporte}
                                      onChange={function (e: any) {
                                        setEnviarChatSuporte(e.target.checked)
                                        if (e.target.checked) {
                                          setVisualizarChatSuporte(true)
                                        } else {
                                          setVisualizarChatSuporte(false)
                                        }
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_apagarChatSuporte"
                                      checked={apagarChatSuporte}
                                      onChange={function (e: any) {
                                        setApagarChatSuporte(e.target.checked)
                                        if (e.target.checked) {
                                          setVisualizarChatSuporte(true)
                                        } else {
                                          setVisualizarChatSuporte(false)
                                        }
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalChatSuporte"
                                      checked={AcessoTotalChatSuporte}
                                      onChange={function (e: any) {
                                        setAcessoTotalChatSuporte(e.target.checked)
                                        setVisualizarChatSuporte(e.target.checked)
                                        setEnviarChatSuporte(e.target.checked)
                                        setApagarChatSuporte(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* FIM - CHAT SUPORTE */}
                  <br />

                </TabPanel>
              </TabContext>
            </Box>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex my-2 gap-1 justify-content-between">
              <Button
                invisivel={false}
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  navigate(-1);
                }}
              />
              <Button
                invisivel={false}
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
              />
            </div>
          </Card.Footer>
        </Form>
      </Card>
      <ModalLoading show={loading} label="Carregado dados do perfil ..." />
    </>
  );
};
export default FormPerfil;
