import { useParams } from "react-router-dom";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo"
import FormCliente from "./components/formulario"


function ClientesVisualizar() {
    const idcliente = useParams()

    return (
        <>
            <div className="col mt-2">
                <ContainerTitulo titulo="Visualização de Cliente" />
                <FormCliente idcliente={idcliente.id} acao="Visualizar" />
            </div>
        </>
    )
}

export default ClientesVisualizar