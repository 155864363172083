import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import ModalLoading from "../Formularios/Modal/ModalLoading"
import axios from "axios"
import { toast } from "react-toastify"
function VisualizarCamposDinamicos({
    idTicket,
    fecharClick,
    show
}: {
    idTicket: string,
    fecharClick: () => void,
    show: boolean
}
) {
    const [arrayCampos, setArrayCampos] = useState([])
    const [showModalLoading, setShowModalLoading] = useState(false)
    const token = sessionStorage.getItem("MultfilialWebToken")!
    function CarregarCamposDinamicosTicket(idTicket: string) {
        setShowModalLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/ticket/campos/detalhe/${idTicket}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setArrayCampos(resposta.data.campos)
            setShowModalLoading(false)
        }).catch(function (erro) {
            toast.error(erro.response.data.message || erro.message)
            setShowModalLoading(false)
        })
    }
    useEffect(function () {
        CarregarCamposDinamicosTicket(idTicket)
    }, [])
    return (
        <Modal show={show} onHide={fecharClick} size="lg">
            <Modal.Header className="bg-white" closeButton>
                <h4>Detalhes do Ticket [Ticket #{idTicket}]</h4>
            </Modal.Header>
            <Modal.Body className="bg-white">
                <div className="container">
                    <div className="row">
                        {
                            arrayCampos.map(function (campo: any) {
                                return <div className="col-sm col-md-12 col-lg-12">
                                    <div className="form-floating">
                                        <p><b>{campo.label}</b>: <label>{campo.conteudo}</label></p>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </Modal.Body>
            <ModalLoading
                show={showModalLoading}
                label="Carregando campos de detalhes..."
            />
        </Modal>
    )
}

export default VisualizarCamposDinamicos