import { Card, Form } from "react-bootstrap"
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo"
import { Box, Tab } from "@mui/material"
import TabContext from "@mui/lab/TabContext"
import { useEffect, useState } from "react"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import ClickableTable from "../../../components/ClickableTable/ClickableTabl"
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface"
import axios from "axios"
import { getSessionData } from "../../../utils/storageUtils"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading"
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda"
import { Button } from "../../../components/Formularios/Buttons/Button"
import { mdiSendCircleOutline } from "@mdi/js"

function AdministrarTickets() {
    const [guiaAtual, set_guiaAtual] = useState("")
    const [dadosAbertos, setDadosAbertos] = useState([])
    const [dadosPendentes, setDadosPendentes] = useState([])
    const [dadosFechados, setDadosFechados] = useState([])
    const token = getSessionData('MultfilialWebToken') || "";
    const [loading, setLoading] = useState<boolean>(false);
    const [buscarPorFilial, setBuscarPorFilial] = useState(true)
    const [permissao_editar, set_permissao_editar] = useState(true)
    const [idTicket, setIdTicket] = useState("")
    const [filtro, setFiltro] = useState("")
    const [categoria, setCategoria] = useState("")
    const [sistema, setSistema] = useState("")
    const navigate = useNavigate()

    const [guia, setGuia] = useState("1")
    const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
        setGuia(novaGuia);
    };

    const colunas: ITabela[] = [
        { titulo: "ID", acesso: "id_ticket" },
        { titulo: "Titulo", acesso: "titulo" },
        { titulo: "Categoria", acesso: "categoria" },
        { titulo: "Sistema", acesso: "sistema" },
        { titulo: "Requerente", acesso: "usuario_criacao" },
        { titulo: "Cliente", acesso: "cliente" },
        { titulo: "Agente", acesso: "agente" },
        { titulo: "Data de Abertura", acesso: "dt_criacao" }
    ]

    // Função para buscar os tickets abertos
    function buscarTicketsAbertos() {
        const idFilial = getSessionData("DadosIdfilial"); // Obtém o ID da filial da sessão
        const idUsuario = getSessionData("DadosUsuarioID")

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/tickets/abertos`, {
            headers: {
                Authorization: token // Token de autenticação
            },
            params: {
                idFilial, // Passa o ID da filial
                categoria,
                sistema,
                idUsuario
            }
        }).then(function (resposta) {
            setLoading(true);
            //Verifica se algum dos filtros esta preenchido para atualizar a tabela
            if (categoria.length > 0) {
                setDadosAbertos(resposta.data.dataFiltroCategoria)
            } else if (sistema.length > 0) {
                setDadosAbertos(resposta.data.dataFiltroSistema)
            } else {
                setDadosAbertos(resposta.data.data); // Armazena os dados retornados
            }
            setLoading(false);
        }).catch(function (erro) {
            const status = erro.response.status;
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente"); // Notifica o erro de acesso
            }
        });
    }

    //Serão recarregados os dados sempre que uma das variáveis for alterada
    useEffect(function () {
        buscarTicketsAbertos()
        buscarTicketsPendentes()
        buscarTicketsFechados()
    }, [categoria, sistema])

    // Função para buscar os tickets pendentes
    function buscarTicketsPendentes() {
        const idFilial = getSessionData("DadosIdfilial");
        const idUsuario = getSessionData("DadosUsuarioID")

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/tickets/pendentes`, {
            headers: {
                Authorization: token
            },
            params: {
                idFilial,
                categoria,
                sistema,
                idUsuario
            }
        }).then(function (resposta) {
            setLoading(true);
            //Verifica se algum dos filtros esta preenchido para atualizar a tabela
            if (categoria.length > 0) {
                setDadosPendentes(resposta.data.dataFiltroCategoria)
            } else if (sistema.length > 0) {
                setDadosPendentes(resposta.data.dataFiltroSistema)
            } else {
                setDadosPendentes(resposta.data.data); // Armazena os dados retornados
            }
            setLoading(false);
        }).catch(function (erro) {
            const status = erro.response.status;
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente");
            }
        });
    }

    // Função para buscar os tickets fechados
    function buscarTicketsFechados() {
        const idFilial = getSessionData("DadosIdfilial");
        const idUsuario = getSessionData("DadosUsuarioID")

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/tickets/fechados`, {
            headers: {
                Authorization: token
            },
            params: {
                idFilial,
                categoria,
                sistema,
                idUsuario
            }
        }).then(function (resposta) {
            setLoading(true);
            //Verifica se algum dos filtros esta preenchido para atualizar a tabela
            if (categoria.length > 0) {
                setDadosFechados(resposta.data.dataFiltroCategoria)
            } else if (sistema.length > 0) {
                setDadosFechados(resposta.data.dataFiltroSistema)
            } else {
                setDadosFechados(resposta.data.data); // Armazena os dados retornados
            }
            setLoading(false);
        }).catch(function (erro) {
            const status = erro.response.status;
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente");
            }
        });
    }

    // useEffect para carregar os dados assim que o componente for montado
    useEffect(() => {
        buscarTicketsAbertos();
        buscarTicketsPendentes();
        buscarTicketsFechados();
    }, []);

    // Função para navegar para a página de detalhes do ticket
    function irParaDetalhes(dados: any) {
        navigate(`detalhesTicket/${dados.id_ticket}`); // Redireciona para os detalhes do ticket selecionado
    }

    //Funcao que limpa os filtros quando acionada
    function removerFiltro() {
        setCategoria('')
        setSistema('')
        setFiltro('')
    }


    function teste() {

    }

    return (
        <>
            <div className="col">
                <ContainerTitulo titulo="Administração dos Tickets" />

                <Card>
                    <Form onSubmit={teste}>
                        <Box sx={{ width: "100%" }}>
                            <TabContext value={guia}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <TabList
                                        onChange={capturaGuia}
                                        TabIndicatorProps={{
                                            style: {
                                                backgroundColor: "#000",
                                                color: "#000",
                                            },
                                        }}
                                    >
                                        <Tab onClick={function () {
                                            set_guiaAtual("")
                                        }} label="Tickets Abertos" value="1" />

                                        <Tab onClick={function () {
                                            set_guiaAtual("")
                                        }} label="Tickets Pendentes" value="2" />
                                        <Tab onClick={function () {

                                            set_guiaAtual("")
                                        }} label="Tickets Finalizados" value="3" />
                                    </TabList>
                                    <TabPanel value="1">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-sm col-md-2 col-lg-2">
                                                    <div className="col-16 form-floating">
                                                        <select
                                                            autoFocus
                                                            required
                                                            className="form-select bordasInferiorInput text-black text-capitalize"
                                                            onChange={(event) => setFiltro(event.target.value)}
                                                            value={filtro}
                                                        >
                                                            <option value={""}>Nenhum</option>
                                                            <option value={"1"}>Por Categoria</option>
                                                            <option value={"2"}>Por Sistema</option>
                                                        </select>
                                                        <label className="form-label">Filtros Extras</label>
                                                    </div>
                                                </div>
                                                <div hidden={filtro !== "1"} className="col-sm col-md-2 col-lg-2">
                                                    <InputSemBorda
                                                        label="Categoria"
                                                        name="categoria"
                                                        type="text"
                                                        placeholder="Categoria"
                                                        value={categoria}
                                                        onChange={setCategoria}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                                    />
                                                </div>
                                                <div hidden={filtro !== "2"} className="col-sm col-md-2 col-lg-2">
                                                    <InputSemBorda
                                                        label="Sistema"
                                                        name="sistema"
                                                        type="text"
                                                        placeholder="Sistema"
                                                        value={sistema}
                                                        onChange={setSistema}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                                    />
                                                </div>
                                                <div hidden={filtro === ""} className="col-sm col-md-2 col-lg-2">
                                                    <div className="mt-2">
                                                        <Button
                                                            invisivel={false}
                                                            className="btn btn-dark text-info-emphasis bg-primary border-0 rounded"
                                                            descricaoBotao="Remover filtro"
                                                            type='button'
                                                            onclick={removerFiltro}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <ClickableTable
                                                    coluna={colunas}
                                                    data={dadosAbertos}
                                                    usaRowClick={true}
                                                    onRowClick={irParaDetalhes}
                                                    itemsPerPage={10}
                                                    acaoVisualizar={teste}
                                                    acaoEditar={irParaDetalhes}
                                                    acaoExcluir={teste}
                                                    usaAcoes={false}
                                                    usaEditar={permissao_editar}
                                                    //usaVisualizar={permissao_visualizar}
                                                    //usaExcluir={permissao_excluir}
                                                    labelpesquisa="Buscar por Titulo"
                                                    acessopesquisa="titulo"
                                                />
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-sm col-md-2 col-lg-2">
                                                    <div className="col-16 form-floating">
                                                        <select
                                                            autoFocus
                                                            required
                                                            className="form-select bordasInferiorInput text-black text-capitalize"
                                                            onChange={(event) => setFiltro(event.target.value)}
                                                            value={filtro}
                                                        >
                                                            <option value={""}>Nenhum</option>
                                                            <option value={"1"}>Por Categoria</option>
                                                            <option value={"2"}>Por Sistema</option>
                                                        </select>
                                                        <label className="form-label">Filtros Extras</label>
                                                    </div>
                                                </div>
                                                <div hidden={filtro !== "1"} className="col-sm col-md-2 col-lg-2">
                                                    <InputSemBorda
                                                        label="Categoria"
                                                        name="categoria"
                                                        type="text"
                                                        placeholder="Categoria"
                                                        value={categoria}
                                                        onChange={setCategoria}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                                    />
                                                </div>
                                                <div hidden={filtro !== "2"} className="col-sm col-md-2 col-lg-2">
                                                    <InputSemBorda
                                                        label="Sistema"
                                                        name="sistema"
                                                        type="text"
                                                        placeholder="Sistema"
                                                        value={sistema}
                                                        onChange={setSistema}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                                    />
                                                </div>
                                                <div hidden={filtro === ""} className="col-sm col-md-2 col-lg-2">
                                                    <div className="mt-2">
                                                        <Button
                                                            invisivel={false}
                                                            className="btn btn-dark text-info-emphasis bg-primary border-0 rounded"
                                                            descricaoBotao="Remover filtro"
                                                            type='button'
                                                            onclick={removerFiltro}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <ClickableTable
                                                    coluna={colunas}
                                                    data={dadosPendentes}
                                                    usaRowClick={true}
                                                    onRowClick={irParaDetalhes}
                                                    itemsPerPage={10}
                                                    acaoVisualizar={teste}
                                                    acaoEditar={irParaDetalhes}
                                                    acaoExcluir={teste}
                                                    usaAcoes={false}
                                                    usaEditar={permissao_editar}
                                                    //usaVisualizar={permissao_visualizar}
                                                    //usaExcluir={permissao_excluir}
                                                    labelpesquisa="Buscar por Titulo"
                                                    acessopesquisa="titulo"
                                                />
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value="3">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-sm col-md-2 col-lg-2">
                                                    <div className="col-16 form-floating">
                                                        <select
                                                            autoFocus
                                                            required
                                                            className="form-select bordasInferiorInput text-black text-capitalize"
                                                            onChange={(event) => setFiltro(event.target.value)}
                                                            value={filtro}
                                                        >
                                                            <option value={""}>Nenhum</option>
                                                            <option value={"1"}>Por Categoria</option>
                                                            <option value={"2"}>Por Sistema</option>
                                                        </select>
                                                        <label className="form-label">Filtros Extras</label>
                                                    </div>
                                                </div>
                                                <div hidden={filtro !== "1"} className="col-sm col-md-2 col-lg-2">
                                                    <InputSemBorda
                                                        label="Categoria"
                                                        name="categoria"
                                                        type="text"
                                                        placeholder="Categoria"
                                                        value={categoria}
                                                        onChange={setCategoria}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                                    />
                                                </div>
                                                <div hidden={filtro !== "2"} className="col-sm col-md-2 col-lg-2">
                                                    <InputSemBorda
                                                        label="Sistema"
                                                        name="sistema"
                                                        type="text"
                                                        placeholder="Sistema"
                                                        value={sistema}
                                                        onChange={setSistema}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                                    />
                                                </div>
                                                <div hidden={filtro === ""} className="col-sm col-md-2 col-lg-2">
                                                    <div className="mt-2">
                                                        <Button
                                                            invisivel={false}
                                                            className="btn btn-dark text-info-emphasis bg-primary border-0 rounded"
                                                            descricaoBotao="Remover filtro"
                                                            type='button'
                                                            onclick={removerFiltro}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <ClickableTable
                                                    coluna={colunas}
                                                    data={dadosFechados}
                                                    usaRowClick={true}
                                                    onRowClick={irParaDetalhes}
                                                    itemsPerPage={10}
                                                    acaoVisualizar={teste}
                                                    acaoEditar={irParaDetalhes}
                                                    acaoExcluir={teste}
                                                    usaAcoes={false}
                                                    usaEditar={permissao_editar}
                                                    //usaVisualizar={permissao_visualizar}
                                                    //usaExcluir={permissao_excluir}
                                                    labelpesquisa="Buscar por Titulo"
                                                    acessopesquisa="titulo"
                                                />
                                            </div>
                                        </div>
                                    </TabPanel>
                                </Box>
                            </TabContext>
                        </Box>
                    </Form>
                </Card>
                <ModalLoading show={loading} label="Carregando..." />
            </div>
        </>
    )
}

export default AdministrarTickets