import Swal from 'sweetalert2';

interface ConfirmationResult {
  confirmed: boolean;
}




export const showConfirmationDialog = (titulo: string, texto: string,
  icone: 'error' | 'question' | 'warning' | 'info' | 'success',
  acao: any = 0,
): Promise<ConfirmationResult> => {
  return new Promise((resolve, reject) => {
    Swal.fire({
      title: titulo,
      text: texto,
      icon: icone,
      confirmButtonText: acao == 0 ? 'Excluir' : acao == 1 ? 'Finalizar' : acao == 2 ? 'Reabrir' : 'Excluir',
      cancelButtonText: acao == 0 ? 'Cancelar' : acao == 1 ? 'Cancelar' : acao == 2 ? 'Cancelar' : 'Continuar',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#1605EE',
      reverseButtons: true,
      focusConfirm: true,
      focusCancel: false,
      allowEscapeKey: true,
      allowEnterKey: false,
      showLoaderOnConfirm: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        resolve({ confirmed: true });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        resolve({ confirmed: false });
      } else {
        reject();
      }
    });
  });
};
