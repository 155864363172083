import { useNavigate, useParams } from "react-router-dom";
import ModalLoading from "../../../../../components/Formularios/Modal/ModalLoading";
import { useState, useEffect, ChangeEvent } from "react";
import { toast } from "react-toastify";
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import InputSemBorda from "../../../../../components/Formularios/Inputs/InputsSemBorda";
// import applyCepMask  from "../../../../../../components/Formularios/Inputs/InputComMascara";
import { mascaraCEP, mascaraTelefoneCelular, mascaraTelefoneFixo } from "../../../../../hooks/mascaras";

import {
    Box,
    Switch,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Tab,
    Avatar,
    Stack,
    Button,
    Paper,
    Typography,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button as ButtonCustom } from "../../../../../components/Formularios/Buttons/Button";
import {
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiLockCheckOutline,
} from "@mdi/js";
import ModalUploadImage from "../../../../../components/Formularios/Modal/ModalUpload";
import { styled } from "@mui/material/styles";
import ConverterBase64 from "../../../../../components/ConversorBase64";
import { dadosUF } from "../../../../../components/dadosFixos/UF";
import { dadosPais } from "../../../../../components/dadosFixos/Pais";
import { TextoFormatoCaptalize } from "../../../../../components/Auxiliar/ConvTextoCaptalize";
import { TextoFormatoLowerCase } from "../../../../../components/Auxiliar/ConvTextoLower";
import { TextoFormatoUpperCase } from "../../../../../components/Auxiliar/ConvTextoUpper";
import useSelectOptions from "../../../../../hooks/useSelectOptions";
import SelectInput from "../../../../../shared/SelectInputs";
import axios from "axios";
import { getSessionData } from "../../../../../utils/storageUtils";
import ReactQuill from "react-quill";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#F0F0F0",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: "#616161",
    borderRadius: "5px",
    fontWeight: "bold",
    fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
}))

interface FormfilialProps {
    idcliente?: string;
    acao?: string;
}

// Definindo a interface para o tipo de cliente
interface TipoCliente {
    id: number;
    descricao: string;
}

const FormCliente = ({ idcliente, acao }: FormfilialProps) => {

    const isSuper = getSessionData("isPerfilSuper")
    const id_filial = getSessionData("DadosIdfilial")
    const token = getSessionData("MultfilialWebToken") || "";
    const navigate = useNavigate();
    const isVinculo = getSessionData("isPerfilVinculo") || "0"

    //#region Controla as guias
    const [guia, setGuia] = useState("1")

    const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
        setGuia(novaGuia);
    };
    //#endregion

    const [showUpload, setShowUpload] = useState<boolean>(false);

    //const [id_filial, setId_filial] = useState<any>(idfilial || "");
    const [CNPJ, setCNPJ] = useState<string>("")
    const [status, setStatus] = useState<boolean>(true);
    const [visualizarTickets, setVisualizarTickets] = useState<boolean>(false);
    const [escolherPrioridade, setEscolherPrioridade] = useState<boolean>(false);
    const [usaMensagemPersonalizada, setUsaMensagemPersonalizada] = useState<boolean>(false);
    const [mensagemPersonalizada, setMensagemPersonalizada] = useState<string>();
    const [id_cliente, setid_cliente] = useState<string>(idcliente || "");

    const [tipoCliente, setTipoCliente] = useState<TipoCliente[]>([]);
    const [tipoClienteSelecionado, setTipoClienteSelecionado] = useState<string>("");

    const [email, setEmail] = useState<string>("");
    const [logradouro, setLogradouro] = useState<string>("");
    const [numero, setNumero] = useState<string>("");
    const [bairro, setBairro] = useState<string>("");
    const [compl, setCompl] = useState<string>("");
    const [cnum, setCnum] = useState<string>("");
    const [cidade, setCidade] = useState<string>("");
    const [cuf, setCuf] = useState<string>("");
    const [cep, setCep] = useState<string>("");
    const [responsavel, setResponsavel] = useState<string>("");
    const [fantasia, setFantasia] = useState<string>("");
    const [telfixo, setTelfixo] = useState<string>("");
    const [telcelular, setTelcelular] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(false);
    const [permissao, set_permissao] = useState(true) //true = invisivel
    const { id } = useParams<{ id: string }>();

    //preencher com base no CNPJ consultando api de terceiros
    async function carregarDadosCNPJ(event: any) {

        setCNPJ(event)
        if (event.length == 14) {
            const response = await axios.get(`https://publica.cnpj.ws/cnpj/${event}`)

            setCNPJ(response.data.estabelecimento.cnpj)
            setFantasia(response.data.estabelecimento.nome_fantasia)
            setLogradouro(response.data.estabelecimento.logradouro)
            setNumero(response.data.estabelecimento.numero)
            setCompl(response.data.estabelecimento.complemento)
            setBairro(response.data.estabelecimento.bairro)
            setCidade(response.data.estabelecimento.cidade.nome)
            setCnum(response.data.estabelecimento.cidade.ibge_id)
            setCep(response.data.estabelecimento.cep)
            setCuf(response.data.estabelecimento.estado.sigla)
        }
    }

    const buscarCep = async (cep: string) => {
        const _cep = cep.replace("-", "");
        if (_cep.length > 0 && _cep.length < 8) {
            toast.error("CEP inválido!");
        } else if (_cep.length === 8) {
            await axios
                .get(`https://viacep.com.br/ws/${_cep}/json/`)
                .then((retorno) => {
                    if (retorno.data?.erro) {
                        toast.error("CEP inválido ou Não Localizado!");
                    } else {
                        const dadosCep = retorno.data;
                        console.log(dadosCep)

                        setLogradouro(dadosCep.logradouro);
                        setCompl(dadosCep.complemento);
                        setBairro(dadosCep.bairro);
                        setCidade(dadosCep.localidade);
                        setCuf(dadosCep.uf);
                        setCnum(dadosCep.ibge);
                    }
                })
                .catch((erro) => {
                    console.log("erro: ", erro);
                    const msg =
                        erro.response?.data.message === undefined
                            ? erro.message
                            : erro.response.data.message;
                    toast.error(`Erro ao buscar cep. Motivo: ${msg}`);
                });
        }
    };

    function gravarCliente(e: any) {
        e.preventDefault()

        const id_filial = getSessionData("DadosIdfilial")

        // Verifica se o campo está vazio ou contém apenas uma linha em branco
        if (usaMensagemPersonalizada === true && !mensagemPersonalizada || mensagemPersonalizada === '<p><br></p>') {
            toast.info('Não é possível enviar a mensagem personalizada vazia.');
            return
        }

        const dados = {
            CNPJ,
            fantasia,
            email,
            status,
            tipoClienteSelecionado,
            telfixo,
            telcelular,
            responsavel,
            cep,
            logradouro,
            compl,
            numero,
            bairro,
            cidade,
            cnum,
            cuf,
            visualizarTickets,
            escolherPrioridade,
            usaMensagemPersonalizada,
            mensagemPersonalizada
        }

        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/gravar/dados/cliente/${id_filial}`, dados, {
            headers: { Authorization: token } // Adiciona o token de autorização no cabeçalho da requisição
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            setTimeout(function () {
                navigate(-1)
            }, 2000)
            setLoading(false)
        }).catch(function (erro) {
            setLoading(false)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            } else if (status == 500) {
                toast.error(erro.response.data.message)
            }
        })
    }

    function preencherSelectboxTipo() {
        const id_filial = getSessionData("DadosIdfilial")

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/preencher/selectbox/tipo/${id_filial}`, {
            headers: { Authorization: token } // Adiciona o token de autorização no cabeçalho da requisição
        }).then(function (resposta) {
            setTipoCliente(resposta.data.dataTipo)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    useEffect(function () {
        preencherSelectboxTipo()
    }, [])

    // Função que irá buscar os dados do cliente no banco e retornar preenchendo os campos caso seja uma tela de visualizar ou editar
    function buscarClienteSelecionado() {
        const id_filial = getSessionData("DadosIdfilial")

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/cliente/selecionado/${id_filial}/${id}`, {
            headers: { Authorization: token } // Adiciona o token de autorização no cabeçalho da requisição
        }).then(function (resposta) {

            setCNPJ(resposta.data.data[0].cnpj)
            setFantasia(resposta.data.data[0].fantasia)
            setEmail(resposta.data.data[0].email)
            setTipoClienteSelecionado(resposta.data.data[0].tipo)
            setTelfixo(resposta.data.data[0].telefone)
            setTelcelular(resposta.data.data[0].celular)
            setResponsavel(resposta.data.data[0].contato)
            setCep(resposta.data.data[0].cep)
            setLogradouro(resposta.data.data[0].logradouro)
            setCompl(resposta.data.data[0].complemento)
            setNumero(resposta.data.data[0].numero)
            setBairro(resposta.data.data[0].bairro)
            setCidade(resposta.data.data[0].cidade)
            setCnum(resposta.data.data[0].cmun)
            setCuf(resposta.data.data[0].uf)
            setUsaMensagemPersonalizada(resposta.data.data[0].usaMsgPersonalizada)
            setMensagemPersonalizada(resposta.data.data[0].msgPersonalizada)
            console.log(resposta.data.data[0])

            if (resposta.data.data[0].visualizarTickets == 1) {
                setVisualizarTickets(true)
            } else {
                setVisualizarTickets(false)
            }

            if (resposta.data.data[0].escolherPrioridade == 1) {
                setEscolherPrioridade(true)
            } else {
                setEscolherPrioridade(false)
            }

            setLoading(false)
        }).catch(function (erro) {
            setLoading(false)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            } else if (status == 500) {
                toast.error(erro.response.data.message)
            }
        })
    }

    useEffect(function () {
        if (id !== 'novo') {
            buscarClienteSelecionado()
        }
    }, [])

    function alterarCliente(e: any) {
        e.preventDefault()

        const id_filial = getSessionData("DadosIdfilial")

        // Verifica se o campo está vazio ou contém apenas uma linha em branco
        if (usaMensagemPersonalizada === true && !mensagemPersonalizada || mensagemPersonalizada === '<p><br></p>') {
            toast.info('Não é possível enviar a mensagem personalizada vazia.');
            return
        }

        const dados = {
            id,
            email,
            status,
            tipoClienteSelecionado,
            telfixo,
            telcelular,
            responsavel,
            cep,
            logradouro,
            compl,
            numero,
            bairro,
            cidade,
            cnum,
            cuf,
            visualizarTickets,
            escolherPrioridade,
            usaMensagemPersonalizada,
            mensagemPersonalizada
        }

        setLoading(true)
        axios.put(`${process.env.REACT_APP_API_BASE_URL}/alterar/dados/cliente/${id_filial}`, dados, {
            headers: { Authorization: token } // Adiciona o token de autorização no cabeçalho da requisição
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            setTimeout(function () {
                navigate(-1)
            }, 2000)
            setLoading(false)
        }).catch(function (erro) {
            setLoading(false)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            } else if (status == 500) {
                toast.error(erro.response.data.message)
            }
        })
    }

    return (
        <>
            <Card>
                <Form onSubmit={acao === 'Novo' ? gravarCliente : alterarCliente}>
                    <Card.Body>
                        <Box sx={{ width: "100%" }}>
                            <TabContext value={guia}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <TabList
                                        onChange={capturaGuia}
                                        TabIndicatorProps={{
                                            style: {
                                                backgroundColor: "#000",
                                                color: "#000",
                                            },
                                        }}
                                    >
                                        <Tab label="Dados Básicos" value="1" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <Row>
                                        <Col sm={2}>
                                            <InputSemBorda
                                                label="ID Cliente"
                                                name="idcliente"
                                                type="text"
                                                placeholder="ID Cliente"
                                                readonly
                                                value={id_cliente}
                                                onChange={setid_cliente}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <InputSemBorda
                                                label="CNPJ"
                                                name="CNPJ"
                                                type="text"
                                                placeholder="Nome CNPJ"
                                                required
                                                readonly={
                                                    acao === "Visualizar" || acao === "Editar"
                                                        ? true
                                                        : false
                                                }
                                                onBlur={function (e: any) {
                                                    const cnpj_formatado = CNPJ.replace(/[.\-/]/g, '');
                                                    carregarDadosCNPJ(cnpj_formatado)
                                                }}
                                                value={CNPJ || ""}
                                                onChange={function (e: any) {
                                                    setCNPJ(e)
                                                }}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <InputSemBorda
                                                label="Fantasia"
                                                name="fantasia"
                                                type="text"
                                                placeholder="Nome Fantasia"
                                                required
                                                readonly={
                                                    acao === "Visualizar" || acao === "Editar"
                                                        ? true
                                                        : false
                                                }
                                                value={fantasia || ""}
                                                onChange={setFantasia}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <InputSemBorda
                                                label="Email"
                                                name="email"
                                                type="text"
                                                placeholder="Email"
                                                required
                                                readonly={acao === "Visualizar" ? true : false}
                                                value={email || ""}
                                                onChange={setEmail}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                                            />
                                        </Col>
                                        <Col sm={2}>
                                            <br />
                                            <label htmlFor="" className="form-label me-2">
                                                Ativo
                                            </label>
                                            <Switch
                                                checked={status}
                                                onChange={() => setStatus(!status)}
                                                disabled={acao === "Visualizar" ? true : false}
                                            />
                                        </Col>
                                        <Col sm={2}>
                                            <FormControl
                                                variant="standard"
                                                sx={{ m: 1.5, width: "100%" }}
                                            >
                                                <InputLabel>Tipo</InputLabel>
                                                <Select
                                                    value={tipoClienteSelecionado}
                                                    onChange={(e: SelectChangeEvent) =>
                                                        setTipoClienteSelecionado(e.target.value)
                                                    }
                                                    label="UF"
                                                    required
                                                    disabled={
                                                        acao === "Visualizar" ? true : false
                                                    }
                                                >
                                                    <MenuItem value="">
                                                        <em>Selecione...</em>
                                                    </MenuItem>
                                                    {tipoCliente.map((tipo) => (
                                                        <MenuItem key={tipo.id} value={tipo.descricao}>
                                                            {tipo.descricao}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3}>
                                            <InputSemBorda
                                                label="Telefone"
                                                name="telefone"
                                                type="text"
                                                placeholder="Telefone Fixo"
                                                required
                                                readonly={acao === "Visualizar" ? true : false}
                                                // value={telfixo || ""}
                                                value={mascaraTelefoneFixo(telfixo) || ""}
                                                onChange={setTelfixo}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                        <Col sm={3}>
                                            <InputSemBorda
                                                label="Celular"
                                                name="telcelular"
                                                type="text"
                                                placeholder="Celular"
                                                required
                                                readonly={acao === "Visualizar" ? true : false}
                                                // value={telcelular || ""}
                                                value={mascaraTelefoneCelular(telcelular) || ""}
                                                onChange={setTelcelular}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <InputSemBorda
                                                label="Contato"
                                                name="responsavel Técnico"
                                                type="text"
                                                placeholder="Responsável"
                                                required
                                                readonly={acao === "Visualizar" ? true : false}
                                                value={responsavel || ""}
                                                onChange={setResponsavel}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={2}>
                                            <InputSemBorda
                                                label="CEP"
                                                name="cep"
                                                type="text"
                                                placeholder="CEP"
                                                readonly={acao === "Visualizar" ? true : false}
                                                // value={cep || ""}
                                                value={cep || ""}
                                                onBlur={function (e: any) {
                                                    const cep_formatado = cep.replace(/[.\-/]/g, '');
                                                    setCep(cep_formatado)
                                                    buscarCep(cep_formatado)
                                                }}
                                                onChange={setCep}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <InputSemBorda
                                                label="Logradouro"
                                                name="endereco"
                                                type="text"
                                                placeholder="Logradouro"
                                                readonly={acao === "Visualizar" ? true : false}
                                                value={logradouro || ""}
                                                onChange={setLogradouro}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <InputSemBorda
                                                label="Complemento"
                                                name="compl"
                                                type="text"
                                                placeholder="Complemento"
                                                readonly={acao === "Visualizar" ? true : false}
                                                value={compl || ""}
                                                onChange={setCompl}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                        <Col sm={2}>
                                            <InputSemBorda
                                                label="Número"
                                                name="numero"
                                                type="text"
                                                placeholder="Número"
                                                readonly={acao === "Visualizar" ? true : false}
                                                value={numero || ""}
                                                onChange={setNumero}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3}>
                                            <InputSemBorda
                                                label="Bairro"
                                                name="bairro"
                                                type="text"
                                                placeholder="Bairro"
                                                readonly={acao === "Visualizar" ? true : false}
                                                value={bairro || ""}
                                                onChange={setBairro}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                        <Col sm={3}>
                                            <InputSemBorda
                                                label="Cidade"
                                                name="cidade"
                                                type="text"
                                                placeholder="Cidade"
                                                required
                                                readonly={acao === "Visualizar" ? true : false}
                                                value={cidade || ""}
                                                onChange={setCidade}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                        <Col sm={3}>
                                            <InputSemBorda
                                                label="Cód. Municipio"
                                                name="cnum"
                                                type="text"
                                                placeholder="Cod. Municipio"
                                                required
                                                readonly={acao === "Visualizar" ? true : false}
                                                value={cnum || ""}
                                                onChange={setCnum}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                        <Col sm={3}>
                                            <InputSemBorda
                                                label="UF"
                                                name="cnum"
                                                type="text"
                                                placeholder="UF"
                                                required
                                                readonly={acao === "Visualizar" ? true : false}
                                                value={cuf || ""}
                                                onChange={setCuf}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3}>
                                            <br />
                                            <label htmlFor="" className="form-label me-2">
                                                Visualizar Tickets?
                                            </label>
                                            <Switch
                                                checked={visualizarTickets}
                                                onChange={() => setVisualizarTickets(!visualizarTickets)}
                                                disabled={acao === "Visualizar" ? true : false}
                                            />
                                            <h6 hidden={!visualizarTickets} className="mt-0">Ativar esta opção permitirá que os usuários vejam todos os tickets</h6>
                                        </Col>
                                        <Col sm={3}>
                                            <br />
                                            <label htmlFor="" className="form-label me-2">
                                                Escolher Prioridade?
                                            </label>
                                            <Switch
                                                checked={escolherPrioridade}
                                                onChange={() => setEscolherPrioridade(!escolherPrioridade)}
                                                disabled={acao === "Visualizar" ? true : false}
                                            />
                                            <h6 hidden={!escolherPrioridade} className="mt-0">Ativar esta opção permitirá que os usuários escolham a prioridade dos tickets</h6>
                                        </Col>
                                        <Col sm={4}>
                                            <br />
                                            <label htmlFor="" className="form-label me-2">
                                                Mensagem Personalizada?
                                            </label>
                                            <Switch
                                                checked={usaMensagemPersonalizada}
                                                onChange={() => setUsaMensagemPersonalizada(!usaMensagemPersonalizada)}
                                                disabled={acao === "Visualizar" ? true : false}
                                            />
                                            <h6 hidden={!usaMensagemPersonalizada} className="mt-0">Ativar esta opção permitirá a escolha de uma mensagem personalizada para os tickets.</h6>
                                        </Col>
                                    </Row>
                                    <Card className="mt-2">
                                        <Row className="p-3" hidden={!usaMensagemPersonalizada}>
                                            <h6>Escolha uma mensagem personalizada para enviar em seus e-mails.</h6>
                                            <ReactQuill
                                                id="editor"
                                                theme="snow"
                                                readOnly={acao === "Visualizar" ? true : false}
                                                value={mensagemPersonalizada}
                                                modules={{ toolbar: false }}
                                                onChange={(value) => setMensagemPersonalizada(value)}
                                            />
                                        </Row>
                                    </Card>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Card.Body>
                    <Card.Footer>
                        <div className="d-flex my-2 gap-1 justify-content-between">
                            <ButtonCustom
                                invisivel={false}
                                type="button"
                                className="btn btn-light text-danger bg-white border-0"
                                descricaoBotao="Voltar"
                                icone={mdiKeyboardReturn}
                                onclick={() => {
                                    navigate(-1);
                                }}
                            />
                            <ButtonCustom
                                invisivel={acao === "Visualizar" ? true : false}
                                type="submit"
                                className="btn btn-light text-info-emphasis bg-white border-0"
                                descricaoBotao="Salvar"
                                icone={mdiCheckCircle}
                            />
                        </div>
                    </Card.Footer>
                </Form>
            </Card >
            <ModalUploadImage
                show={showUpload}
                label="Realizando Upload da Imagem ..."
            />
            <ModalLoading show={loading} label="Carregado dados do cliente..." />
        </>
    );
};
export default FormCliente;
