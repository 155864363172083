import { Card, Col, Row } from "react-bootstrap"
import AddIcon from "@mui/icons-material/Add";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo"
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading"
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material"
import ClickableTable from "../../../components/ClickableTable/ClickableTabl"
import { useEffect, useState } from "react"
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface"
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { getSessionData } from "../../../utils/storageUtils";
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";

function Formularios() {
    const [dados, setDados] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate()
    const token = getSessionData('MultfilialWebToken') || "";

    function teste() {
    }

    function irParaIncluir() {
        navigate(`formulariosIncluir`)
    }

    interface Action {
        icon: JSX.Element;
        name: string;
    }
    const actions: Action[] = [
        {
            icon: (
                <AddIcon
                    onClick={irParaIncluir}
                />
            ),
            name: "Incluir",
        },
        // { icon: <FileCopyIcon />, name: "Duplicar" },
        // { icon: <SaveIcon />, name: "Salvar" },
        // { icon: <PrintIcon />, name: "Imprimir" },
    ];

    const colunas: ITabela[] = [
        { titulo: "Código do Form", acesso: "id_formulario" },
        { titulo: "Sistema", acesso: "sistema" },
        { titulo: "Categoria", acesso: "categoria" },
        { titulo: "Data de Criação", acesso: "dt_criacao" }
    ];

    //verifica permissão --- inicio
    const [permissao_visualizar, set_permissao_visualizar] = useState(true)
    const [permissao_incluir, set_permissao_incluir] = useState(true)
    const [permissao_editar, set_permissao_editar] = useState(true)
    const [permissao_excluir, set_permissao_excluir] = useState(true)

    function excluirForm(obj: any) {
        const idFilial = getSessionData("DadosIdfilial")

        showConfirmationDialog('Excluir formulário?',
            'Esta ação irá excluir o formulário selecionado', "warning", 0).then(function (confirmed) {
                if (confirmed.confirmed) {
                    setLoading(true)
                    axios.delete(`${process.env.REACT_APP_API_BASE_URL}/excluir/formulario/${obj.id_formulario}/${idFilial}`, {
                        headers: { Authorization: token } // Adiciona o token de autorização no cabeçalho da requisição
                    }).then(function (resposta) {
                        toast.success(resposta.data.message)
                        buscarFormularios()
                        setLoading(false)
                    }).catch(function (erro) {
                        setLoading(false)
                        const status = erro.response.status
                        if (status == 403 || status == 401) {
                            toast.error("Acesso negado, faça seu login novamente")
                        }
                    })
                }
            })
    }

    function buscarFormularios() {
        const idFilial = getSessionData("DadosIdfilial")

        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/formularios/${idFilial}`, {
            headers: { Authorization: token } // Adiciona o token de autorização no cabeçalho da requisição
        }).then(function (resposta) {
            setDados(resposta.data.data)
            setLoading(false)
        }).catch(function (erro) {
            setLoading(false)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    function irParaVisualizar(obj: any){
        navigate(`formulariosVisualizar/${obj.id_formulario}`)
    }

    function irParaEditar(obj: any){
        navigate(`formulariosEditar/${obj.id_formulario}`)
    }

    useEffect(() => {
        buscarFormularios()
    }, [])

    return (
        <>
            <div className="col">
                <ContainerTitulo titulo="Listagem de Formulários" />

                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <SpeedDial
                                        hidden={false}
                                        ariaLabel="SpeedDial basic example"
                                        direction="left"
                                        sx={{
                                            right: 0,
                                            position: "absolute",
                                        }}
                                        icon={<SpeedDialIcon />}
                                    >
                                        {actions.map((action) => (
                                            <SpeedDialAction
                                                key={action.name}
                                                icon={action.icon}
                                                tooltipTitle={action.name}
                                            />
                                        ))}
                                    </SpeedDial>
                                </Row>
                                <Row>
                                    <ClickableTable
                                        coluna={colunas}
                                        data={dados}
                                        usaRowClick={false}
                                        onRowClick={function () {
                                        }}
                                        itemsPerPage={10}
                                        acaoVisualizar={irParaVisualizar}
                                        acaoEditar={irParaEditar}
                                        acaoExcluir={excluirForm}
                                        usaAcoes={true}
                                        usaEditar={permissao_editar}
                                        usaVisualizar={permissao_visualizar}
                                        usaExcluir={permissao_excluir}
                                        labelpesquisa="Procurar por sistema"
                                        acessopesquisa="sistema"
                                    />
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
            <ModalLoading show={loading} label="Carregando Listagem de Formulários" />
        </>
    )
}

export default Formularios