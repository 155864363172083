import { Card, Col, Row } from "react-bootstrap"
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo"
import { Button } from "../../../components/Formularios/Buttons/Button"
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material"
import RefreshIcon from '@mui/icons-material/Refresh';
import axios from "axios";
import { getSessionData } from "../../../utils/storageUtils";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import ReactQuill from 'react-quill';
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import { mdiSendCircle } from "@mdi/js";
import VisualizarAnexos from "../../../components/visualizarAnexos/visualizarAnexos";
import { useNavigate } from "react-router-dom";
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";
import VisualizarCamposDinamicos from "../../../components/visualizarCamposDinamicos/visualizarCamposDinamicos";

function DetalhesTicketUsuario() {
    const token = getSessionData('MultfilialWebToken') || "";
    const [loading, setLoading] = useState<boolean>(false);
    const [dados, setDados] = useState([])
    const [tituloTicket, setTituloTicket] = useState("")
    const [idTicket, setIdTicket] = useState("")
    const idFilial = getSessionData("DadosIdfilial")
    const [statusTicket, setStatusTicket] = useState("")
    const [categoriaTicket, setCategoriaTicket] = useState("")
    const [interacaoTicket, setInteracaoTicket] = useState("")
    const [requerenteTicket, setRequerenteTicket] = useState("")
    const [agenteTicket, setAgenteTicket] = useState([])
    const [telefoneRequerente, setTelefoneRequerente] = useState("")
    const [empresaRequerente, setEmpresaRequerente] = useState("")
    const [emailUsuario, setEmailUsuario] = useState("")
    const { id_ticket } = useParams();
    const params = useParams();
    const navigate = useNavigate()

    const tituloAjustado = "[Ticket #" + idTicket + "] " + tituloTicket; // Ajusta o título do ticket para exibição

    interface Action {
        icon: JSX.Element;
        name: string;
    }

    // Função teste vazia, pode ser usada futuramente
    function teste() {

    }

    const mostraReabrir = statusTicket == 'FECHADO' // Verifica se o ticket está fechado para reabrir

    const actions: Action[] = [
        {
            icon: <RefreshIcon onClick={atualizarTicket} />, // Ícone para atualizar o ticket
            name: "Atualizar",
        },
    ];

    // Adiciona a ação "Reabrir Ticket" se o ticket estiver fechado
    if (mostraReabrir) {
        actions.push({
            icon: <EventRepeatIcon onClick={reabrirTicket} />, // Ícone para reabrir o ticket
            name: "Reabrir Ticket",
        });
    }

    // Atualiza o ticket e busca suas interações
    function atualizarTicket() {
        buscarTicketSelecionado()
        buscarInteracoesTicket()
    }

    // Retorna uma cor diferente baseada no status do ticket
    const mudaCorStatus = (status: string) => {
        switch (status) {
            case 'ABERTO':
                return '#77dd77'; // Verde para aberto
            case 'FECHADO':
                return '#ff6961'; // Vermelho para fechado
            case 'PENDENTE':
                return '#d6d67e'; // Amarelo para pendente
            default:
                return 'transparent'; // Cor padrão
        }
    };

    // Busca os dados do ticket selecionado
    function buscarTicketSelecionado() {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/ticket/selecionado/usuario`, {
            headers: {
                Authorization: token // Envia o token de autorização
            },
            params: {
                id_ticket
            }
        }).then(function (resposta) {
            console.log(resposta.data.data) // Exibe a resposta no console

            // Define os dados do ticket selecionado
            setEmailUsuario(resposta.data.data[0].email)
            setTituloTicket(resposta.data.data[0].titulo)
            setIdTicket(resposta.data.data[0].id_ticket)
            setStatusTicket(resposta.data.data[0].status)
            setCategoriaTicket(resposta.data.data[0].categoria[0])
            setRequerenteTicket(resposta.data.data[0].usuario_criacao)
            setTelefoneRequerente(resposta.data.data[0].telefone)
            setEmpresaRequerente(resposta.data.data[0].empresa)

            // Preenche a lista de agentes que interagiram no ticket
            const listaAgentes: any = []
            for (let i = 0; i < resposta.data.data.length; i++) {
                const mensagem = resposta.data.data[i]
                const agenteEncontrado = listaAgentes.find((agente: any) => agente.agente == mensagem.agente)
                if (agenteEncontrado == undefined && mensagem.agente !== null) {
                    listaAgentes.push({ agente: mensagem.agente })
                }
            }
            setAgenteTicket(listaAgentes)
            setLoading(false);
        }).catch(function (erro) {
            setLoading(false)
            console.log(erro)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    // Função para reabrir o ticket
    function reabrirTicket() {
        showConfirmationDialog('Reabrir ticket?', 'Esta ação irá reabrir o ticket selecionado', "warning", 2).then(function (confirmed) {
            if (confirmed.confirmed) {
                const idFilial = getSessionData("DadosIdfilial")
                const dados = {
                    id_ticket,
                    emailUsuario,
                    tituloAjustado,
                    idFilial
                }

                setLoading(true)
                axios.put(`${process.env.REACT_APP_API_BASE_URL}/reabrir/ticket/usuario`, dados, {
                    headers: {
                        Authorization: token // Envia o token de autorização
                    }
                }).then(function (resposta) {
                    toast.success(resposta.data.message) // Exibe mensagem de sucesso
                    buscarTicketSelecionado() // Atualiza os dados do ticket
                    setLoading(false)
                }).catch(function (erro) {
                    setLoading(false)
                    const status = erro.response.status
                    if (status == 403 || status == 401) {
                        toast.error("Acesso negado, faça seu login novamente")
                    } else {
                        toast.error(erro.response.data.message)
                    }
                })
            }
        })
    }

    // Busca as interações feitas no ticket
    function buscarInteracoesTicket() {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/interacoes/ticket/usuario`, {
            headers: {
                Authorization: token // Envia o token de autorização
            },
            params: {
                id_ticket
            }
        }).then(function (resposta) {
            setDados(resposta.data.data) // Atualiza o estado com as interações recebidas
            setLoading(false)
        }).catch(function (erro) {
            setLoading(false)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    // Insere uma nova interação no ticket
    function inserirNovaInteracao(e: any) {
        e.preventDefault()

        if (interacaoTicket == '') {
            return toast.info("Não é possível inserir nova interação sem conteúdo")
        }

        const idAgente = getSessionData("DadosUsuarioID")
        const nomeAgente = getSessionData("NomeUsuario")
        const idFilial = getSessionData("DadosIdfilial")

        const dados = {
            id_ticket,
            interacaoTicket,
            idAgente,
            nomeAgente,
            idFilial,
            emailUsuario,
            tituloAjustado,
            statusTicket
        }

        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/inserir/nova/interacao/usuario`, dados, {
            headers: {
                Authorization: token // Envia o token de autorização
            }
        }).then(function (resposta) {
            toast.success('Mensagem enviada com sucesso!') // Notifica sucesso
            buscarInteracoesTicket() // Atualiza a lista de interações
            buscarTicketSelecionado() // Atualiza os dados do ticket
            setInteracaoTicket('') // Limpa o campo de interação
            setLoading(false)
        }).catch(function (erro) {
            setLoading(false)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    // Executa a busca inicial de ticket selecionado e interações
    useEffect(() => {
        buscarTicketSelecionado()
        buscarInteracoesTicket()
    }, [])

    // Controla a visualização do modal de anexos
    const [showAnexos, setShowAnexos] = useState(false)
    function visualizarAnexos() {
        setShowAnexos(!showAnexos) // Alterna a exibição do modal de anexos
    }

    // Controle de visualização do modal de campos dinâmicos
    const [showModalCamposDinamicos, setShowModalCamposDinamicos] = useState(false)
    function mostrarModalCamposDinamicos() {
        setShowModalCamposDinamicos(!showModalCamposDinamicos) // Alterna a exibição do modal de campos dinâmicos
    }

    // Lista de campos dinâmicos
    const [listaCamposDinamicos, setListaCamposDinamicos] = useState([])

    function carregarCamposDinamicosTicket() {
        setLoading(true) // Simula carregamento de dados de campos dinâmicos
    }

    return (
        <>
            <div className="container-fluid">
                <ContainerTitulo titulo="Detalhes do Ticket" />
                <form onSubmit={inserirNovaInteracao}>
                    <Card className="p-4" style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                        <Card className="mb-3 p-3 border shadow">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm col-md-2 col-lg-4">
                                        <ContainerTitulo titulo={tituloAjustado} />
                                    </div>
                                    <div className="col-sm col-md-2 col-lg-4">
                                        <SpeedDial
                                            //hidden={permissao_incluir}
                                            ariaLabel="SpeedDial basic example"
                                            direction="left"
                                            sx={{
                                                right: 35,
                                                top: 15,
                                                position: "absolute",
                                            }}
                                            icon={<SpeedDialIcon />}
                                        >
                                            {actions.map((action) => (
                                                <SpeedDialAction
                                                    key={action.name}
                                                    icon={action.icon}
                                                    tooltipTitle={action.name}
                                                />
                                            ))}
                                        </SpeedDial>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm col-md-2 col-lg-4">
                                        <div style={{ marginBottom: '10px' }}>
                                            <label htmlFor="editor" style={{
                                                color: '#1E90FF',
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                                fontSize: '16px'
                                            }}
                                                onClick={mostrarModalCamposDinamicos}
                                            >
                                                Visualizar mais detalhes do ticket
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm col-md-2 col-lg-8">
                                        <ReactQuill
                                            id="editor" // Adiciona um id para associar com o label
                                            theme="snow"
                                            value={interacaoTicket}
                                            onChange={setInteracaoTicket}
                                            style={{ height: '130px', width: '100%' }}
                                        />
                                    </div>
                                    <div className="col-sm col-md-2 col-lg-4">
                                        <Card className="mb-2 border shadow" style={{ height: '172px' }}>
                                            <Card.Header
                                                style={{
                                                    position: 'sticky',
                                                    top: 0,
                                                    backgroundColor: '#f8f9fa',
                                                    zIndex: 1
                                                }}
                                            >
                                                Status
                                            </Card.Header>
                                            <Card.Body>
                                                <p style={{
                                                    backgroundColor: mudaCorStatus(statusTicket),
                                                    color: 'white',
                                                    padding: '5px',
                                                    borderRadius: '5px',
                                                    textAlign: 'center',
                                                    margin: '0 auto',
                                                    fontSize: '0.650rem',
                                                    maxWidth: 'fit-content',
                                                }}>{statusTicket} </p>
                                                <p className="mt-2" style={{
                                                    padding: '5px',
                                                    borderRadius: '5px',
                                                    textAlign: 'center',
                                                    margin: '0 auto',
                                                    fontSize: '0.700rem',
                                                    maxWidth: 'fit-content',
                                                    fontWeight: 'bold',
                                                }}> Categoria: {categoriaTicket} </p>
                                                <div hidden={statusTicket !== 'FECHADO'}
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>
                                                    <label style={{
                                                        padding: '5px',
                                                        borderRadius: '5px',
                                                        textAlign: 'center',
                                                        margin: 'auto',
                                                        color: '#1E90FF',
                                                        textDecoration: 'underline',
                                                        cursor: 'pointer',
                                                        fontSize: '13px',
                                                    }}
                                                        onClick={function () {
                                                            navigate(`/tickets/satisfacao/pesquisaSatisfacao/${idTicket}/${idFilial}`)
                                                        }}>Acessar pesquisa de satisfação</label>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm col-md-2 col-lg-4">
                                        <div className="form-group w-50">
                                            <input
                                                multiple
                                                hidden={statusTicket == 'FECHADO' ? true : false}
                                                type="file"
                                                className="form-control-file d-none"
                                                id="fileInput"
                                                onChange={teste}
                                            />
                                            <label htmlFor="fileInput" className="mt-1" hidden={statusTicket == 'FECHADO' ? true : false} style={{
                                                color: '#1E90FF',
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                                marginLeft: '0.5rem',
                                                fontSize: '16px'
                                            }}>Anexar arquivos</label>
                                            <label className="mt-1" style={{
                                                color: '#1E90FF',
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                                marginLeft: '0.5rem',
                                                fontSize: '16px'
                                            }}
                                                onClick={visualizarAnexos}>Visualizar Anexos</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md-2 col-lg-4">
                                        <div className="d-flex justify-content-end mb-3">
                                            <Button
                                                invisivel={statusTicket == 'FECHADO' ? true : false}
                                                type="submit"
                                                className="btn btn-dark text-info-emphasis bg-primary border-0 rounded"
                                                descricaoBotao="Enviar Resposta"
                                                icone={mdiSendCircle}
                                                onclick={function () {
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm col-md-2 col-lg-4">
                                        <Card className="mb-2 border shadow" style={{ height: '180px' }}>
                                            <Card.Header
                                                style={{
                                                    position: 'sticky',
                                                    top: 0,
                                                    backgroundColor: '#f8f9fa',
                                                    zIndex: 1
                                                }}
                                            >
                                                Requerente
                                            </Card.Header>
                                            <Card.Body>
                                                <p style={{
                                                    backgroundColor: 'grey',
                                                    color: 'white',
                                                    padding: '5px',
                                                    borderRadius: '5px',
                                                    fontSize: '0.700rem',
                                                    maxWidth: 'fit-content',
                                                }}>{requerenteTicket} </p>
                                                <p style={{
                                                    fontSize: '0.700rem',
                                                    maxWidth: 'fit-content',
                                                }}>Telefone: {telefoneRequerente}</p>
                                                <p style={{
                                                    fontSize: '0.700rem',
                                                    maxWidth: 'fit-content',
                                                }}>Empresa: {empresaRequerente}</p>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm col-md-2 col-lg-8">
                                        {dados.map(function (dado: any, index: number) {
                                            if (index == 0) {
                                                return (
                                                    <Card className="mb-2 border shadow" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                                        padding: '15px',
                                                        marginBottom: '10px',
                                                        maxWidth: '600px',
                                                        margin: 'auto',
                                                        backgroundColor: '#fff',
                                                    }}>
                                                        <Card.Body style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'space-between',
                                                        }}>
                                                            {/* Cabeçalho com nome e horário */}
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                marginBottom: '10px',
                                                            }}>
                                                                <strong style={{
                                                                    fontSize: '16px',
                                                                    fontWeight: 'bold',
                                                                    color: '#333',
                                                                }}>
                                                                    {dado.usuario || dado.agente}
                                                                </strong>
                                                                <span style={{
                                                                    fontSize: '12px',
                                                                    color: '#888',
                                                                }}>
                                                                    {dado.horario_interacao}
                                                                </span>
                                                            </div>

                                                            {/* Email do usuário */}
                                                            <p style={{
                                                                fontSize: '14px',
                                                                color: '#888',
                                                                marginBottom: '15px',
                                                            }}>
                                                                {dado.email}
                                                            </p>

                                                            {/* Mensagem */}
                                                            <ReactQuill
                                                                id="editor" // Adiciona um id para associar com o label
                                                                theme="snow"
                                                                readOnly
                                                                value={dado.mensagem}
                                                                modules={{ toolbar: false }}
                                                                style={{
                                                                    borderRadius: '10px',
                                                                    overflow: 'hidden',
                                                                    border: '1px solid #888',
                                                                }}
                                                            />
                                                        </Card.Body>
                                                    </Card>
                                                )
                                            }
                                        })}
                                    </div>
                                    <div className="col-sm col-md-2 col-lg-4">
                                        <Card className="mb-2 border shadow" style={{ height: '195px' }}>
                                            <Card.Header
                                                style={{
                                                    position: 'sticky',
                                                    top: 0,
                                                    backgroundColor: '#f8f9fa',
                                                    zIndex: 1
                                                }}
                                            >
                                                Agente(s) atribuido(s)
                                            </Card.Header>
                                            <Card.Body>
                                                {agenteTicket.map(function (agente: any) {
                                                    return <p style={{
                                                        backgroundColor: 'grey',
                                                        color: 'white',
                                                        padding: '5px',
                                                        borderRadius: '5px',
                                                        textAlign: 'center',
                                                        margin: '0 auto',
                                                        fontSize: '0.700rem',
                                                        maxWidth: 'fit-content',
                                                        marginBottom: '5px'
                                                    }}>{agente.agente} </p>
                                                })}
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm col-md-2 col-lg-8">
                                        {dados.map(function (dado: any, index: number) {
                                            if (index >= 1) {
                                                return (
                                                    <Card className="mb-2 border shadow" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                                        padding: '15px',
                                                        marginBottom: '10px',
                                                        maxWidth: '600px',
                                                        margin: 'auto',
                                                        backgroundColor: '#fff',
                                                    }}>
                                                        <Card.Body style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'space-between',
                                                        }}>
                                                            {/* Cabeçalho com nome e horário */}
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                marginBottom: '10px',
                                                            }}>
                                                                <strong style={{
                                                                    fontSize: '16px',
                                                                    fontWeight: 'bold',
                                                                    color: '#333',
                                                                }}>
                                                                    {dado.usuario || dado.agente}
                                                                </strong>
                                                                <span style={{
                                                                    fontSize: '12px',
                                                                    color: '#888',
                                                                }}>
                                                                    {dado.horario_interacao}
                                                                </span>
                                                            </div>

                                                            {/* Email do usuário */}
                                                            <p style={{
                                                                fontSize: '14px',
                                                                color: '#888',
                                                                marginBottom: '15px',
                                                            }}>
                                                                {dado.email}
                                                            </p>

                                                            {/* Mensagem */}
                                                            <ReactQuill
                                                                id="editor" // Adiciona um id para associar com o label
                                                                theme="snow"
                                                                readOnly
                                                                value={dado.mensagem}
                                                                modules={{ toolbar: false }}
                                                                style={{
                                                                    borderRadius: '10px',
                                                                    overflow: 'hidden',
                                                                    border: '1px solid #888',
                                                                }}
                                                            />
                                                        </Card.Body>
                                                    </Card>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Card>
                </form>
                <ModalLoading show={loading} label="Carregando..." />
                <VisualizarAnexos
                    id_ticket={parseInt(params.id_ticket!)}
                    mostrar={showAnexos}
                    fecharModal={visualizarAnexos}
                />
                <VisualizarCamposDinamicos
                    idTicket={params.id_ticket!}
                    fecharClick={mostrarModalCamposDinamicos}
                    show={showModalCamposDinamicos}
                />
            </div>
        </>
    )
}

export default DetalhesTicketUsuario