import Logo from "../assets/img/logo_sm.png";

const FooterBar = (): JSX.Element => {
  return (


    <>
      <br />
      <br />
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              {new Date().getFullYear()}© FKL Tecnologi@<img src={Logo} alt="" style={{ height: "14px", marginLeft: "5px" }} /> - v1.0.0
            </div>
          </div>
        </div>
      </footer>
    </>

  );
};
export { FooterBar };
