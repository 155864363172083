import { Card } from "react-bootstrap"
import { useEffect, useState } from "react";
import { Button } from "../../../components/Formularios/Buttons/Button";
import { mdiSendCircleOutline } from "@mdi/js";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { getSessionData } from "../../../utils/storageUtils";

function PesquisaSatisfacao() {
    const [opcaoSelecionada, setOpcaoSelecionada] = useState('');
    const [comentario, setComentario] = useState('');
    const [visualiza, setVisualiza] = useState<boolean>(false);
    const location = useLocation()
    const token = getSessionData("MultfilialWebToken") ? getSessionData("MultfilialWebToken") : (new URLSearchParams(location.search)).get('token');
    const { id_ticket, id_filial } = useParams();

    //Função que altera a opção selecionada
    function mudarOpcao(e: React.ChangeEvent<HTMLInputElement>) {
        setOpcaoSelecionada(e.target.value)
    }

    // Função que enviará a opção com o check e o comentário
    function enviarAvaliacao(e: any) {
        e.preventDefault()

        const dados = {
            opcaoSelecionada,
            comentario,
            id_filial,
            id_ticket
        }
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/enviar/avaliacao`, dados, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            buscarAvaliacao()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    //Função que irá buscar a avaliação já inserida no cabeçalho do ticket
    function buscarAvaliacao() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/avaliacao/${id_ticket}/${id_filial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            if (resposta.data.data.avaliacao !== null || resposta.data.data.comentario !== null) {
                setOpcaoSelecionada(resposta.data.data.avaliacao)
                setComentario(resposta.data.data.comentario)
                setVisualiza(true)
            } else {
                setVisualiza(false)
            }
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    //Controla uma mensagem solicitando um feedback caso a nota for 1 ou 2
    useEffect(function () {
        if (opcaoSelecionada == '1' || opcaoSelecionada == '2') {
            toast.info('Nos dê um feedback mencionando o motivo da sua experiência conosco não ter sido agradável')
        }
    }, [opcaoSelecionada])

    useEffect(function () {
        buscarAvaliacao()
    }, [])

    return (
        <>
            <div className="container-fluid" >
                <form onSubmit={enviarAvaliacao}>
                    <Card className="p-2 mt-2 pb-5">
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-sm col-md-2 col-lg-5">
                                    <h3>Você está satisfeito com o atendimento que obteve ou restou alguma dúvida?</h3>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-sm col-md-2 col-lg-5">
                                    <input disabled={visualiza} className="form-check-input mt-1" type="checkbox" value="5" checked={opcaoSelecionada == '5'} onChange={mudarOpcao} />
                                    <label className="form-check-label mt-1 ms-2">
                                        Extremamente satisfeito(a)
                                    </label>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-sm col-md-2 col-lg-5">
                                    <input disabled={visualiza} className="form-check-input mt-1" type="checkbox" value="4" checked={opcaoSelecionada == '4'} onChange={mudarOpcao} />
                                    <label className="form-check-label mt-1 ms-2">
                                        Satisfeito(a)
                                    </label>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-sm col-md-2 col-lg-5">
                                    <input disabled={visualiza} className="form-check-input mt-1" type="checkbox" value="3" checked={opcaoSelecionada == '3'} onChange={mudarOpcao} />
                                    <label className="form-check-label mt-1 ms-2">
                                        Nem satisfeito(a), nem insatisfeito(a)
                                    </label>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-sm col-md-2 col-lg-5">
                                    <input disabled={visualiza} className="form-check-input mt-1" type="checkbox" value="2" checked={opcaoSelecionada == '2'} onChange={mudarOpcao} />
                                    <label className="form-check-label mt-1 ms-2">
                                        Pouco satisfeito(a)
                                    </label>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-sm col-md-2 col-lg-5">
                                    <input disabled={visualiza} className="form-check-input mt-1" type="checkbox" value="1" checked={opcaoSelecionada == '1'} onChange={mudarOpcao} />
                                    <label className="form-check-label mt-1 ms-2">
                                        Completamente insatisfeito(a)
                                    </label>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-sm col-md-2 col-lg-5">
                                    <input disabled={visualiza} required={opcaoSelecionada == '1' || opcaoSelecionada == '2'} className="mt-1" type="input" placeholder="O que te motivou a dar esta nota?" value={comentario} onChange={(e) => setComentario(e.target.value)} />
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-sm col-md-2 col-lg-5">
                                    <div className="text-end mt-2">
                                        <Button
                                            disabled={opcaoSelecionada == ''}
                                            invisivel={visualiza}
                                            className="btn btn-dark text-info-emphasis bg-primary border-0 rounded"
                                            descricaoBotao="Enviar"
                                            icone={mdiSendCircleOutline}
                                            type='submit'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </form>
            </div>
        </>
    )
}

export default PesquisaSatisfacao