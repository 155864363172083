import { useParams } from "react-router-dom";
import ContainerTitulo from "../../../../components/Formularios/Container/ContainerTitulo";
import Formfilial from "./components/formulario";

export function FilialIncluir() {
  const idfilial = useParams()
  return (
    <>
      <div className="col mt-2">
        <ContainerTitulo titulo="Incluir Filial" />
        <Formfilial idfilial={idfilial.id} acao="Novo" />
      </div>
    </>
  )
}
