import React, { createContext, useState, useContext } from 'react';

export interface Mensagem {
    id: string; // UUID único para cada mensagem
    nome: string;
    mensagem: string;
    tipoUsuario: string; // 'Cliente' ou 'Suporte'
}

interface ConversaContextType {
    idDaConversa: string | null;
    setIdDaConversa: (id: string | null) => void;
    minimizado: boolean;
    setMinimizado: (valor: boolean) => void;
    mensagens: Mensagem[]
    setMensagens: React.Dispatch<React.SetStateAction<Mensagem[]>>;
    tipoUsuario: string;
    setTipoUsuario: (user: string) => void
    adicionarMensagem: (mensagem: Mensagem) => void; 
}

const ConversaContext = createContext<ConversaContextType | undefined>(undefined);

export const ConversaProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [idDaConversa, setIdDaConversa] = useState<string | null>(null);
    const [minimizado, setMinimizado] = useState<boolean>(false);
    const [mensagens, setMensagens] = useState<Mensagem[]>([]);
    const [tipoUsuario, setTipoUsuario] = useState<string>('Cliente');

    // Função para adicionar mensagem sem duplicação
    const adicionarMensagem = (novaMensagem: Mensagem) => {
        setMensagens((prevMensagens) => {
            // Verifica se a mensagem com o mesmo conteúdo e tipo de usuário já existe
            const existeMensagem = prevMensagens.some(
                (msg) =>
                    msg.mensagem === novaMensagem.mensagem &&
                    msg.tipoUsuario === novaMensagem.tipoUsuario
            );

            // Adiciona a nova mensagem apenas se ela não existir
            if (!existeMensagem) {
                return [...prevMensagens, novaMensagem];
            }

            // Caso exista, mantém o histórico intacto
            return prevMensagens;
        });
    };



    return (
        <ConversaContext.Provider value={{
            idDaConversa,
            setIdDaConversa,
            minimizado,
            setMinimizado,
            mensagens,
            setMensagens,
            tipoUsuario,
            setTipoUsuario,
            adicionarMensagem, // Expondo a função no contexto
        }}>
            {children}
        </ConversaContext.Provider>
    );
};

export const useConversa = () => {
    const context = useContext(ConversaContext);
    if (!context) {
        throw new Error('useConversa deve ser usado dentro de um ConversaProvider');
    }
    return context;
};
