import { useParams } from "react-router-dom";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import FormEmpresa from "./components/formulario";

export function EmpresaIncluir() {
  const idempresa = useParams();
  return (
    <>
      <div className="col mt-2">
        <ContainerTitulo titulo="Incluir Empresa" />
        <FormEmpresa idempresa={idempresa.id} acao="Novo" />
      </div>
    </>
  );
}
