import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda";
import {
    Switch,
    Avatar,
    Button,
    IconButton,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Box,
    Tab,
    Hidden,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { Button as ButtonCustom } from "../../../../components/Formularios/Buttons/Button";
import {
    mdiCheckAll,
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiPlus,
    mdiBookArrowDown
} from "@mdi/js";
import { useNavigate } from "react-router-dom";
import ModalUploadImage from "../../../../components/Formularios/Modal/ModalUpload";
import { toast } from "react-toastify";
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading";
import ConverterBase64 from "../../../../components/ConversorBase64";
import { getSessionData } from "../../../../utils/storageUtils";
import ValidaComplexidadeSenha from "../../../../components/Formularios/ValidaComplexidadeSenha";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface";
import Tabela from "../../../../components/Formularios/Table";
import { showConfirmationDialog } from "../../../../components/Formularios/Modal/ModalExcluir";
import axios from "axios";
import ApiPost from "../../../../api/endPoints/usePost";
import ApiGet from "../../../../api/endPoints/useGet";
import ApiDelete from "../../../../api/endPoints/useDelete";
import CarregarDadosEmpresa from "../../../administrativo/usuario/components/CarregarEmpresa";
import { url_CarregarDados_Empresa } from "../../../administrativo/usuario/components/auxiliar";
import ClickableTable from "../../../../components/ClickableTable/ClickableTabl";

interface FormUsuarioProps {
    idusuario?: string;
    acao?: string;
}

const FormAgente: React.FC<FormUsuarioProps> = ({ idusuario, acao }) => {
    const token = getSessionData("MultfilialWebToken") || "";
    const isSuper = getSessionData("isPerfilSuper") || "";

    const isVinculo = getSessionData("isPerfilVinculo") || "";

    // const InformaVinculo = isSuper === "1" ? true : false;

    //#region Controla as guias
    const [guia, setGuia] = useState("1");

    const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
        setGuia(novaGuia);
    };
    //#endregion

    const navigate = useNavigate();
    const [nome, setNome] = useState<string>("");
    const [login, setLogin] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [senha, setSenha] = useState<string>("");
    const [confirmSenha, setConfirmSenha] = useState<string>("");
    const [telefone, setTelefone] = useState<string>("");
    const [avatar, setAvatar] = useState<string>("");
    const [ativo, setAtivo] = useState<boolean>(true);
    const [perfil, setPerfil] = useState<string>("");
    const [empresaVinculada, setEmpresaVinculada] = useState<string>("");
    const [abrirModalListarClientes, setAbrirModalListarClientes] = useState<boolean>(false);

    const senhaRef = useRef<HTMLInputElement>(null);

    const [dadosperfil, setDadosPerfil] = useState<any[]>([]);

    //#region REALIZA REQUISIÇÃO PARA EXIBIR OS DADOS DO USUÁRIO
    const carregarPerfil = async (idusuario: any) => {
        const id_filial = getSessionData("DadosIdfilial")
        const headers = {
            Authorization: token,
        };
        const dados = {
            url: `/perfil/listar/${isSuper}/${id_filial}`,
            parametros: {},
            headers: headers,
        };
        ApiGet(dados)
            .then((retorno) => {
                setDadosPerfil(retorno);
            })
            .catch((erro) => {
                toast.error(`Erro ao carregar registro. Motivo: ${erro.message}`);
            });
    };
    useEffect(() => {
        carregarPerfil(idusuario);
    }, []);
    //#endregion

    //#region REALIZA REQUISIÇÃO PARA EXIBIR OS DADOS DO USUÁRIO
    const [loading, setLoading] = useState<boolean>(false);

    const carregarDados = async (idusuario: any) => {
        const id_filial = getSessionData("DadosIdfilial")

        setLoading(true);
        const headers = {
            Authorization: token,
        };
        const dados = {
            url: `/carregar/agente/${idusuario}/${id_filial}`,
            parametros: {},
            headers: headers,
        };
        ApiGet(dados)
            .then((retorno) => {
                setNome(retorno[0].nome);
                setLogin(retorno[0].nome_usuario);
                setEmail(retorno[0].email);
                setSenha(retorno[0].senha);
                setConfirmSenha(retorno[0].senha);
                setTelefone(retorno[0].telefone);
                if (retorno[0].avatar !== null) {
                    setAvatar(retorno[0].avatar);
                }
                if (retorno[0].situacao === 1) {
                    setAtivo(true);
                } else {
                    setAtivo(false);
                }
                setPerfil(retorno[0].id_perfil);
                setEmpresaVinculada(retorno[0].vinculo);
                setDadosClientes(retorno[0].filial);
                setLoading(false);
            })
            .catch((erro) => {
                setLoading(false);
                toast.error(`Erro ao carregar dados. Motivo: ${erro.message}`);
            });
    };
    useEffect(() => {
        if (idusuario !== "novo") {
            carregarDados(idusuario);
        }
    }, [idusuario]);
    //#endregion

    //#region Dados para vincular o cliente do agente
    const colunas: ITabela[] = [
        { titulo: "Código", acesso: "id_cliente" },
        { titulo: "Cliente", acesso: "fantasia" },
        { titulo: "CNPJ", acesso: "cnpj" },
        { titulo: "Responsável", acesso: "contato" },
        { titulo: "Contato", acesso: "telefone" },
        { titulo: "E-mail", acesso: "email" },
    ];

    const [dadosClientes, setDadosClientes] = useState<any[]>([]);
    
    const selecionarCliente = (dados: any) => {
        const id_filial = getSessionData("DadosIdfilial")

        if (acao === "Novo") {
            const clienteSelecionado = dadosClientes.some(
                (item: any) => item.id_cliente === dados.id_cliente
            );

            if (!clienteSelecionado) {
                setDadosClientes([...dadosClientes, dados]);
                setAbrirModalListarClientes(false);
            } else {
                toast.error("Cliente já vinculado ao agente.");
            }
        } else if (acao === "Editar" || "Visualizar") {
            const clienteSelecionado = dadosClientes.some(
                (item: any) => item.id_cliente === dados.id_cliente
            );

            if (!clienteSelecionado) {
                const dadosClientes = {
                    id_usuario: idusuario,
                    id_cliente: dados.id_cliente,
                    id_filial: id_filial
                };
                //  ApiPost("/usuario/gravar", dados, token)
                ApiPost("/gravar/agente/cliente", dadosClientes, token)
                    .then((retorno) => {
                        toast.success(retorno.message);
                        carregarDados(idusuario);
                        listarClientes()
                        setAbrirModalListarClientes(false);
                    })
                    .catch((erro) => {
                        toast.error(
                            `Erro ao incluir registro. Motivo: ${erro.response.data.message}`
                        );
                    });
            } else {
                toast.error("Cliente já vinculado ao agente.");
            }
        }
    };
    //#endregion

    //#region LISTAR CLIENTE PARA VINCULAR AO AGENTE
    const colunasListarClientes: ITabela[] = [

        { titulo: "Id", acesso: "id_cliente" },
        { titulo: "CNPJ", acesso: "cnpj" },
        { titulo: "Cliente", acesso: "fantasia" }
    ]

    const [dadosListarClientes, setDadosListarClientes] = useState([]);

    function listarClientes() {
        const id_filial = getSessionData("DadosIdfilial")

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/clientes/agente/${idusuario}/${id_filial}`, {
            headers: { Authorization: token }
        }).then(function (resposta) {
            setDadosListarClientes(resposta.data.data)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }
    useEffect(function(){
        listarClientes()
    }, [])
    //#endregion

    const abrirModalClientes = () => setAbrirModalListarClientes(true);
    const fecharModalClientes = () => setAbrirModalListarClientes(false);

    //#region Listar Empresa Contratante
    const [empresa, setEmpresa] = useState<any[]>([]);

    const listarEmpresa = async () => {
        CarregarDadosEmpresa(url_CarregarDados_Empresa)
            .then((retorno) => {
                setEmpresa(retorno);
            })
            .catch((erro) => {
                const msg = erro.response?.data.message;
                toast.error(`Erro ao listar dados empresa. Motivo: ${msg}`);
            });
    };
    useEffect(() => {
        listarEmpresa();
    }, []);
    //#endregion

    //#region Excluir Cliente do Agente
    const excluirCliente= async (dados: any) => {
        const id_filial = getSessionData("DadosIdfilial")

            await showConfirmationDialog(
                "Desvincular Cliente",
                `Tem certeza que deseja desvincular o Cliente?`,
                "question"
            )
                .then((result) => {
                    if (result.confirmed) {
                        if (acao === "Novo") {
                            const dadosFiltrados = dadosClientes.filter(
                                (item: any) => item.id_cliente !== dados.id_cliente
                            );
                            setDadosClientes(dadosFiltrados);
                        } else if (acao === "Editar" || "Visualizar") {
                            const headers = {
                                Authorization: token,
                            };
                            const dadosClientes = {
                                url: `/remover/agente/cliente/${idusuario}/${dados.id_cliente}/${id_filial}`,
                                parametros: {},
                                headers,
                            };
                            ApiDelete(dadosClientes)
                                .then((retorno) => {
                                    toast.success(retorno.message);
                                    carregarDados(idusuario);
                                    listarClientes()
                                })
                                .catch((erro) => {
                                    toast.error(
                                        `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
                                    );
                                });
                        }
                    }
                })
                .catch((erro) => {
                    toast.error(
                        `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
                    );
                });
    };
    //#endregion


    return (
        <>
            <Card>
                <Form>
                    <Card.Body>
                        <Box sx={{ width: "100%" }}>
                            <TabContext value={guia}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <TabList
                                        onChange={capturaGuia}
                                        TabIndicatorProps={{
                                            style: {
                                                backgroundColor: "#000",
                                                color: "#000",
                                            },
                                        }}
                                    >
                                        <Tab label="Dados Básicos" value="1" />
                                        <Tab label="Clientes Vinculados" value="2" />
                                    </TabList>
                                </Box>

                                <TabPanel value="1">
                                    <Row>
                                        <Col sm={7}>
                                            <Row>
                                                <Col sm={4}>
                                                    <InputSemBorda
                                                        name="codigo"
                                                        label="Código Usuário"
                                                        type="text"
                                                        placeholder="Código Usuário"
                                                        value={idusuario || ""}
                                                        onChange={() => { }}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                                        readonly
                                                    />
                                                </Col>
                                                <Col sm={6}>
                                                    <InputSemBorda
                                                        name="nome"
                                                        label="Nome Usuário"
                                                        type="text"
                                                        placeholder="Nome"
                                                        value={nome || ""}
                                                        onChange={setNome}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput text-capitalize text-black"
                                                        required={true}
                                                        readonly={acao === "Visualizar" ? true : false}
                                                    />
                                                </Col>
                                                <Col sm={2}>
                                                    <br />
                                                    <label htmlFor="" className="form-label me-2">
                                                        Ativo
                                                    </label>
                                                    <Switch
                                                        checked={ativo}
                                                        onChange={() => setAtivo(!ativo)}
                                                        disabled={acao === "Visualizar" ? true : false}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={5}>
                                                    <InputSemBorda
                                                        name="login"
                                                        label="Login"
                                                        type="text"
                                                        placeholder="Login"
                                                        value={login || ""}
                                                        onChange={setLogin}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput text-lower text-black"
                                                        readonly={
                                                            acao === "Editar" || acao === "Visualizar"
                                                                ? true
                                                                : false
                                                        }
                                                        required={true}
                                                    />
                                                </Col>
                                                <Col sm={7}>
                                                    <InputSemBorda
                                                        name="email"
                                                        label="E-mail"
                                                        type="email"
                                                        placeholder="E-mail"
                                                        value={email || ""}
                                                        onChange={setEmail}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput text-lower text-black"
                                                        readonly={acao === "Visualizar" ? true : false}
                                                        required={true}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={6}>
                                                    <InputSemBorda
                                                        name="senha"
                                                        label="Senha"
                                                        type="password"
                                                        placeholder="Senha"
                                                        value={senha || ""}
                                                        onChange={setSenha}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput text-capitalize text-black"
                                                        readonly={
                                                            acao === "Visualizar" || acao === "Editar"
                                                                ? true
                                                                : false
                                                        }
                                                        useRef={senhaRef}
                                                    />
                                                    {acao === "Novo" && (
                                                        <ValidaComplexidadeSenha senha={senha} />
                                                    )}
                                                </Col>
                                                <Col sm={6}>
                                                    <InputSemBorda
                                                        name="confirmsenha"
                                                        label="Confirmar Senha"
                                                        type="password"
                                                        placeholder="Confirmar Senha"
                                                        value={confirmSenha || ""}
                                                        onChange={setConfirmSenha}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput text-capitalize text-black"
                                                        readonly={
                                                            acao === "Visualizar" || acao === "Editar"
                                                                ? true
                                                                : false
                                                        }
                                                        required={true}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={6}>
                                                    <InputSemBorda
                                                        name="fone"
                                                        label="Telefone"
                                                        type="tel"
                                                        placeholder="Telefone"
                                                        value={telefone || ""}
                                                        onChange={setTelefone}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput text-capitalize text-black"
                                                        readonly={acao === "Visualizar" ? true : false}
                                                    />
                                                </Col>
                                                <Col sm={6}>
                                                    <FormControl
                                                        variant="standard"
                                                        sx={{ m: 1.5, width: "100%" }}
                                                    >
                                                        <InputLabel id="demo-simple-select-standard-label">
                                                            Perfil
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={perfil}
                                                            onChange={() => {}}
                                                            label="Perfil"
                                                            required
                                                            disabled={acao === "Visualizar" ? true : false}
                                                        >
                                                            <MenuItem value="">
                                                                <em>Selecione...</em>
                                                            </MenuItem>

                                                            {dadosperfil.map(function (item: any) {

                                                                const NivelPerfilUser = getSessionData("NivelPerfil") || ""

                                                                if (item.nivel >= NivelPerfilUser) {
                                                                    return (
                                                                        <MenuItem
                                                                            value={item.id_perfil}
                                                                            data-master={item.master}
                                                                        >
                                                                            {item.nome_perfil}
                                                                        </MenuItem>
                                                                    )
                                                                }

                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Col>
                                            </Row>
                                            {isSuper === "1" && (
                                                <Row>
                                                    <Col>
                                                        <FormControl
                                                            variant="standard"
                                                            sx={{ m: 1.5, width: "100%" }}
                                                            required
                                                        >
                                                            <InputLabel>Empresa Vinculada</InputLabel>
                                                            <Select
                                                                value={empresaVinculada}
                                                                onChange={(e: SelectChangeEvent) =>
                                                                    setEmpresaVinculada(e.target.value)
                                                                }
                                                                label="Empresa Vinculada"
                                                                disabled={acao === "Visualizar" ? true : false}
                                                            >
                                                                <MenuItem value="">
                                                                    <em>Selecione...</em>
                                                                </MenuItem>
                                                                {empresa.map((item: any) => (
                                                                    <MenuItem value={item.id}>
                                                                        {item.fantasia}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Col>
                                        <Col sm={5}>
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                {avatar ? (
                                                    <Avatar
                                                        src={`data:image/png;base64,${avatar || null}`}
                                                        alt="Avatar"
                                                        sx={{ width: 240, height: 240 }}
                                                    />
                                                ) : (
                                                    <Avatar
                                                        src={""}
                                                        alt="Avatar"
                                                        sx={{ width: 240, height: 240 }}
                                                    />
                                                )}
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    id="avatar-input"
                                                    onChange={() => {}}
                                                    style={{ display: "none" }}
                                                    disabled={acao === "Visualizar" ? true : false}
                                                />
                                            </Stack>
                                        </Col>
                                    </Row>
                                </TabPanel>
                                <TabPanel value="2">
                                    <ButtonCustom
                                        invisivel={false}
                                        type="button"
                                        className="btn btn-light text-info-emphasis bg-white border-0"
                                        descricaoBotao="Incluir"
                                        icone={mdiPlus}
                                        onclick={abrirModalClientes}
                                        disabled={false}
                                    />
                                    <hr />
                                    <Row>
                                        <Tabela
                                            coluna={colunas}
                                            dados={dadosClientes}
                                            itemsPerPage={10}
                                            onVisuClick={() => { }}
                                            onEditClick={() => { }}
                                            onDeleteClick={(item) => excluirCliente(item)}
                                            usaVisu={false}
                                            usaEdit={false}
                                            id="codigo"
                                            permissao_editar={true}
                                            permissao_visualizar={false}
                                            permissao_excluir={false}
                                        />
                                    </Row>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Card.Body>
                    <Card.Footer>
                        <div className="d-flex my-2 gap-1 justify-content-between">
                            <ButtonCustom
                                invisivel={false}
                                type="button"
                                className="btn btn-light text-danger bg-white border-0"
                                descricaoBotao="Voltar"
                                icone={mdiKeyboardReturn}
                                onclick={() => {
                                    navigate(-1);
                                }}
                            />
                            <ButtonCustom
                                invisivel={false}
                                type="button"
                                className="btn btn-light text-info-emphasis bg-white border-0"
                                descricaoBotao="Finalizar"
                                icone={mdiCheckCircle}
                                disabled={false}
                                onclick={() => {
                                    toast.success("Informações salvas com sucesso")
                                    navigate(-1)
                                }}
                            />
                        </div>
                    </Card.Footer>
                </Form>
            </Card>

            {/* MODAL LISTAR FILIAIS PARA VINCULAR AO USUÁRIO */}
            <Modal
                show={abrirModalListarClientes}
                className="modal-full-width "
                size="xl"
                keyboard={false}
                centered
            >
                <Modal.Header className="bg-white">
                    <Modal.Title>Listagem de Clientes</Modal.Title>
                </Modal.Header>
                <Form //onSubmit={handleSubmit}
                >
                    <Modal.Body className="bg-white">
                        <Row>
                            <ClickableTable 
                            coluna={colunasListarClientes}
                            data={dadosListarClientes}
                            itemsPerPage={10}
                            usaAcoes={true}
                            usaEditar={true}
                            onRowClick={() => {}}
                            acaoVisualizar={() => {}}
                            acaoEditar={selecionarCliente}
                            acaoExcluir={() => {}}
                            iconeEditar="bi bi-check-lg"
                            corIconeEditar="black"
                            tamanhoIcones="fs-3"
                            labelpesquisa="Filtrar por Cliente"
                            acessopesquisa="fantasia"
                            />
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="bg-white">
                        <div className="d-flex my-2 gap-1 justify-content-between">
                            <ButtonCustom
                                invisivel={false}
                                type="button"
                                className="btn btn-light text-danger bg-white border-0"
                                descricaoBotao="Voltar"
                                icone={mdiKeyboardReturn}
                                onclick={fecharModalClientes}
                            />
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ModalLoading show={loading} label="Carregando dados do usuário ..." />

        </>
    );
};
export default FormAgente;
