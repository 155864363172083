import ClickableTable from "../ClickableTable/ClickableTabl"
import { Modal } from "react-bootstrap"
import { ITabela } from "../Formularios/Table/TabelaInterface"
import { useEffect, useState } from "react"
import ModalLoading from "../Formularios/Modal/ModalLoading"
import axios from "axios"
interface VisualizarAnexos {
    id_ticket: number,
    mostrar: boolean,
    fecharModal(): void
}
function VisualizarAnexos({
    id_ticket,
    mostrar,
    fecharModal
}: VisualizarAnexos) {
    const colunas: ITabela[] = [
        { titulo: "Ticket", acesso: "id_ticket" },
        { titulo: "Arquivo", acesso: "nomeArquivo" },
        { titulo: "Tipo", acesso: "tipoArquivo" },
        { titulo: "Data de Envio", acesso: "dataEnvio_formatado" },
        { titulo: "Usuario", acesso: "usuario" }
    ]
    const [showModalLoading, setShowModalLoading] = useState(false)
    const [dados, setDados] = useState([])
    const token = sessionStorage.getItem("MultfilialWebToken")!
    function CarregarAnexos(id_ticket: number) {
        setShowModalLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/anexos/tickets?id_ticket=${id_ticket}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setShowModalLoading(false)
            setDados(resposta.data.anexos)
        }).catch(function (erro) {
            setShowModalLoading(false)
            console.log(erro)
        })
    }
    function BaixarAnexo(dados: any) {
        try {
            const linkTemporario = document.createElement("a")
            linkTemporario.href = dados.arquivoBase //-> conteudo a ser baixado
            linkTemporario.download = dados.nomeArquivo //nome do arquivo pro usuario
            document.body.appendChild(linkTemporario)
            linkTemporario.click()
            // Remove o link do DOM após o download
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(function () {
        if (mostrar == true) {
            CarregarAnexos(id_ticket)
        }
    }, [mostrar])
    return (
        <Modal show={mostrar} centered size='lg'>
            <Modal.Header closeButton className="bg-white" onHide={fecharModal}>
                <h4>Anexos carregados</h4>
            </Modal.Header>
            <Modal.Body className="bg-white">
                <div className="container">
                    <div className="row">
                        <ClickableTable
                            coluna={colunas}
                            data={dados}
                            usaRowClick={false}
                            onRowClick={function () {

                            }}
                            itemsPerPage={10}
                            acaoVisualizar={BaixarAnexo}
                            acaoEditar={function () {

                            }}
                            acaoExcluir={function () {

                            }}
                            usaAcoes={true}
                            usaEditar={false}
                            usaVisualizar={true}
                            usaExcluir={false}
                            labelpesquisa="Buscar por Arquivo"
                            acessopesquisa="nomeaArquivo"
                        />
                    </div>
                </div>
            </Modal.Body>
            <ModalLoading show={showModalLoading} />
        </Modal>
    )
}
export default VisualizarAnexos