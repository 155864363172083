import { useParams } from "react-router-dom";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo"
import FormCliente from "./components/formulario"

function ClientesEditar() {
    const idcliente = useParams()

    return (
        <div className="col mt-2">
            <ContainerTitulo titulo="Edição de Cliente" />
            <FormCliente idcliente={idcliente.id} acao="Editar" />
        </div>
    )
}

export default ClientesEditar