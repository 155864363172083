import { Row, Col, Card } from "react-bootstrap";
import ContainerTitulo from "../../components/Formularios/Container/ContainerTitulo";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ITabela } from "../../components/Formularios/Table/TabelaInterface";
import { InterfaceUsuario } from "../../types/UsuarioInterface";
import { toast } from "react-toastify";
import ApiGet from "../../api/endPoints/useGet";
import ModalLoading from "../../components/Formularios/Modal/ModalLoading";
import { getSessionData } from "../../utils/storageUtils";
import ClickableTable from "../../components/ClickableTable/ClickableTabl";
import axios from "axios";

function Agentes() {
    const token = getSessionData("MultfilialWebToken") || "";
    const isSuper = getSessionData("isPerfilSuper") || "";
    let isVinculo = getSessionData("DadosIdfilial") || "";
    const id_perfil = getSessionData("id_perfil")
    const id_filial = getSessionData("DadosIdfilial")
    const navigate = useNavigate();

    const editarAgente = (dados: any) => {
        const id_usuario = dados.id_usuario;
        navigate("agentesEditar/" + id_usuario);
    };

    const colunas: ITabela[] = [
        { titulo: "Código", acesso: "id_usuario" },
        { titulo: "Nome", acesso: "nome" },
        { titulo: "Usuário", acesso: "nome_usuario" },
        { titulo: "Email", acesso: "email" },
        { titulo: "Telefone", acesso: "telefone" },
        { titulo: "Perfil", acesso: "nome_perfil" },
        { titulo: "Situação", acesso: "situacao" },
    ];

    //#region REALIZA REQUISIÇÃO PARA LISTAR DADOS DA TABELA
    const [dados, setDados] = useState<InterfaceUsuario[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    function buscarAgentes() {
        const id_filial = getSessionData("DadosIdfilial")

        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/agentes/${id_filial}`, {
            headers: { Authorization: token }
        }).then(function (resposta) {
            setDados(resposta.data.data)
            setLoading(false)
        }).catch(function (erro) {
            setLoading(false)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    //verifica permissão --- inicio
    const [permissao_visualizar, set_permissao_visualizar] = useState(true)
    const [permissao_incluir, set_permissao_incluir] = useState(true)
    const [permissao_editar, set_permissao_editar] = useState(true)
    const [permissao_excluir, set_permissao_excluir] = useState(true)


    function CarregarPermissões(id_perfil: any, id_filial: any) {
        const headers = {
            Authorization: token,
        };
        const dados = {
            url: "/perfil/carregar/:idperfil/:idfilial",
            parametros: {
                idperfil: id_perfil,
                idfilial: id_filial
            },
            headers: headers,
        };
        ApiGet(dados).then(function (response) {
            if (response[0].visualizarConfiguracaoAgentes === 1) {
                set_permissao_visualizar(true)
            } else (
                set_permissao_visualizar(false)
            )
            if (response[0].incluirConfiguracaoAgentes === 1) {
                set_permissao_incluir(false)
            }
            if (response[0].editarConfiguracaoAgentes === 1) {
                set_permissao_editar(true)
            } else (
                set_permissao_editar(false)
            )
            if (response[0].excluirConfiguracaoAgentes === 1) {
                set_permissao_excluir(true)
            } else (
                set_permissao_excluir(false)
            )


        })
    }
    //verifica permissão --- fim
    useEffect(() => {
        buscarAgentes()
        CarregarPermissões(id_perfil, id_filial)
    }, []);

    //#endregion

    return (
        <>
            <div className="col mt-2">
                <ContainerTitulo titulo="Listagem de Agentes" />

                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <ClickableTable
                                        coluna={colunas}
                                        data={dados}
                                        usaRowClick={false}
                                        onRowClick={function () {

                                        }}
                                        itemsPerPage={5}
                                        acaoVisualizar={function(){}}
                                        acaoEditar={editarAgente}
                                        acaoExcluir={function(){}}
                                        usaAcoes={true}
                                        usaEditar={permissao_editar}
                                        usaVisualizar={false}
                                        usaExcluir={false}
                                        labelpesquisa="Procurar por Nome"
                                        acessopesquisa="nome"
                                    />
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
            <ModalLoading show={loading} label="Carregando Listagem de Agentes" />
        </>
    )
}

export default Agentes