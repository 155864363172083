import { Col, Form, Modal, Row } from "react-bootstrap";
import { Button as ButtonCustom } from "../../../../components/Formularios/Buttons/Button";
import { mdiCheckAll, mdiCheckCircle, mdiKeyboardReturn } from "@mdi/js";
import { useEffect, useState } from "react";
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda";
import { Switch } from "@mui/material";
import Tabela from "../../../../components/Formularios/Table";
import { getSessionData } from "../../../../utils/storageUtils";
import axios from "axios";
import { toast } from "react-toastify";
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface";
import { useNavigate } from "react-router-dom";
import ApiPost from "../../../../api/endPoints/usePost";
import { showConfirmationDialog } from "../../../../components/Formularios/Modal/ModalExcluir";
import ApiDelete from "../../../../api/endPoints/useDelete";
import ClickableTable from "../../../../components/ClickableTable/ClickableTabl";

interface DetalheModalProps {
  showModal: boolean;
  setShowModal: (item: boolean) => void
  acao?: string;
  onClose: () => void;
  itemTipo: string;
  dadosCodigo?: string;
  dadosDescricao?: string;
  dadosAtivo?: boolean;
  dadosDefUsuario01?: string;
  dadosDefUsuario02?: string;
  dadosDefUsuario03?: string;
  dadosDefUsuario04?: string;
  dadosDefUsuario05?: string;
  dadosDefUsuario06?: string;
  onSubmit: (formData: any) => void;
  onSave: () => void;
}
const ModalDetalhe: React.FC<DetalheModalProps> = ({
  showModal,
  setShowModal,
  acao,
  onClose,
  itemTipo,
  dadosCodigo,
  dadosDescricao,
  dadosAtivo,
  dadosDefUsuario01,
  dadosDefUsuario02,
  dadosDefUsuario03,
  dadosDefUsuario04,
  dadosDefUsuario05,
  dadosDefUsuario06,
  onSubmit,
  onSave
}) => {
  const [codigo, setCodigo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [ativo, setAtivo] = useState<boolean>(true);
  const [defUsuario01, setDefUsuario01] = useState<string>("");
  const [defUsuario02, setDefUsuario02] = useState<string>("");
  const [defUsuario03, setDefUsuario03] = useState<string>("");
  const [defUsuario04, setDefUsuario04] = useState<string>("");
  const [defUsuario05, setDefUsuario05] = useState<string>("");
  const [defUsuario06, setDefUsuario06] = useState<string>("");
  const token = getSessionData("MultfilialWebToken") || "";


  //#region CONTROLANDO A ABERTUA DO MODAL DE VINCULAÇÃO DE CLIENTES
  const [abrirModalVincCliente, setAbrirModalVincCliente] = useState<boolean>(false)

  function abrirModalVincular() {
    setAbrirModalVincCliente(true)
    onClose()
  }

  function fecharModalVincular() {
    setAbrirModalVincCliente(false)
    setShowModal(true)
  }
  //#endregion

  //#region CONTROLANDO A ABERTUA DO MODAL DE VISUALIZAÇÃO DE CLIENTES VINCULADOS
  const [abrirModalCliVinculados, setAbrirModalCliVinculados] = useState<boolean>(false);

  function abrirModalVinculados() {
    setAbrirModalCliVinculados(true)
    onClose()
  }

  function fecharModalVinculados() {
    setAbrirModalCliVinculados(false)
    setShowModal(true)
  }
  //#endregion

  const carregarDados = () => {
    setCodigo(dadosCodigo || "");
    setDescricao(dadosDescricao || "");
    setAtivo(dadosAtivo === true ? true : false);
    setDefUsuario01(dadosDefUsuario01 || "");
    setDefUsuario02(dadosDefUsuario02 || "");
    setDefUsuario03(dadosDefUsuario03 || "");
    setDefUsuario04(dadosDefUsuario04 || "");
    setDefUsuario05(dadosDefUsuario05 || "");
    setDefUsuario06(dadosDefUsuario06 || "");
  };

  useEffect(() => {
    if (showModal) {
      carregarDados();
    }
  }, [showModal]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const dados = {
      codigo,
      descricao,
      ativo,
      defUsuario01,
      defUsuario02,
      defUsuario03,
      defUsuario04,
      defUsuario05,
      defUsuario06,
      acao,
    };
    onSubmit(dados);
  };

  //#region LISTAR CLIENTE PARA VINCULAR AO SISTEMA
  const colunasListarClientes: ITabela[] = [

    { titulo: "Id", acesso: "id_cliente" },
    { titulo: "Cliente", acesso: "fantasia" },
  ]

  const [dadosListarClientes, setDadosListarClientes] = useState([]);

  function listarClientes(codigo: string) {
    const id_filial = getSessionData("DadosIdfilial")

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/clientes/codsis/${codigo}/${id_filial}`, {
      headers: { Authorization: token }
    }).then(function (resposta) {
      setDadosListarClientes(resposta.data.data)
    }).catch(function (erro) {
      const status = erro.response.status
      if (status == 403 || status == 401) {
        toast.error("Acesso negado, faça seu login novamente")
      }
    })
  }

  useEffect(function () {
    listarClientes(codigo)
  }, [abrirModalVincCliente])
  //#endregion

  //#region LISTAR CLIENTES JA VINCULADOS
  const [dadosListarClientesVinculados, setDadosListarClientesVinculados] = useState([]);

  function listarClientesVinculados(codigo: string) {
    const id_filial = getSessionData("DadosIdfilial")

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/clientes/vinculados/codsis/${codigo}/${id_filial}`, {
      headers: { Authorization: token }
    }).then(function (resposta) {
      setDadosListarClientesVinculados(resposta.data.data)
    }).catch(function (erro) {
      const status = erro.response.status
      if (status == 403 || status == 401) {
        toast.error("Acesso negado, faça seu login novamente")
      }
    })
  }

  useEffect(function () {
    listarClientesVinculados(codigo)
  }, [abrirModalCliVinculados])
  //#endregion

  //#region Dados para vincular o cliente do agente
  const [dadosClientes, setDadosClientes] = useState<any[]>([]);

  const selecionarCliente = (dados: any) => {
    const id_filial = getSessionData("DadosIdfilial")

    if (acao === "Novo") {
      const clienteSelecionado = dadosClientes.some(
        (item: any) => item.id_cliente === dados.id_cliente
      );

      if (!clienteSelecionado) {
        setDadosClientes([...dadosClientes, dados]);
        setAbrirModalVincCliente(false);
      } else {
        toast.error("Cliente já vinculado ao agente.");
      }
    } else if (acao === "Editar" || "Visualizar") {
      const clienteSelecionado = dadosClientes.some(
        (item: any) => item.id_cliente === dados.id_cliente
      );

      if (!clienteSelecionado) {
        const dadosClientes = {
          id_sistema: codigo,
          id_cliente: dados.id_cliente,
          id_filial: id_filial
        };
        //  ApiPost("/usuario/gravar", dados, token)
        ApiPost("/gravar/sistema/cliente", dadosClientes, token)
          .then((retorno) => {
            toast.success(retorno.message);
            carregarDados();
            listarClientes(codigo)
          })
          .catch((erro) => {
            toast.error(
              `Erro ao incluir registro. Motivo: ${erro.response.data.message}`
            );
          });
      } else {
        toast.error("Cliente já vinculado ao agente.");
      }
    }
  };
  //#endregion

  //#region Excluir Cliente do Agente
  const excluirCliente = async (dados: any) => {
    const id_filial = getSessionData("DadosIdfilial")

    if (acao !== "Visualizar") {
      await showConfirmationDialog(
        "Desvincular Cliente",
        `Tem certeza que deseja desvincular o Cliente?`,
        "question"
      )
        .then((result) => {
          if (result.confirmed) {
            if (acao === "Novo") {
              const dadosFiltrados = dadosClientes.filter(
                (item: any) => item.id_cliente !== dados.id_cliente
              );
              setDadosClientes(dadosFiltrados);
            } else if (acao === "Editar" || "Visualizar") {
              const headers = {
                Authorization: token,
              };
              const dadosClientes = {
                url: `/remover/sistema/cliente/${codigo}/${dados.id_cliente}/${id_filial}`,
                parametros: {},
                headers,
              };
              ApiDelete(dadosClientes)
                .then((retorno) => {
                  toast.success(retorno.message);
                  carregarDados();
                  listarClientesVinculados(codigo)
                })
                .catch((erro) => {
                  toast.error(
                    `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
                  );
                });
            }
          }
        })
        .catch((erro) => {
          toast.error(
            `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
          );
        });
    }
  };
  //#endregion

  return (
    <>
      <Modal
        show={showModal}
        className="modal-full-width "
        size="xl"
        keyboard={false}
        centered
      >
        <Modal.Header className="bg-white">
          <Modal.Title>Incluir Código do Sistema</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body className="bg-white">
            <Row>
              <Col sm={2}>
                <InputSemBorda
                  label="Código"
                  name="codigo"
                  type="text"
                  placeholder="Código"
                  readonly={acao === "Visualizar" || acao === "EditarItem" ? true : false}
                  value={codigo || ""}
                  onChange={setCodigo}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-uppercase"
                />
              </Col>
              <Col sm={4}>
                <InputSemBorda
                  label="Descrição"
                  name="descricao"
                  type="text"
                  placeholder="Descrição"
                  required
                  readonly={acao === "Visualizar" ? true : false}
                  value={descricao || dadosDescricao || ""}
                  onChange={setDescricao}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                />
              </Col>
              <Col sm={2}>
                <br />
                <label htmlFor="" className="form-label me-2">
                  Ativo
                </label>
                <Switch
                  checked={ativo}
                  onChange={() => setAtivo(!ativo)}
                  disabled={acao === "Visualizar" ? true : false}
                />
              </Col>
              <Col sm={2}>
                <ButtonCustom
                  invisivel={itemTipo !== 'sistema' || acao == 'Visualizar'}
                  className="btn btn-dark text-info-emphasis bg-primary border-0 rounded w-100 mt-2"
                  descricaoBotao="Vincular Cliente"
                  type='button'
                  onclick={abrirModalVincular}
                />
              </Col>
              <Col sm={2}>
                <ButtonCustom
                  invisivel={itemTipo !== 'sistema'}
                  className="btn btn-dark text-info-emphasis bg-primary border-0 rounded w-100 mt-2"
                  descricaoBotao="Clientes Vinculados"
                  type='button'
                  onclick={abrirModalVinculados}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={4} hidden={true}>
                <InputSemBorda
                  label="Def. Usuário 01"
                  name="defusuario01"
                  type="text"
                  placeholder="Def. Usuário 01"
                  readonly={acao === "Visualizar" ? true : false}
                  value={defUsuario01}
                  onChange={setDefUsuario01}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                />
              </Col>
              <Col sm={4} hidden={true}>
                <InputSemBorda
                  label="Def. Usuário 02"
                  name="defusuario02"
                  type="text"
                  placeholder="Def. Usuário 02"
                  readonly={acao === "Visualizar" ? true : false}
                  value={defUsuario02}
                  onChange={setDefUsuario02}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                />
              </Col>
              <Col sm={4} hidden={true}>
                <InputSemBorda
                  label="Def. Usuário 03"
                  name="defusuario03"
                  type="text"
                  placeholder="Def. Usuário 03"
                  readonly={acao === "Visualizar" ? true : false}
                  value={defUsuario03}
                  onChange={setDefUsuario03}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={4} hidden={true}>
                <InputSemBorda
                  label="Def. Usuário 04"
                  name="defusuario04"
                  type="text"
                  placeholder="Def. Usuário 04"
                  readonly={acao === "Visualizar" ? true : false}
                  value={defUsuario04}
                  onChange={setDefUsuario04}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                />
              </Col>
              <Col sm={4} hidden={true}>
                <InputSemBorda
                  label="Def. Usuário 05"
                  name="defusuario05"
                  type="text"
                  placeholder="Def. Usuário 05"
                  readonly={acao === "Visualizar" ? true : false}
                  value={defUsuario05}
                  onChange={setDefUsuario05}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                />
              </Col>
              <Col sm={4} hidden={true}>
                <InputSemBorda
                  label="Def. Usuário 06"
                  name="defusuario06"
                  type="text"
                  placeholder="Def. Usuário 06"
                  readonly={acao === "Visualizar" ? true : false}
                  value={defUsuario06}
                  onChange={setDefUsuario06}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="bg-white">
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                invisivel={false}
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={onClose}
              />
              <ButtonCustom
                invisivel={acao == 'Visualizar'}
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
                onclick={onSave}
              />
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* MODAL LISTAR CLIENTES PARA VINCULAR AO USUÁRIO */}
      <Modal
        show={abrirModalVincCliente === true}
        className="modal-full-width "
        size="xl"
        keyboard={false}
        centered
      >
        <Modal.Header className="bg-white">
          <Modal.Title>Vincular Cliente</Modal.Title>
        </Modal.Header>
        <Form //onSubmit={handleSubmit}
        >
          <Modal.Body className="bg-white">
            <Row>
              <ClickableTable
                coluna={colunasListarClientes}
                data={dadosListarClientes}
                itemsPerPage={10}
                usaRowClick={false}
                onRowClick={function () { }}
                usaAcoes={true}
                acaoEditar={function () { }}
                acaoVisualizar={function () { }}
                acaoExcluir={function () { }}
                acaoSelecionar={selecionarCliente}
                usaSelecionar={true}
                labelpesquisa={'Filtrar por Nome'}
                acessopesquisa={'fantasia'}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer className="bg-white">
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                invisivel={false}
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={fecharModalVincular}
              />
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* MODAL LISTAR CLIENTES VINCULADOS */}
      <Modal
        show={abrirModalCliVinculados === true}
        className="modal-full-width "
        size="xl"
        keyboard={false}
        centered
      >
        <Modal.Header className="bg-white">
          <Modal.Title>Clientes Vinculados</Modal.Title>
        </Modal.Header>
        <Form //onSubmit={handleSubmit}
        >
          <Modal.Body className="bg-white">
            <Row>
              <ClickableTable
                coluna={colunasListarClientes}
                data={dadosListarClientesVinculados}
                itemsPerPage={10}
                usaRowClick={false}
                onRowClick={function () { }}
                usaAcoes={true}
                acaoEditar={function () { }}
                acaoVisualizar={function () { }}
                acaoExcluir={(item) => excluirCliente(item)}
                acaoSelecionar={function () { }}
                usaExcluir={true}
                labelpesquisa={'Filtrar por Nome'}
                acessopesquisa={'fantasia'}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer className="bg-white">
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                invisivel={false}
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={fecharModalVinculados}
              />
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

    </>
  );
};
export default ModalDetalhe;
