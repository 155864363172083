import { useEffect, useState } from "react"
import extentendoItemMenuBot from "../interfaces/extentendoItemMenuBot"
import { Modal } from "react-bootstrap"
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda"
import { Switch } from "@mui/material"
import axios from "axios"
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading"
import { toast } from "react-toastify"
import { useParams } from "react-router-dom"
import menuBot from "../interfaces/menuBot"
function FormItemMenuBot({
    id_item = "",
    id_menu,
    id_filial,
    id_bot,
    usuario,
    show,
    fechar,
    acao,
    descricao,
    id_menu_retorno,
    sem_retorno
}: extentendoItemMenuBot) {
    //inputs para preencher form
    const [idItem, setIdItem] = useState("")
    const [descricaoItem, setDescricaoItem] = useState<string>("")
    const [semRetorno, setSemRetorno] = useState<boolean>(false)
    const [idMenuRetorno, setIdMenuRetorno] = useState<string>("")
    //lista de menus para selecionar o retorno
    const [listaMenusRetorno, setListaMenusRetorno] = useState([])
    //fecha o modal e zera os inputs 
    function FecharModal() {
        manipularEstadoModal()
        fechar()
        setIdItem("")
        setDescricaoItem("")
        setSemRetorno(false)
        setIdMenuRetorno("")
    }
    //dispara submit do form
    function Submit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        const dados = {
            idItem,
            descricao: descricaoItem,
            semRetorno,
            idMenuRetorno
        }
        setLoading(true)
        if (acao == "novo") {
            axios.post(process.env.REACT_APP_API_BASE_URL + `/criar/menu/opcao/${id_filial}/${id_bot}/${id_menu}`, dados, {
                headers: {
                    Authorization: token
                }
            }).then(function (resposta) {
                toast.success(resposta.data.message)
                FecharModal()
                setLoading(false)
            }).catch(function (erro) {
                toast.error(erro.response.data.message)
                setLoading(false)
            })
        }
        else if (acao == "editar") {
            axios.put(process.env.REACT_APP_API_BASE_URL + `/atualiza/menu/opcao/${id_filial}/${id_bot}/${id_menu}`, dados, {
                headers: {
                    Authorization: token
                }
            }).then(function (resposta) {
                toast.success(resposta.data.message)
                FecharModal()
                setLoading(false)
            }).catch(function (erro) {
                toast.error(erro.response.data.message)
                setLoading(false)
            })
        }
    }
    //"reabre" modal do menu 
    const [showModalMenuBot, setShowModalMenuBot] = useState<boolean>(false)
    function manipularEstadoModal() {
        setShowModalMenuBot(!showModalMenuBot)
    }
    //carrega menus criados do bot para que seja possível vincular o "Retorno"
    const params = useParams()
    const token = sessionStorage.getItem("MultfilialWebToken")!
    const [loading, setLoading] = useState(false)
    function CarregarMenusCriados() {
        setLoading(true)
        axios.get(process.env.REACT_APP_API_BASE_URL + `/carregar/menus/bot/${id_filial}/${params.id_bot}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setLoading(false)
            setListaMenusRetorno(resposta.data.ListaDeMenus)
        }).catch(function (erro) {
            setLoading(false)
            toast.error(erro.response.data.message)
        })
    }
    useEffect(function () {
        if (show == true) {
            CarregarMenusCriados()
            setIdItem(id_item)
            setDescricaoItem(descricao || "")
            setSemRetorno(sem_retorno || false)
            setIdMenuRetorno(id_menu_retorno || "")
        }
    }, [show])
    //set do id retorno
    function setIdRetorno(e: React.ChangeEvent<HTMLSelectElement>) {
        setIdMenuRetorno(e.target.value)
    }
    //limpa o menu vinculado caso fique "ligando e desligando o Tem retorno"
    useEffect(function () {
        if (semRetorno == false) {
            setIdMenuRetorno("")
        }
    }, [semRetorno])
    return (
        <Modal centered size="lg" show={show} onHide={FecharModal}>
            <Modal.Header className="bg-white" closeButton>
                {acao == "novo" ? "Nova Opção de Menu" : acao == "editar" ? `Editando opção ${id_item}` : `Visualizando opção ${id_item}`}
            </Modal.Header>
            <Modal.Body className="bg-white">
                <div className="container">
                    <form onSubmit={Submit}>
                        <div className="row">
                            <div className="col-sm col-md-12 col-lg-2">
                                <InputSemBorda
                                    value={idItem}
                                    type="text"
                                    name="nomedomenu"
                                    onChange={setIdItem}
                                    label="Id.Opção"
                                    placeholder=" "
                                    readonly={acao == "visualizar"}
                                    required
                                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                />
                            </div>
                            <div className="col-sm col-md-12 col-lg-5">
                                <InputSemBorda
                                    value={descricaoItem}
                                    type="text"
                                    name="nomedomenu"
                                    onChange={setDescricaoItem}
                                    label="Descrição"
                                    placeholder=" "
                                    readonly={acao == "visualizar"}
                                    required
                                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                />
                            </div>
                            <div className="col-sm col-md-12 col-lg-4 pt-3 text-end">
                                <label htmlFor="" className="form-label me-2">
                                    Tem retorno?
                                </label>
                                <Switch
                                    disabled={acao == "visualizar"}
                                    checked={semRetorno}
                                    onChange={function () {
                                        setSemRetorno(!semRetorno)
                                    }}
                                />
                            </div>
                            <div className="col-sm col-md-12 col-lg-7">
                                {
                                    semRetorno == true &&

                                    <div className="form-floating">
                                        <select value={idMenuRetorno} onChange={setIdRetorno} disabled={acao == "visualizar"} required={semRetorno == true} className="form-select bordasInferiorInput text-black" id="floatingSelect" aria-label="Floating label select example">
                                            <option value="" selected>Selecione...</option>
                                            {
                                                listaMenusRetorno.map(function (menu: menuBot) {
                                                    return <option value={menu.id_menu} selected>{menu.nomeMenu}</option>
                                                })
                                            }
                                        </select>
                                        <label>Menu de Retorno</label>
                                    </div>

                                }
                            </div>
                            <div className="col-sm col-md-12 col-lg-4 pt-3">
                                <button disabled={acao == "visualizar"} type="submit" className="btn btn-sm btn-secondary w-100">
                                    <i className="bi bi-floppy me-1"></i>
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <ModalLoading
                    show={loading}
                    label="Carregando opção de menu"
                />
            </Modal.Body>
        </Modal>
    )
}
export default FormItemMenuBot