import { Card, Col, Row } from "react-bootstrap"
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo"
import { useEffect, useState } from "react"
import { Button } from "../../../components/Formularios/Buttons/Button"
import { mdiCheckAll } from "@mdi/js"
import axios from "axios"
import { toast } from "react-toastify"
import { getSessionData } from "../../../utils/storageUtils"
import ModalIncluirCampos from "../../../components/Formularios/FomulariosIncluir/AdicionarCampos"
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";
import ModalEditarCampos from "../../../components/Formularios/FormulariosEditar/EditarCampos"
import { useParams } from "react-router-dom"
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda"
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading"

function FormulariosVisualizar() {
    const [sistema, setSistema] = useState('')
    const [categoria, setCategoria] = useState('')
    const [listaInputsForm, setListaInputsForm] = useState<any>([])
    const [listaCodSist, setListaCodSist] = useState([])
    const [dados, setDados] = useState([])
    const { idFormulario } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const token = getSessionData('MultfilialWebToken') || "";

    function teste() {

    }

    function buscarCabecalho() {
        const idFilial = getSessionData("DadosIdfilial")

        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/visualizar/cabecalho/formulario/${idFormulario}/${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setCategoria(resposta.data.data[0].categoria)
            setSistema(resposta.data.data[0].sistema)
            setLoading(false)
        }).catch(function (erro) {
            setLoading(false)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    function buscarDetalhes() {
        const idFilial = getSessionData("DadosIdfilial")

        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/visualizar/detalhes/formulario/${idFormulario}/${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            console.log(resposta.data.data)
            setDados(resposta.data.data)
            setLoading(false)
        }).catch(function (erro) {
            setLoading(false)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    useEffect(() => {
        buscarCabecalho()
        buscarDetalhes()
    }, [])


    return (
        <>
            <div className="col">
                <ContainerTitulo titulo="Visualização - Detalhes do Formulário" />

                <form onSubmit={teste}>
                    <Card className="p-4">
                        <Card className="mb-4 p-3 border shadow">
                            <Row>
                                <Col sm={6} className="mb-3">
                                    <div className="col-16 form-floating">
                                        <InputSemBorda
                                            label="Sistema"
                                            name="sistema"
                                            type="text"
                                            readonly
                                            placeholder="Sistema"
                                            value={sistema}
                                            onChange={setSistema}
                                            classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                        />
                                    </div>
                                </Col>
                                <Col sm={6} className="mb-3">
                                    <div className="col-16 form-floating">
                                        <InputSemBorda
                                            label="Categoria"
                                            name="categoria"
                                            type="text"
                                            readonly
                                            placeholder="Categoria"
                                            value={categoria}
                                            onChange={setCategoria}
                                            classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Card>
                </form>
                <Card className="p-4">
                    <Card className="mb-4 p-3 border shadow">
                        <h4>Campos adicionados ao formulário</h4>
                        <form onSubmit={function (e) {
                            e.preventDefault()
                        }}>
                            <Row>
                                {dados.map((campo: any) => {
                                    return (
                                        <>
                                            {campo.tipo_campo === '3' && (
                                                <Col sm={2} key={campo.id_det}>
                                                    <div className="d-flex align-items-center">
                                                        <div className="col-16 form-floating">
                                                            <select
                                                                autoFocus
                                                                disabled
                                                                className="form-select bordasInferiorInput text-black text-capitalize"
                                                                id={campo.id_campo}
                                                            >
                                                                <option value={""}>{campo.tabela}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}

                                            {campo.tipo_campo === '2' && (
                                                <Col sm={2} key={campo.id_det}>
                                                    <div className="d-flex align-items-center">
                                                        <div className="form-floating">
                                                            <input
                                                                id={campo.id_campo}
                                                                readOnly
                                                                className="form-control bordasInferiorInput text-black text-capitalize"
                                                                placeholder=" "
                                                                type="number"
                                                            />
                                                            <label>{campo.label_campo}</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}

                                            {campo.tipo_campo === '1' && (
                                                <Col sm={2} key={campo.id_det}>
                                                    <div className="d-flex align-items-center">
                                                        <div className="form-floating">
                                                            <input
                                                                id={campo.id_campo}
                                                                readOnly
                                                                className="form-control bordasInferiorInput text-black text-capitalize"
                                                                placeholder=" "
                                                                type="text"
                                                            />
                                                            <label>{campo.label_campo}</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}
                                        </>
                                    );
                                })}
                            </Row>

                        </form>
                    </Card>
                </Card>
            </div>
            <ModalLoading show={loading} label="Carregando Listagem de Formulários" />
        </>
    )
}

export default FormulariosVisualizar