import { Form, Card, Col, Row } from "react-bootstrap"
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo"
import { useEffect, useState } from "react";
import ClickableTable from "../../../components/ClickableTable/ClickableTabl";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";
import axios from "axios";
import { getSessionData } from "../../../utils/storageUtils";
import { toast } from "react-toastify";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import { Box, SpeedDial, SpeedDialAction, SpeedDialIcon, Tab } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

interface Cliente {
    id_cliente: string; // ou string, dependendo do seu caso
    fantasia: string;
}

function VisualizarTickets() {
    const token = getSessionData('MultfilialWebToken') || "";
    const idUsuario = getSessionData("DadosUsuarioID") || "";

    const [loading, setLoading] = useState<boolean>(false);
    const [permissao_editar, set_permissao_editar] = useState(true)
    const navigate = useNavigate()
    const [buscarPorCliente, setBuscarPorCliente] = useState(false)
    const [clientes, setClientes] = useState<Cliente[]>([]);
    const [idCliente, setidCliente] = useState<string>('')
    const [clienteSelecionado, setClienteSelecionado] = useState("")
    const [guiaAtual, set_guiaAtual] = useState("")
    const [dadosAbertos, setDadosAbertos] = useState([])
    const [dadosPendentes, setDadosPendentes] = useState([])
    const [dadosFechados, setDadosFechados] = useState([])

    // Estado para controlar a guia ativa
    const [guia, setGuia] = useState("1");

    // Função para capturar a guia selecionada
    const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
        setGuia(novaGuia);
    };

    interface Action {
        icon: JSX.Element;
        name: string;
    }

    // Definição das ações disponíveis para listar tickets por filial ou por usuário
    const actions: Action[] = [
        {
            icon: <SupervisedUserCircleIcon onClick={function () {
                setBuscarPorCliente(false)
                setidCliente('')
                setClienteSelecionado('')
            }} />,
            name: "Listar por usuário",
        },
        {
            icon: <AccountBoxIcon onClick={function () {
                setBuscarPorCliente(true) // Muda para buscar por cliente
            }} />,
            name: "Listar por Cliente",
        }
    ];

    useEffect(function () {
        if (clienteSelecionado === '') {
            setidCliente('')
        }
    }, [clienteSelecionado])

    // Funcao criada para preencher o select box de clientes na tela de criacao de ticket
    function buscarClientesSelectbox() {
        const idFilial = getSessionData("DadosIdfilial")
        const idUsuario = getSessionData('DadosUsuarioID')

        // Faz uma requisição GET para obter dados de vários select boxes da API
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/clientes/selectBox/visualizarTickets`, {
            headers: {
                Authorization: token // Adiciona o token de autorização no cabeçalho da requisição
            },
            params: {
                idFilial,
                idUsuario
            }
        }).then(function (resposta) {
            setClientes(resposta.data.dataClientes)
        }).catch(function (erro) {
            // Trata erros de autorização e exibe uma mensagem para o usuário
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    useEffect(function () {
        buscarClientesSelectbox()
    }, [])

    // Definição das colunas da tabela de exibição de tickets
    const colunas: ITabela[] = [
        { titulo: "ID", acesso: "id_ticket" },
        { titulo: "Titulo", acesso: "titulo" },
        { titulo: "Categoria", acesso: "categoria" },
        { titulo: "Requerente", acesso: "usuario_criacao" },
        { titulo: "Cliente", acesso: "cliente" },
        { titulo: "Agente", acesso: "agente" },
        { titulo: "Data de Abertura", acesso: "dt_criacao" }
    ];

    // Efeito que executa as buscas dos tickets quando o valor de `buscarPorFilial` muda
    useEffect(() => {
        buscarTicketsAbertos();
        buscarTicketsPendentes();
        buscarTicketsFechados();
    }, [buscarPorCliente, clienteSelecionado]);

    // Função para buscar tickets abertos
    function buscarTicketsAbertos() {
        const usuarioCriacaoId = getSessionData("DadosUsuarioID");
        const idFilial = getSessionData("DadosIdfilial");

        setLoading(true); // Exibe carregamento
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/tickets/abertos/usuario`, {
            headers: {
                Authorization: token, // Adiciona o token de autorização
            },
            params: {
                idFilial,
                usuarioCriacaoId,
                buscarPorCliente,
                idCliente
            }
        }).then(function (resposta) {
            setDadosAbertos(resposta.data.data); // Define os dados de tickets abertos
            setLoading(false);
        }).catch(function (erro) {
            setLoading(false);
            const status = erro.response.status;
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente");
            }
        });
    }

    // Função para buscar tickets pendentes
    function buscarTicketsPendentes() {
        const usuarioCriacaoId = getSessionData("DadosUsuarioID");
        const idFilial = getSessionData("DadosIdfilial");

        setLoading(true); // Exibe carregamento
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/tickets/pendentes/usuario`, {
            headers: {
                Authorization: token, // Adiciona o token de autorização
            },
            params: {
                idFilial,
                usuarioCriacaoId,
                buscarPorCliente,
                idCliente
            }
        }).then(function (resposta) {
            setDadosPendentes(resposta.data.data); // Define os dados de tickets pendentes
            setLoading(false);
        }).catch(function (erro) {
            setLoading(false);
            const status = erro.response.status;
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente");
            }
        });
    }

    // Função para buscar tickets fechados
    function buscarTicketsFechados() {
        const usuarioCriacaoId = getSessionData("DadosUsuarioID");
        const idFilial = getSessionData("DadosIdfilial");

        setLoading(true); // Exibe carregamento
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/tickets/fechados/usuario`, {
            headers: {
                Authorization: token, // Adiciona o token de autorização
            },
            params: {
                idFilial,
                usuarioCriacaoId,
                buscarPorCliente,
                idCliente
            }
        }).then(function (resposta) {
            setDadosFechados(resposta.data.data); // Define os dados de tickets fechados
            setLoading(false);
        }).catch(function (erro) {
            setLoading(false);
            const status = erro.response.status;
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente");
            }
        });
    }

    // Efeito que faz a busca inicial dos tickets ao carregar a página
    useEffect(() => {
        buscarTicketsAbertos();
        buscarTicketsPendentes();
        buscarTicketsFechados();
    }, []);

    // Navega para a página de detalhes do ticket selecionado
    function irParaDetalhes(dados: any) {
        navigate(`detalhesTicketUsuario/${dados.id_ticket}`);
    }

    function teste() {
    }

    return (
        <>
            <div className="col">
                <ContainerTitulo titulo="Visualização dos Tickets" />

                <Card>
                    <Form onSubmit={teste}>
                        <Box sx={{ width: "100%" }}>
                            <TabContext value={guia}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <TabList
                                        onChange={capturaGuia}
                                        TabIndicatorProps={{
                                            style: {
                                                backgroundColor: "#000",
                                                color: "#000",
                                            },
                                        }}
                                    >
                                        <Tab onClick={function () {
                                            set_guiaAtual("")
                                        }} label="Tickets Abertos" value="1" />

                                        <Tab onClick={function () {
                                            set_guiaAtual("")
                                        }} label="Tickets Pendentes" value="2" />
                                        <Tab onClick={function () {

                                            set_guiaAtual("")
                                        }} label="Tickets Finalizados" value="3" />
                                    </TabList>
                                    <TabPanel value="1">
                                        <div className="container-fluid">
                                            <div className="row" hidden={!buscarPorCliente}>
                                                <Col sm={3} className="">
                                                    <div className="col-16 form-floating">
                                                        <select
                                                            autoFocus
                                                            required
                                                            className="form-select bordasInferiorInput text-black text-capitalize"
                                                            onChange={(event) => {
                                                                const selectedClient = event.target.value;
                                                                setClienteSelecionado(selectedClient);

                                                                // Aqui, você pode encontrar o cliente selecionado pelo nome (fantasia)
                                                                const cliente = clientes.find(item => item.fantasia === selectedClient);
                                                                if (cliente) {
                                                                    setidCliente(cliente.id_cliente); // Atualiza o ID do cliente apenas quando um cliente é selecionado
                                                                } else {
                                                                    setidCliente("")
                                                                }
                                                            }}
                                                            value={clienteSelecionado}
                                                        >
                                                            <option value={""}>Todos</option>
                                                            {clientes.map((item: any) => (
                                                                <option key={item.id_cliente} value={item.fantasia}>
                                                                    {item.fantasia}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <label className="form-label">Cliente(s)</label>
                                                    </div>
                                                </Col>
                                            </div>
                                            <div className="row">
                                                <SpeedDial
                                                    //hidden={permissao_incluir}
                                                    ariaLabel="SpeedDial basic example"
                                                    direction="left"
                                                    sx={{
                                                        right: 25,
                                                        position: "absolute",
                                                    }}
                                                    icon={<SpeedDialIcon />}
                                                >
                                                    {actions.map((action) => (
                                                        <SpeedDialAction
                                                            key={action.name}
                                                            icon={action.icon}
                                                            tooltipTitle={action.name}
                                                        />
                                                    ))}
                                                </SpeedDial>
                                                <ClickableTable
                                                    coluna={colunas}
                                                    data={dadosAbertos}
                                                    usaRowClick={true}
                                                    onRowClick={irParaDetalhes}
                                                    itemsPerPage={10}
                                                    acaoVisualizar={teste}
                                                    acaoEditar={irParaDetalhes}
                                                    acaoExcluir={teste}
                                                    usaAcoes={false}
                                                    usaEditar={permissao_editar}
                                                    //usaVisualizar={permissao_visualizar}
                                                    //usaExcluir={permissao_excluir}
                                                    labelpesquisa="Buscar por Titulo"
                                                    acessopesquisa="titulo"
                                                />
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <div className="container-fluid">
                                            <div className="row" hidden={!buscarPorCliente}>
                                                <Col sm={3} className="">
                                                    <div className="col-16 form-floating">
                                                        <select
                                                            autoFocus
                                                            required
                                                            className="form-select bordasInferiorInput text-black text-capitalize"
                                                            onChange={(event) => {
                                                                const selectedClient = event.target.value;
                                                                setClienteSelecionado(selectedClient);

                                                                // Aqui, você pode encontrar o cliente selecionado pelo nome (fantasia)
                                                                const cliente = clientes.find(item => item.fantasia === selectedClient);
                                                                if (cliente) {
                                                                    setidCliente(cliente.id_cliente); // Atualiza o ID do cliente apenas quando um cliente é selecionado
                                                                } else {
                                                                    setidCliente("")
                                                                }
                                                            }}
                                                            value={clienteSelecionado}
                                                        >
                                                            <option value={""}>Todos</option>
                                                            {clientes.map((item: any) => (
                                                                <option key={item.id_cliente} value={item.fantasia}>
                                                                    {item.fantasia}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <label className="form-label">Cliente(s)</label>
                                                    </div>
                                                </Col>
                                            </div>
                                            <div className="row">
                                                <SpeedDial
                                                    //hidden={permissao_incluir}
                                                    ariaLabel="SpeedDial basic example"
                                                    direction="left"
                                                    sx={{
                                                        right: 25,
                                                        position: "absolute",
                                                    }}
                                                    icon={<SpeedDialIcon />}
                                                >
                                                    {actions.map((action) => (
                                                        <SpeedDialAction
                                                            key={action.name}
                                                            icon={action.icon}
                                                            tooltipTitle={action.name}
                                                        />
                                                    ))}
                                                </SpeedDial>
                                                <ClickableTable
                                                    coluna={colunas}
                                                    data={dadosPendentes}
                                                    usaRowClick={true}
                                                    onRowClick={irParaDetalhes}
                                                    itemsPerPage={10}
                                                    acaoVisualizar={teste}
                                                    acaoEditar={irParaDetalhes}
                                                    acaoExcluir={teste}
                                                    usaAcoes={false}
                                                    usaEditar={permissao_editar}
                                                    //usaVisualizar={permissao_visualizar}
                                                    //usaExcluir={permissao_excluir}
                                                    labelpesquisa="Buscar por Titulo"
                                                    acessopesquisa="titulo"
                                                />
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value="3">
                                        <div className="container-fluid">
                                            <div className="row" hidden={!buscarPorCliente}>
                                                <Col sm={3} className="">
                                                    <div className="col-16 form-floating">
                                                        <select
                                                            autoFocus
                                                            required
                                                            className="form-select bordasInferiorInput text-black text-capitalize"
                                                            onChange={(event) => {
                                                                const selectedClient = event.target.value;
                                                                setClienteSelecionado(selectedClient);

                                                                // Aqui, você pode encontrar o cliente selecionado pelo nome (fantasia)
                                                                const cliente = clientes.find(item => item.fantasia === selectedClient);
                                                                if (cliente) {
                                                                    setidCliente(cliente.id_cliente); // Atualiza o ID do cliente apenas quando um cliente é selecionado
                                                                } else {
                                                                    setidCliente("")
                                                                }
                                                            }}
                                                            value={clienteSelecionado}
                                                        >
                                                            <option value={""}>Todos</option>
                                                            {clientes.map((item: any) => (
                                                                <option key={item.id_cliente} value={item.fantasia}>
                                                                    {item.fantasia}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <label className="form-label">Cliente(s)</label>
                                                    </div>
                                                </Col>
                                            </div>
                                            <div className="row">
                                                <SpeedDial
                                                    //hidden={permissao_incluir}
                                                    ariaLabel="SpeedDial basic example"
                                                    direction="left"
                                                    sx={{
                                                        right: 25,
                                                        position: "absolute",
                                                    }}
                                                    icon={<SpeedDialIcon />}
                                                >
                                                    {actions.map((action) => (
                                                        <SpeedDialAction
                                                            key={action.name}
                                                            icon={action.icon}
                                                            tooltipTitle={action.name}
                                                        />
                                                    ))}
                                                </SpeedDial>
                                                <ClickableTable
                                                    coluna={colunas}
                                                    data={dadosFechados}
                                                    usaRowClick={true}
                                                    onRowClick={irParaDetalhes}
                                                    itemsPerPage={10}
                                                    acaoVisualizar={teste}
                                                    acaoEditar={irParaDetalhes}
                                                    acaoExcluir={teste}
                                                    usaAcoes={false}
                                                    usaEditar={permissao_editar}
                                                    //usaVisualizar={permissao_visualizar}
                                                    //usaExcluir={permissao_excluir}
                                                    labelpesquisa="Buscar por Titulo"
                                                    acessopesquisa="titulo"
                                                />
                                            </div>
                                        </div>
                                    </TabPanel>
                                </Box>
                            </TabContext>
                        </Box>
                    </Form>
                </Card>
                <ModalLoading show={loading} label="Carregando..." />
            </div>
        </>
    )
}

export default VisualizarTickets