import { useParams } from "react-router-dom";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import FormCliente from "./components/formulario";

function ClientesIncluir() {
  const idcliente = useParams()

  return (
    <>
      <div className="col mt-2">
        <ContainerTitulo titulo="Incluir Cliente" />
        <FormCliente idcliente={idcliente.id} acao="Novo" />
      </div>
    </>
  )
}

export default ClientesIncluir