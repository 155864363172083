import { Card, Col, Row } from "react-bootstrap"
import ContainerTitulo from "../../../../components/Formularios/Container/ContainerTitulo"
import { SpeedDial, SpeedDialAction, SpeedDialIcon, useScrollTrigger } from "@mui/material"
import { FaFileExcel, FaFilePdf } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Action } from "../../../administrativo/codigodoSistema/codigodosistema";
import ClickableTable from "../../../../components/ClickableTable/ClickableTabl";
import axios from "axios";
import { getSessionData } from "../../../../utils/storageUtils";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface";
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading";

function Relatorios() {
    const token = getSessionData('MultfilialWebToken') || "";
    const idFilial = getSessionData('DadosIdfilial') || "";
    const idUsuario = getSessionData('DadosUsuarioID') || ""

    const navigate = useNavigate()

    const [dataInicio, setDataInicio] = useState<string>('')
    const [dataFim, setDataFim] = useState<string>('')
    const [dados, setDados] = useState([])
    const [mostraModalCarregando, setMostraModalCarregando] = useState<boolean>(false)
    const [tipoRelatorio, setTipoRelatorio] = useState<string>('')
    const [agentes, setAgentes] = useState<[string]>([''])
    const [agenteSelecionado, setAgenteSelecionado] = useState<number | string>('')
    const [usuarios, setUsuarios] = useState<[string]>([''])
    const [usuarioSelecionado, setUsuarioSelecionado] = useState<number | string>('')
    const [nomeArquivo, setNomeArquivo] = useState<string>()
    const [diasCarregamento, setDiasCarregamento] = useState<number>(10)
    const [diasDisabled, setDiasDisabled] = useState<boolean>(false)

    const actions: Action[] = [
        {
            icon: (
                <FaFilePdf
                    onClick={gerarPDF}
                />
            ),
            name: "PDF",
        },
        {
            icon: (
                <FaFileExcel
                    onClick={gerarExcelTickets}
                />
            ),
            name: "Excel",
        },

    ];

    async function buscaDadosDeVida() {
        if (!dataInicio && !dataFim && !diasCarregamento) {
            return
        }

        setMostraModalCarregando(true)

        let url = `${process.env.REACT_APP_API_BASE_URL}/busca/dados/tickets/${idFilial}/${diasCarregamento}/${idUsuario}`;

        if (dataInicio && dataFim) {
            url += `/${dataInicio}/${dataFim}`;
        } else if (dataInicio) {
            url += `/${dataInicio}`
        } else if (dataFim) {
            url += `/${dataFim}`
        }

        await axios.get(url, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setDados(resposta.data.data)
        }).catch(function (erro) {
            const status = erro.response.status;
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente");
                navigate("/login")
            } else {
                setDados([])
                toast.error(erro.response.data.message)
            }
        }).finally(function () {
            setMostraModalCarregando(false)
        })
    }

    async function buscaDadosPorAgente(idAgente: number | string) {
        if (!idAgente || !diasCarregamento) {
            return
        }

        setMostraModalCarregando(true)
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/busca/tickets/agente/${idAgente}/${diasCarregamento}/${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setDados(resposta.data.data)
        }).catch(function (erro) {
            const status = erro.response.status;
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente");
                navigate("/login")
            } else {
                setDados([])
                toast.error(erro.response.data.message)
            }
        }).finally(function () {
            setMostraModalCarregando(false)
        })
    }

    async function buscarDadosPorUsuario(idUsuario: number | string) {
        if (!idUsuario || !diasCarregamento) {
            return
        }

        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/busca/tickets/usuario/${idUsuario}/${diasCarregamento}/${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setDados(resposta.data.data)
        }).catch(function (erro) {
            const status = erro.response.status;
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente");
                navigate("/login")
            } else {
                setDados([])
                toast.error(erro.response.data.message)
            }
        })
    }

    async function buscaAgentes() {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/busca/agentes/tickets/${idUsuario}/${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setAgentes(resposta.data.data)
        }).catch(function (erro) {
            const status = erro.response.status;
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente");
                navigate("/login")
            } else { 
                setDados([])
                toast.error(erro.response.data.message)
            }
        })
    }

    async function buscaUsuarios() {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/busca/usuarios/tickets/${idFilial}/${idUsuario}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setUsuarios(resposta.data.data)
        }).catch(function (erro) {
            const status = erro.response.status;
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente");
                navigate("/login")
            } else {
                setDados([])
                toast.error(erro.response.data.message)
            }
        })
    }

    async function gerarExcelTickets() {
        if (dados.length === 0) {
            return toast.error("Nenhum ticket encontrado");
        } else {
            setMostraModalCarregando(true);
            // Criar uma nova planilha
            const ws = XLSX.utils.aoa_to_sheet([]);

            // Definir o cabeçalho
            const header = [
                "ID do Ticket",
                "Título",
                "Categoria",
                "Sistema",
                "Usuário de cadastro",
                "Agente de conclusão",
                "Data de criação",
                "Data de conclusão"
            ];
            XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });

            // Adicionar os dados dos usuários ao array
            const dataRows = dados.map((item: any) => [
                item.id_ticket,
                item.titulo,
                item.categoria,
                item.sistema,
                item.usuario_criacao,
                item.agente_conclusao,
                item.dt_criacao,
                item.dt_conclusao
            ]);

            // Adicionar os dados ao arquivo Excel
            XLSX.utils.sheet_add_aoa(ws, dataRows, { origin: "A2" });

            // Criar um novo livro de Excel
            const wb = XLSX.utils.book_new();

            // Adicionar a planilha ao livro de Excel
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

            // Configurar opções de escrita, incluindo o tipo de livro e o tipo de saída
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

            // Criar um Blob a partir do buffer de Excel
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Salvar o Blob como um arquivo Excel
            FileSaver.saveAs(blob, `${nomeArquivo}.xlsx`);
        }
        setMostraModalCarregando(false);
    }

    async function gerarPDF() {

        if (dados.length === 0) {
            return toast.error("Nenhum ticket encontrado")
        }

        setMostraModalCarregando(true)
        const doc = new jsPDF();

        doc.setFontSize(12);
        doc.text('Relatório de Tickets', 14, 16);

        // Configurar a tabela
        (doc as any).autoTable({
            head: [[
                "ID do Ticket", +
                "Título",
                "Categoria",
                "Sistema",
                "Usuário de cadastro",
                "Agente de conclusão",
                "Data de criação",
                "Data de conclusão"
            ]],
            body: dados.map((item: any) => [
                item.id_ticket,
                item.titulo,
                item.categoria,
                item.sistema,
                item.usuario_criacao,
                item.agente_conclusao,
                item.dt_criacao,
                item.dt_conclusao
            ]),
            startY: 22,
        });

        doc.save(`${nomeArquivo}.pdf`);
        setMostraModalCarregando(false)
    };

    const colunas: ITabela[] = [
        { titulo: "ID", acesso: "id_ticket" },
        { titulo: "Titulo", acesso: "titulo" },
        { titulo: "Categoria", acesso: "categoria" },
        { titulo: "Sistema", acesso: "sistema" },
        { titulo: "Requerente", acesso: "usuario_criacao" },
        { titulo: "Agente", acesso: "agente_conclusao" },
        { titulo: "Data de Abertura", acesso: "dt_criacao" },
        { titulo: "Data de Fechamento", acesso: "dt_conclusao" }
    ]

    useEffect(() => {
        if (tipoRelatorio === 'vida') {
            buscaDadosDeVida()
        }
    }, [dataInicio, dataFim])

    useEffect(() => {
        setDados([])
        setAgentes([''])
        setAgenteSelecionado('')
        setUsuarios([''])
        setUsuarioSelecionado('')

        if (tipoRelatorio === 'vida') {
            buscaDadosDeVida()
            setNomeArquivo("Relatório_Tickets_Vida")
        } else if (tipoRelatorio === 'agente') {
            buscaAgentes()
            setNomeArquivo("Relatório_Tickets_Agente")
        } else if (tipoRelatorio === 'usuario') {
            buscaUsuarios()
            setNomeArquivo("Relatório_Tickets_Usuários")
        } else {
            setDados([])
        }
    }, [tipoRelatorio])

    return (
        <>
            <ContainerTitulo titulo="Relatório de Tickets" />

            <Card>
                <Card.Body>
                    <Row className="mb-2">

                        <SpeedDial
                            ariaLabel="SpeedDial basic example"
                            direction="left"
                            sx={{
                                right: 0,
                                position: "absolute",
                            }}
                            icon={<SpeedDialIcon />}
                        >
                            {actions.map((action) => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                />
                            ))}
                        </SpeedDial>

                        <Col xs={12} md={6} className="form-floating">
                            <select
                                autoFocus
                                className="form-select bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => setTipoRelatorio(event.target.value)}
                                // onKeyDown={(event) => handleKeyDown(event, odoresRef)}
                                value={tipoRelatorio}
                            >
                                <option value=''>Selecione</option>
                                <option value="vida">Relatório de Vida do Ticket</option>
                                <option value="agente">Relatório do Ticket por Agente</option>
                                <option value="usuario">Relatório do Ticket por Usuário</option>
                            </select>
                            <label className="form-label">Relatórios</label>
                        </Col>

                        <Col xs={12} md={3}>
                            <div className="form-group">
                                <label htmlFor="inputDiasCarregamento">Dias de carregamento</label>
                                <input
                                    type="number"
                                    id="inputDiasCarregamento"
                                    className="form-control bordasInferiorInput"
                                    value={diasCarregamento}
                                    disabled={diasDisabled}
                                    onChange={(e) => {
                                        setDiasCarregamento(parseInt(e.target.value))

                                        if (e.target.value) {
                                            setDataInicio('')
                                            setDataFim('')
                                        }
                                    }}
                                    onBlur={() => {
                                        if (tipoRelatorio === 'vida') {
                                            buscaDadosDeVida()
                                        } else if (tipoRelatorio === 'agente') {
                                            buscaDadosPorAgente(agenteSelecionado)
                                        } else if (tipoRelatorio === 'usuario') {
                                            buscarDadosPorUsuario(usuarioSelecionado)
                                        } else {
                                            setDados([])
                                        }
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-3" hidden={tipoRelatorio === 'vida' ? false : true}>
                        <Col xs={12} md={4}>
                            <div className="form-group">
                                <label htmlFor="inputDataInicio">Selecione a data de criação</label>
                                <input
                                    type="date"
                                    id="inputDataInicio"
                                    className="form-control"
                                    value={dataInicio}
                                    onChange={(e) => {
                                        setDataInicio(e.target.value)

                                        if (e.target.value) {
                                            setDiasDisabled(true)
                                        } else {
                                            setDiasDisabled(false)
                                        }
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className="form-group">
                                <label htmlFor="inputDataFim">Selecione a data de conclusão</label>
                                <input
                                    type="date"
                                    id="inputDataFim"
                                    className="form-control"
                                    value={dataFim}
                                    onChange={(e) => {
                                        setDataFim(e.target.value)

                                        if (e.target.value) {
                                            setDiasDisabled(true)
                                        } else {
                                            setDiasDisabled(false)
                                        }
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row hidden={tipoRelatorio === 'agente' ? false : true}>
                        <Col xs={12} md={6} className="form-floating">
                            <select
                                autoFocus
                                className="form-select bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setAgenteSelecionado(event.target.value)

                                    buscaDadosPorAgente(parseInt(event.target.value))
                                }}
                                value={agenteSelecionado}
                            >
                                <option value=''>Selecione</option>

                                {agentes.map((agente: any) => (
                                    <option value={agente.idAgente} key={agente.idAgente}>{agente.nome}</option>
                                ))}

                            </select>
                            <label className="form-label">Agentes</label>
                        </Col>
                    </Row>

                    <Row hidden={tipoRelatorio === 'usuario' ? false : true}>
                        <Col xs={12} md={6} className="form-floating">
                            <select
                                autoFocus
                                className="form-select bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setUsuarioSelecionado(event.target.value)

                                    buscarDadosPorUsuario(parseInt(event.target.value))
                                }}
                                value={usuarioSelecionado}
                            >
                                <option value=''>Selecione</option>

                                {usuarios.map((usuario: any) => (
                                    <option value={usuario.idUsuarioCriacao} key={usuario.idUsuarioCriacao}>{usuario.usuario_criacao}</option>
                                ))}

                            </select>
                            <label className="form-label">Usuários</label>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <ClickableTable
                            coluna={colunas}
                            data={dados}
                            usaRowClick={false}
                            itemsPerPage={10}
                            onRowClick={function () { }}
                            acaoEditar={function () { }}
                            acaoExcluir={function () { }}
                            acaoVisualizar={function () { }}
                        />
                    </Row>

                </Card.Body>

            </Card>

            <ModalLoading
                show={mostraModalCarregando}
                label="Carregando..."
            />
        </>
    )
}

export default Relatorios