import { Card, Col, Row } from "react-bootstrap"
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo"
import { useEffect, useState } from "react"
import { Action } from "../../administrativo/codigodoSistema/codigodosistema";
import { FaFileExcel, FaFilePdf } from "react-icons/fa";
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import ClickableTable from "../../../components/ClickableTable/ClickableTabl";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";
import axios from "axios";
import { getSessionData } from "../../../utils/storageUtils";
import { useNavigate } from "react-router-dom";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import ModalComentario from "./components/modalComentario";

function RelatorioSatisfacao() {
    const [mostraModalCarregando, setMostraModalCarregando] = useState<boolean>(false)
    const [diasCarregamento, setDiasCarregamento] = useState<number>(10)
    const [filtroSelecionado, setFiltroSelecionado] = useState<string>('cliente')
    const [textoSelecionado, setTextoSelecionado] = useState<string>('Cliente')
    const [dados, setDados] = useState([])
    const [mostraModalComentario, setMostraModalComentario] = useState<boolean>(false)
    const [comentario, setComentario] = useState<string>('')

    const token = getSessionData('MultfilialWebToken') || "";
    const idFilial = getSessionData('DadosIdfilial') || ""

    const navigate = useNavigate()

    const actions = [
        {
            icon: <FaFilePdf/>,
            name: "PDF",
            onClick: gerarPDF
        },
        {
            icon: <FaFileExcel/>,
            name: "Excel",
            onClick: gerarExcelTickets
        } 

    ];

    const colunas: ITabela[] = [
        { titulo: "Id", acesso: "id_ticket" },
        { titulo: "Cliente", acesso: "cliente" },
        { titulo: "Titulo", acesso: "titulo" },
        { titulo: "Categoria", acesso: "categoria" },
        { titulo: "Sistema", acesso: "sistema" },
        { titulo: "Usuário", acesso: "usuario_criacao" },
        { titulo: "Agente", acesso: "agente_conclusao" },
        { titulo: "Dt. Criação", acesso: "dt_criacao"},
        { titulo: "Avaliação", acesso: "avaliacao" },
        { titulo: "Comentário", acesso: "newComentario" }
    ]

    async function gerarExcelTickets() {
        if (dados.length === 0) {
            return toast.error("Nenhum ticket encontrado");
        } else {
            setMostraModalCarregando(true);
            // Criar uma nova planilha
            const ws = XLSX.utils.aoa_to_sheet([]);

            // Definir o cabeçalho
            const header = [
                "Cliente",
                "Titulo",
                "Categoria",
                "Sistema",
                "Usuário de cadastro",
                "Agente de conclusão",
                "Data de criação",
                "Avaliação",
                "Comentário"
            ];
            XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });

            // Adicionar os dados dos usuários ao array
            const dataRows = dados.map((item: any) => [
                item.cliente,
                item.titulo,
                item.categoria,
                item.sistema,
                item.usuario_criacao,
                item.agente_conclusao,
                item.dt_criacao,
                item.avaliacao,
                item.comentario
            ]);

            // Adicionar os dados ao arquivo Excel
            XLSX.utils.sheet_add_aoa(ws, dataRows, { origin: "A2" });

            // Criar um novo livro de Excel
            const wb = XLSX.utils.book_new();

            // Adicionar a planilha ao livro de Excel
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

            // Configurar opções de escrita, incluindo o tipo de livro e o tipo de saída
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

            // Criar um Blob a partir do buffer de Excel
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Salvar o Blob como um arquivo Excel
            FileSaver.saveAs(blob, `RelatorioSatisfacao.xlsx`);
        }
        setMostraModalCarregando(false);
    }

    async function gerarPDF() {

        if (dados.length === 0) {
            return toast.error("Nenhum ticket encontrado")
        }

        setMostraModalCarregando(true)
        const doc = new jsPDF();

        doc.setFontSize(12);
        doc.text('Relatório de avaliação dos tickets', 14, 16);

        // Configurar a tabela
        (doc as any).autoTable({
            head: [[
                "Cliente",
                "Título",
                "Categoria",
                "Sistema",
                "Usuário de cadastro",
                "Agente de conclusão",
                "Data de criação",
                "Avaliação",
                "Comentário"
            ]],
            body: dados.map((item: any) => [
                item.cliente,
                item.titulo,
                item.categoria,
                item.sistema,
                item.usuario_criacao,
                item.agente_conclusao,
                item.dt_criacao,
                item.avaliacao,
                item.comentario
            ]),
            startY: 22,
        });

        doc.save(`RelatorioSatisfacao.pdf`);
        setMostraModalCarregando(false)
    };

    async function carregaTickets() {
        if (!diasCarregamento) {
            return
        }
        setMostraModalCarregando(true)
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/tickets/${diasCarregamento}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setDados(reduzComentario(resposta.data.data))
        }).catch(function (erro) {
            const status = erro.response.status;
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente");
                navigate("/login")
            } else {
                setDados([])
                toast.error(erro.response.data.message)
            }
        }).finally(function () {
            setMostraModalCarregando(false)
        })
    }

    function handleFiltroChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const valor = event.target.value;
        const texto = event.target.options[event.target.selectedIndex].text;

        setFiltroSelecionado(valor);
        setTextoSelecionado(texto);
    }

    const reduzComentario = (data: any) => {
        return data.map((item: any) => {
            let newComentario = item.comentario;
            if (newComentario.length > 20) {
                newComentario = newComentario.slice(0, 20) + '...'
            }
            return { ...item, newComentario }
        })
    }

    const transformAvaliacao = (data: any) => {
        return data.map((item: any) => {
            let newAvaliacao = item.avaliacao
            if (newAvaliacao === null) {
                newAvaliacao = 0
            }

            return {
                ...item,
                avaliacao: newAvaliacao
            }
        })
    }

    useEffect(() => {
        carregaTickets()
    }, [])

    return (
        <>
            <ContainerTitulo titulo="Relatório das Pesquisas de Satisfação" />

            <Card>
                <Card.Body>

                    <Row>
                        <SpeedDial
                            ariaLabel="SpeedDial basic example"
                            direction="left"
                            sx={{
                                right: 0,
                                position: "absolute",
                            }}
                            icon={<SpeedDialIcon />}
                        >
                            {actions.map((action) => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    onClick={action.onClick}
                                />
                            ))}
                        </SpeedDial>

                        <Col xs={12} md={3}>
                            <div className="form-floating">
                                <select
                                    autoFocus
                                    className="form-select bordasInferiorInput text-black text-capitalize"
                                    onChange={handleFiltroChange}
                                    value={filtroSelecionado}
                                >
                                    <option value="cliente">Cliente</option>
                                    <option value="titulo">Título</option>
                                    <option value="categoria">Categoria</option>
                                    <option value="sistema">Sistema</option>
                                    <option value="agente_conclusao">Agente</option>
                                    <option value="avaliacao">Avaliação</option>
                                </select>
                                <label className="form-label">Filtrar Por</label>
                            </div>
                        </Col>

                        <Col xs={12} md={3}>
                            <div className="form-group" style={{ marginTop: '-2.2px' }}>
                                <label htmlFor="inputDiasCarregamento">Dias de carregamento</label>
                                <input
                                    type="number"
                                    id="inputDiasCarregamento"
                                    className="form-control bordasInferiorInput"
                                    value={diasCarregamento}
                                    onChange={(e) => {
                                        setDiasCarregamento(parseInt(e.target.value))
                                    }}
                                    onBlur={(e) => carregaTickets()}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <ClickableTable
                            data={transformAvaliacao(dados)}
                            coluna={colunas}
                            itemsPerPage={10}
                            onRowClick={function (dados) {
                                setMostraModalComentario(true)
                                setComentario(dados.comentario)
                            }}
                            usaRowClick={true}
                            usaAcoes={false}
                            acaoEditar={function () { }}
                            acaoVisualizar={function () { }}
                            acaoExcluir={function () { }}
                            labelpesquisa={`Filtrar por ${textoSelecionado}`}
                            acessopesquisa={filtroSelecionado}
                        />
                    </Row>
                </Card.Body>
            </Card>

            <ModalLoading
                show={mostraModalCarregando}
                label="Carregando..."
            />

            <ModalComentario
                isOpen={mostraModalComentario}
                fecharModal={() => setMostraModalComentario(false)}
                comentario={comentario}
            />
        </>
    )
}

export default RelatorioSatisfacao