
import Modal from 'react-bootstrap/Modal';

interface ModalConfirmacao {
    icone?: string;
    mostrar: boolean;
    msgAcao1: string;
    msgAcao2: string;
    msgPrincipal: string;
    Acao1: () => void;
    Acao2: () => void;
    backgroundColor?: string;
}

function ModalConfirmacao({
    icone = "bi bi-question-lg",
    mostrar = false,
    msgAcao1,
    msgAcao2,
    msgPrincipal,
    Acao1,
    Acao2,
    backgroundColor = "bg-white"
}: ModalConfirmacao) {

    return (
        <Modal show={mostrar} centered>
            <Modal.Body className={`${backgroundColor}`}>
                <div className='conatiner'>
                    <div className='row'>
                        <div className='col-lg col-md col-sm text-center'>
                            <i className={`${icone} fs-1 ${backgroundColor !== 'bg-white' ? 'text-white' : ''}`}></i>
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm'>
                            <h4 className={`text-center ${backgroundColor !== 'bg-white' ? 'text-white' : ''}`}>{msgPrincipal}</h4>
                        </div>
                        <div className='col-lg col-md col-sm text-center'>
                            <button onClick={Acao1} className='btn btn-danger me-2'>{msgAcao1}</button>
                            <button onClick={Acao2} className='btn btn-primary'>{msgAcao2}</button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalConfirmacao