import { Row, Col, Card } from "react-bootstrap";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";

import AddIcon from "@mui/icons-material/Add";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import Tabela from "../../../components/Formularios/Table";
import ApiGet from "../../../api/endPoints/useGet";
import { useEffect, useState } from "react";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";
import { InterfaceUsuario } from "../../../types/UsuarioInterface";

import { toast } from "react-toastify";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";
import { getSessionData } from "../../../utils/storageUtils";
import axios from "axios";
import ClickableTable from "../../../components/ClickableTable/ClickableTabl";
function ListagemBots() {

    const navigate = useNavigate()

    interface Action {
        icon: JSX.Element;
        name: string;
    }
    const actions: Action[] = [
        {
            icon: (
                <AddIcon
                    onClick={() => navigate("/cadastro/formulario/bots/filial/novo/criar")}
                />
            ),
            name: "Incluir",
        },
        // { icon: <FileCopyIcon />, name: "Duplicar" },
        // { icon: <SaveIcon />, name: "Salvar" },
        // { icon: <PrintIcon />, name: "Imprimir" },
    ];



    //verifica permissão --- inicio
    const [permissao_visualizar, set_permissao_visualizar] = useState(true)
    const [permissao_incluir, set_permissao_incluir] = useState(true)
    const [permissao_editar, set_permissao_editar] = useState(true)
    const [permissao_excluir, set_permissao_excluir] = useState(true)
    const id_filial = getSessionData("DadosIdfilial")


    const colunas: ITabela[] = [

        { titulo: "ID", acesso: "id_bot" },
        { titulo: "Bot", acesso: "nome" },
        { titulo: "Inicio", acesso: "inicio" },
        { titulo: "Fim", acesso: "fim" },
        { titulo: "Status", acesso: "status" }
    ]

    const [dados, setDados] = useState([])
    const [loading, setLoading] = useState<boolean>(false);
    function buscarDados(id_filial: any) {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/bots/filial/${id_filial}`, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        }).then(function (resposta) {
            setDados(resposta.data.bots)
            setLoading(false);
        }).catch(function (erro) {
            setLoading(false);
            toast.error(erro)
        })
    }

    function editar(dado: any) {

        navigate(`/cadastro/formulario/bots/filial/${dado.id_bot}/editar`)
    }

    function visualizar(dado: any) {

        navigate(`/cadastro/formulario/bots/filial/${dado.id_bot}/visualizar`)
    }

    async function excluir(dado: any) {

        await showConfirmationDialog(
            "Excluir Bot",
            `Tem certeza que deseja excluir o ?`,
            "question"
        ).then(function (resposta) {

            if (resposta.confirmed) {

                axios.delete(`${process.env.REACT_APP_API_BASE_URL}/del/bot/filial/${dado.id_bot}/${id_filial}`, {
                    headers: {
                        Authorization: getSessionData("MultfilialWebToken")
                    }
                }).then(function (resposta) {

                    toast.success(resposta.data.message)
                    buscarDados(getSessionData("DadosIdfilial"))

                }).catch(function (erro) {

                    toast.error(erro.response.data.message)
                })
            }
        })
    }

    useEffect(function () {

        CarregarPermissões(getSessionData("id_perfil"), id_filial)
        buscarDados(getSessionData("DadosIdfilial"))

    }, [])



    async function CarregarPermissões(id_perfil: any, id_filial: any) {
        setLoading(true);
        const headers = {
            Authorization: getSessionData("MultfilialWebToken")!,
        };
        const dados = {
            url: "/perfil/carregar/:idperfil/:idfilial",
            parametros: {
                idperfil: id_perfil,
                idfilial: id_filial
            },
            headers: headers,
        };
        ApiGet(dados).then(function (response) {
            if (response[0].visualizarBot === 1) {
                set_permissao_visualizar(true)
            }
            if (response[0].incluirBot === 1) {
                set_permissao_incluir(false)
            }
            if (response[0].EditarBot === 1) {
                set_permissao_editar(true)
            }
            if (response[0].ExcluirBot === 1) {
                set_permissao_excluir(true)
            }

            setLoading(false)
        })
    }

    return (
        <>
            <div className="col mt-2">
                <ContainerTitulo titulo="Listagem de Bots" />
                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <SpeedDial
                                        hidden={permissao_incluir}
                                        ariaLabel="SpeedDial basic example"
                                        direction="left"
                                        sx={{
                                            right: 0,
                                            position: "absolute",
                                        }}
                                        icon={<SpeedDialIcon />}
                                    >
                                        {actions.map((action) => (
                                            <SpeedDialAction
                                                key={action.name}
                                                icon={action.icon}
                                                tooltipTitle={action.name}
                                            />
                                        ))}
                                    </SpeedDial>
                                </Row>
                                <Row>
                                    <ClickableTable
                                        coluna={colunas}
                                        data={dados}
                                        usaRowClick={false}
                                        onRowClick={function () {

                                        }}
                                        itemsPerPage={10}
                                        acaoVisualizar={visualizar}
                                        acaoEditar={editar}
                                        acaoExcluir={excluir}
                                        usaAcoes={true}
                                        usaEditar={permissao_editar}
                                        usaVisualizar={permissao_visualizar}
                                        usaExcluir={permissao_excluir}
                                        labelpesquisa="Procurar por Bot"
                                        acessopesquisa="nome"
                                    />
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <ModalLoading show={loading} label="Carregando" />
            </div>
        </>
    )
}

export default ListagemBots