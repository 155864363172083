import { Card } from "react-bootstrap"
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo"
import Chart from "react-apexcharts"
import axios from "axios"
import { getSessionData } from "../../../utils/storageUtils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

function GestaoAtendimentos() {

    const [dadosChamadosMensais, setDadosChamadosMensais] = useState<any[]>([])

    const token = getSessionData('MultfilialWebToken') || "";
    const idFilial = getSessionData('DadosIdfilial') || ""

    const navigate = useNavigate()

    const nomesClientes = dadosChamadosMensais.map(dado => dado.cliente || "---");
    const quantidades = dadosChamadosMensais.map(dado => dado.qtde);

    const options: ApexCharts.ApexOptions = {
        chart: {
            height: 250,
            width: 300,
            type: "bar", 
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                dataLabels: {
                    position: 'top',
                },
            },
        },
        dataLabels: {
            enabled: true,
            formatter: (val) => `${val}`,
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ['#304758'],
            },
        },
        xaxis: {
            categories: nomesClientes,
            position: 'bottom',
            axisBorder: {
                show: true,
            },
            axisTicks: {
                show: true,
            },
        },
        title: {
            text: 'Chamados Mensais por Cliente',
            align: 'center',
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
                color: '#263238',
            },
        },
    };

    // Configurar dados da série
    const series = [
        {
            name: 'Chamados',
            data: quantidades,
        },
    ];

    async function buscaAtendimentosMensais() {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/atendimentos/mensais/${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setDadosChamadosMensais(resposta.data.data)
        }).catch(function (erro) {
            const status = erro.response.status;
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente");
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
            }
        })
    }

    useEffect(() => {
        buscaAtendimentosMensais()
    }, [])

    return (
        <>
            <div className="container-fluid">
                <ContainerTitulo titulo="Gestão de Atendimentos" />
                <Card className="p-4" style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                    <div className="row">
                        <div className="col col-sm col-md-6 col-lg-4">
                            <Card className="mb-2 border shadow">
                                <Card.Header
                                    style={{
                                        position: 'sticky',
                                        top: 0,
                                        backgroundColor: '#f8f9fa',
                                        zIndex: 1
                                    }}
                                >
                                    Chamados abertos no mês
                                </Card.Header>
                                <div className="m-auto">
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col col-sm col-md-6 col-lg-6">
                                                <Chart 
                                                options={options} 
                                                series={series} 
                                                type="bar" 
                                                height={250} 
                                                width={300}
                                                />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </div>
                            </Card>
                        </div>
                        <div className="col col-sm col-md-6 col-lg-4">
                            <Card className="mb-3 p-3 border shadow">
                            </Card>
                        </div>
                        <div className="col col-sm col-md-2 col-lg-4">
                            <Card className="mb-3 p-3 border shadow">
                            </Card>
                        </div>
                    </div>
                </Card>
            </div>
        </>
    )
}

export default GestaoAtendimentos