import { useEffect, useRef } from "react";
import { Modal, Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

interface ModalComentarioProps {
    isOpen: boolean;
    fecharModal: () => void;
    comentario: string;
}

const ModalComentario: React.FC<ModalComentarioProps> = ({
    isOpen,
    fecharModal,
    comentario
}) => {

    const quillRef = useRef<ReactQuill | null>(null);

    return (
        <>
            <Modal show={isOpen} centered onShow={() => {
                if (quillRef.current) {
                    const editor = quillRef.current.getEditor();
                    const editorElement = editor.root;
                    editorElement.style.fontSize = '15px';
                }
            }}>
                <Modal.Header className="bg-white justify-content-center">
                    <Modal.Title style={{ flex: '1', textAlign: 'center', color: 'black' }}>{"Comentário"}</Modal.Title>
                    <i
                        className="bi bi-x-circle"
                        style={{ fontSize: '2rem', cursor: 'pointer' }}
                        onClick={fecharModal}
                    ></i>
                </Modal.Header>
                <Modal.Body className="bg-white">
                    <Row className="justify-content-center">
                        <div className="col-sm col-md-2 col-lg-10">
                            <ReactQuill
                                ref={quillRef}
                                id="editor"
                                theme="snow"
                                readOnly
                                value={comentario}
                                modules={{ toolbar: false }}
                                style={{
                                    borderRadius: '10px',
                                    overflow: 'hidden',
                                    border: '1px solid #888',
                                }}
                            />
                        </div>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalComentario