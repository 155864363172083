import { Card, Col, Row } from "react-bootstrap"
import ContainerTitulo from "../../components/Formularios/Container/ContainerTitulo"
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material"
import ClickableTable from "../../components/ClickableTable/ClickableTabl"
import ModalLoading from "../../components/Formularios/Modal/ModalLoading"
import { useEffect, useState } from "react"
import ApiGet from "../../api/endPoints/useGet"
import { getSessionData } from "../../utils/storageUtils"
import { useNavigate } from "react-router-dom"
import AddIcon from "@mui/icons-material/Add";
import { ITabela } from "../../components/Formularios/Table/TabelaInterface"
import axios from "axios"
import { toast } from "react-toastify"
import { showConfirmationDialog } from "../../components/Formularios/Modal/ModalExcluir"

function AdministrarMetas() {
    const token = getSessionData("MultfilialWebToken") || "";
    const id_perfil = getSessionData("id_perfil")
    const id_filial = getSessionData("DadosIdfilial")
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    interface Action {
        icon: JSX.Element;
        name: string;
    }
    const actions: Action[] = [
        {
            icon: (
                <AddIcon
                    onClick={() => navigate("/controleMetas/incluirMetas/novo")}
                />
            ),
            name: "Incluir",
        }
    ];

    const editar = async (obj: any) => {
        navigate("/controleMetas/editarMetas/" + obj.id_meta);
    };

    const visualizar = async (obj: any) => {
        navigate("/controleMetas/visualizarMetas/" + obj.id_meta);
    };

    //verifica permissão --- inicio
    const [permissao_visualizar, set_permissao_visualizar] = useState(true)
    const [permissao_incluir, set_permissao_incluir] = useState(true)
    const [permissao_editar, set_permissao_editar] = useState(true)
    const [permissao_excluir, set_permissao_excluir] = useState(true)

    //Função que carrega as permissões vindas do banco de dados
    async function CarregarPermissões(id_perfil: any, id_filial: any) {
        const headers = {
            Authorization: token,
        };
        const dados = {
            url: "/perfil/carregar/:idperfil/:idfilial",
            parametros: {
                idperfil: id_perfil,
                idfilial: id_filial
            },
            headers: headers,
        };
        ApiGet(dados).then(function (response) {
            if (response[0].visualizarMetas === 1) {
                set_permissao_visualizar(true)
            }
            if (response[0].incluirMetas === 1) {
                set_permissao_incluir(true)
            }
            if (response[0].editarMetas === 1) {
                set_permissao_editar(true)
            }
            if (response[0].excluirMetas === 1) {
                set_permissao_excluir(true)
            }
        })
    }
    //verifica permissão --- fim

    //Executa a função que carrega as permissões
    useEffect(() => {
        CarregarPermissões(id_perfil, id_filial)
        buscarDados()
    }, []);

    const colunas: ITabela[] = [
        { titulo: "Id", acesso: "id_meta" },
        { titulo: "Cliente", acesso: "fantasia" },
        { titulo: "Sistema", acesso: "descricao" },
        { titulo: "Prioridade", acesso: "prioridade" },
        { titulo: "Primeiro Contato", acesso: "primeiro_contato" },
        { titulo: "Conclusão", acesso: "conclusao" },
    ];

    //#region REALIZA REQUISIÇÃO PARA LISTAR DADOS DA TABELA
    const [dados, setDados] = useState([]);

    function buscarDados() {
        const idFilial = getSessionData("DadosIdfilial")

        // setLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/metas/${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setDados(resposta.data.data)
            setLoading(false)
        }).catch(function (erro) {
            setLoading(false)
            const status = erro.response.status;
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente");
            }
        })
    }

    function excluirMeta(obj: any) {
        const idFilial = getSessionData("DadosIdfilial")

        showConfirmationDialog('Excluir meta',
            'Você tem certeza que deseja excluir a meta selecionada?', "question", 0).then(function (confirmed) {
                if (confirmed.confirmed) {
                    setLoading(true)
                    axios.delete(`${process.env.REACT_APP_API_BASE_URL}/excluir/dados/metas/${obj.id_meta}/${idFilial}`, {
                        headers: { Authorization: token } // Adiciona o token de autorização no cabeçalho da requisição
                    }).then(function (resposta) {
                        toast.success(resposta.data.message)
                        buscarDados()
                        setLoading(false)
                    }).catch(function (erro) {
                        setLoading(false)
                        const status = erro.response.status
                        if (status == 403 || status == 401) {
                            toast.error("Acesso negado, faça seu login novamente")
                        }
                    })
                }
            })
    }

    return (
        <>
            <div className="col mt-2">
                <ContainerTitulo titulo="Administração de Metas" />

                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <SpeedDial
                                        hidden={!permissao_incluir}
                                        ariaLabel="SpeedDial basic example"
                                        direction="left"
                                        sx={{
                                            right: 0,
                                            position: "absolute",
                                        }}
                                        icon={<SpeedDialIcon />}
                                    >
                                        {actions.map((action) => (
                                            <SpeedDialAction
                                                key={action.name}
                                                icon={action.icon}
                                                tooltipTitle={action.name}
                                            />
                                        ))}
                                    </SpeedDial>
                                </Row>
                                <Row>
                                    <ClickableTable
                                        coluna={colunas}
                                        data={dados}
                                        usaRowClick={false}
                                        onRowClick={function () {

                                        }}
                                        itemsPerPage={5}
                                        acaoVisualizar={visualizar}
                                        acaoEditar={editar}
                                        acaoExcluir={excluirMeta}
                                        usaAcoes={true}
                                        usaEditar={permissao_editar}
                                        usaVisualizar={permissao_visualizar}
                                        usaExcluir={permissao_excluir}
                                        labelpesquisa="Procurar por Sistema"
                                        acessopesquisa="descricao"
                                    />
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
            <ModalLoading show={loading} label="Carregando Listagem de Usuários" />
        </>
    )
}

export default AdministrarMetas